import { createApiError } from "../models/api/apiError";
import ApiLog from "../models/api/apiLog";
import ApiOrder from "../models/api/apiOrder";
import { BaseOrder } from "../models/order";
import apiClient from "./apiClient";

async function getOrdersGroupedByField(
  abortSignal: AbortSignal
): Promise<Record<string, BaseOrder[]>> {
  const response = await apiClient.get("/orders", abortSignal);

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to fetch orders grouped by field", json);
  }
}

async function getOrder(
  abortSignal: AbortSignal,
  orderId: string
): Promise<ApiOrder> {
  const response = await apiClient.get(`/orders/${orderId}`, abortSignal);

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to fetch order", json);
  }
}

async function createOrder(
  abortSignal: AbortSignal,
  wellboreId: string,
  copyFrom?: string
): Promise<ApiOrder> {
  const body = JSON.stringify({ wellboreId });
  const query = copyFrom
    ? "?" + new URLSearchParams({ copyFrom }).toString()
    : "";
  const response = await apiClient.post(`/orders${query}`, body, abortSignal);

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to create order", json);
  }
}

type TimeCurveChange = {
  timeCurveId: string;
  targetWellboreId: string;
};
type DepthCurveChange = {
  depthCurveId: string;
  targetWellboreId: string;
};
type AddCurveChange = {
  op: "add";
  value: TimeCurveChange | DepthCurveChange;
};
type RemoveCurveChange = {
  op: "remove";
  value: (TimeCurveChange | DepthCurveChange) & { id: string };
};

export type CurveChange = AddCurveChange | RemoveCurveChange;

async function updateCurveOrders(
  abortSignal: AbortSignal,
  orderId: string,
  logId: string,
  curveChanges: CurveChange[]
): Promise<ApiLog> {
  return updateCurves(abortSignal, orderId, logId, curveChanges, false);
}

async function updateAndSubmitCurveOrders(
  abortSignal: AbortSignal,
  orderId: string,
  logId: string,
  curveChanges: CurveChange[]
): Promise<ApiLog> {
  return updateCurves(abortSignal, orderId, logId, curveChanges, true);
}

async function updateCurves(
  abortSignal: AbortSignal,
  orderId: string,
  logId: string,
  curveChanges: CurveChange[],
  shouldSubmit: boolean
): Promise<ApiLog> {
  const body = JSON.stringify(curveChanges);
  const query = shouldSubmit
    ? `?${new URLSearchParams({
        shouldSubmit: shouldSubmit.toString()
      }).toString()}`
    : "";
  const response = await apiClient.patch(
    `/orders/${orderId}/logs/${logId}/curves${query}`,
    body,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to update curve orders", json);
  }
}

export default {
  getOrder,
  getOrdersGroupedByField,
  createOrder,
  updateCurveOrders,
  updateAndSubmitCurveOrders
};
