import ApiLog from "./api/apiLog";
import ApiLogTimeCurve from "./api/apiLogTimeCurve";

interface LogTimeCurve extends ApiLogTimeCurve {
  logId: string;
}

export function mapApiLogTimeCurves(log: ApiLog): LogTimeCurve[] {
  return log.timeCurves.map((curve) => ({
    ...curve,
    logId: log.id
  }));
}

export default LogTimeCurve;
