import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  LogAccordionDefaultExpandedState,
  LogAccordionState,
  ServiceAccordionDefaultExpandedState,
  SetLogAccordionStatePayload,
  SetServiceAccordionStatePayload,
  SetToolAccordionStatePayload
} from "./types";

export type AccordionState = LogAccordionState;

const initialState = {} as AccordionState;

const accordionStateSlice = createSlice({
  name: "currentOrder/accordionState",
  initialState,
  reducers: {
    setLogAccordionState: {
      reducer(state, action: PayloadAction<SetLogAccordionStatePayload>) {
        const { logId, expanded } = action.payload;

        if (logId in state) {
          state[logId].expanded = expanded;
        } else {
          state[logId] = { expanded, service: {} };
        }
      },
      prepare(logId: string, expanded: boolean) {
        return {
          payload: { logId, expanded }
        };
      }
    },
    setServiceAccordionState: {
      reducer(state, action: PayloadAction<SetServiceAccordionStatePayload>) {
        const { logId, serviceId, expanded } = action.payload;

        if (!(logId in state)) {
          state[logId] = {
            expanded: LogAccordionDefaultExpandedState,
            service: {}
          };
        }

        if (serviceId in state[logId].service) {
          state[logId].service[serviceId].expanded = expanded;
        } else {
          state[logId].service[serviceId] = {
            expanded,
            tool: {}
          };
        }
      },
      prepare(logId: string, serviceId: string, expanded: boolean) {
        return {
          payload: { logId, serviceId, expanded }
        };
      }
    },
    setToolAccordionState: {
      reducer(state, action: PayloadAction<SetToolAccordionStatePayload>) {
        const { logId, serviceId, toolId, expanded } = action.payload;

        if (!(logId in state)) {
          state[logId] = {
            expanded: LogAccordionDefaultExpandedState,
            service: {}
          };
        }

        if (!(serviceId in state[logId].service)) {
          state[logId].service[serviceId] = {
            expanded: ServiceAccordionDefaultExpandedState,
            tool: {}
          };
        }

        state[logId].service[serviceId].tool[toolId] = expanded;
      },
      prepare(
        logId: string,
        serviceId: string,
        toolId: string,
        expanded: boolean
      ) {
        return {
          payload: { logId, serviceId, toolId, expanded }
        };
      }
    }
  }
});

export const {
  setLogAccordionState,
  setServiceAccordionState,
  setToolAccordionState
} = accordionStateSlice.actions;
export default accordionStateSlice.reducer;
