import { createApiError } from "../models/api/apiError";
import Contact from "../models/contact";
import apiClient from "./apiClient";

interface CreateContactPayload {
  name: string;
  email: string;
  phone?: string;
  isMainContact: boolean;
  roleId: string;
  availability: string;
}
interface UpdateContactPayload extends CreateContactPayload {
  id: string;
}

async function createContact(
  abortSignal: AbortSignal,
  orderId: string,
  contact: CreateContactPayload
): Promise<Contact> {
  const body = JSON.stringify(contact);
  const response = await apiClient.post(
    `/orders/${orderId}/contacts`,
    body,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to create contact", json);
  }
}

async function updateContact(
  abortSignal: AbortSignal,
  orderId: string,
  contact: UpdateContactPayload
): Promise<Contact> {
  const { id, ...payload } = contact;
  const body = JSON.stringify(payload);
  const response = await apiClient.put(
    `/orders/${orderId}/contacts/${id}`,
    body,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to update contact", json);
  }
}

async function deleteContact(
  abortSignal: AbortSignal,
  orderId: string,
  contact: Contact
): Promise<string> {
  const response = await apiClient.delete(
    `/orders/${orderId}/contacts/${contact.id}`,
    abortSignal
  );

  if (response.ok) {
    return contact.id;
  } else {
    const json = await response.json();
    throw createApiError("Failed to delete contact", json);
  }
}

export default { createContact, updateContact, deleteContact };
