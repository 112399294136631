import { PayloadAction } from "@reduxjs/toolkit";

import ApiError from "../../models/api/apiError";
import { BaseRootState } from "../types";
import RequestManager from "./requestManager";

export enum RequestStatus {
  Idle = "Idle",
  InProgress = "In Progress",
  Succeeded = "Succeeded",
  Failed = "Failed"
}

export enum RequestType {
  GetOrder = "GetOrder",
  GetOrdersGroupedByField = "GetOrdersGroupedByField",
  CreateOrder = "CreateOrder",
  UpdateCurveOrders = "UpdateCurveOrders",
  UpdateAndSubmitCurveOrders = "UpdateAndSubmitCurveOrders",
  GetLogs = "GetLogs",
  GetFields = "GetFields",
  GetRoles = "GetRoles",
  GetTargetWellbores = "GetTargetWellbores",
  GetTargetSystems = "GetTargetSystems",
  GetProviderServiceTools = "GetProviderServiceTools",
  GetAzureADUsers = "GetAzureADUsers",
  CreateContact = "CreateContact",
  UpdateContact = "UpdateContact",
  DeleteContact = "DeleteContact",
  CreateOrUpdateTargetWellbore = "CreateOrUpdateTargetWellbore",
  CreateRun = "CreateRun",
  DeleteRun = "DeleteRun",
  GetSiteComGroupsForOrder = "GetSiteComGroupsForOrder",
  UpdateSiteComGroupForOrder = "UpdateSiteComGroupForOrder"
}

export type TRequestType = keyof typeof RequestType;
export type ConcurrentRequestType =
  | "UpdateCurveOrders"
  | "UpdateAndSubmitCurveOrders"
  | "CreateOrUpdateTargetWellbore";
export type SingleRequestType = Exclude<TRequestType, ConcurrentRequestType>;

export const isConcurrentRequestType = (
  request: ConcurrentRequestType | SingleRequestType | string
): request is ConcurrentRequestType =>
  request === RequestType.UpdateCurveOrders ||
  request === RequestType.UpdateAndSubmitCurveOrders ||
  request === RequestType.CreateOrUpdateTargetWellbore;

export type RequestError = ApiError;

export interface Request {
  error?: RequestError;
  status: RequestStatus;
}

export type RequestState = {
  -readonly [K in TRequestType]: K extends ConcurrentRequestType
    ? Record<string, Request>
    : Request;
};

export type RequestPayload = {
  request: TRequestType;
  id?: string;
};

export type FailedRequestPayload = RequestPayload & {
  error: RequestError;
};

export type Selector<T> = (state: BaseRootState) => T;

export type ExcludeAbortSignal<T extends unknown[]> = T extends []
  ? []
  : T extends [infer H, ...infer R]
  ? H extends AbortSignal
    ? ExcludeAbortSignal<R>
    : [H, ...ExcludeAbortSignal<R>]
  : T;

export type StartActionGeneratorSignature<T extends TRequestType> =
  ExcludeAbortSignal<Parameters<(typeof RequestManager.serviceHandlers)[T]>>;

export type StartActionGenerator<T extends TRequestType, P> = (
  ...args: StartActionGeneratorSignature<T>
) => PayloadAction<
  Omit<P, "args"> & { args: StartActionGeneratorSignature<T> }
>;

export type StartRequestActionPayload<T extends TRequestType> = {
  type: T;
  args: StartActionGeneratorSignature<T>;
};

export type StartSingleRequestActionPayload =
  StartRequestActionPayload<SingleRequestType>;

export type StartConcurrentRequestActionPayload =
  StartRequestActionPayload<ConcurrentRequestType> & {
    id: string;
  };

export type StartRequestAction = PayloadAction<
  StartRequestActionPayload<TRequestType> & { id?: string }
>;

export type ConcurrentRequestArg = {
  requestType: ConcurrentRequestType;
  id: string;
};

export const isConcurrentRequestArg = (
  arg: TRequestType | ConcurrentRequestArg
): arg is ConcurrentRequestArg =>
  (arg as ConcurrentRequestArg).id !== undefined;
