import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, Path } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  ConcurrentRequestType,
  isConcurrentRequestType,
  RequestStatus,
  resetRequest,
  selectRequest,
  SingleRequestType,
  startRequest,
  TRequestType
} from "../../state/request";
import {
  StandaloneResult,
  UseConcurrentRequestFormIntegrationProps,
  UseConcurrentRequestStandaloneProps,
  UseFormIntegrationResult,
  UseRequestProps,
  UseRequestResult,
  UseSingleRequestFormIntegrationProps,
  UseSingleRequestStandaloneProps
} from "./types";

function useRequest<T extends ConcurrentRequestType, F extends FieldValues>(
  props: UseConcurrentRequestFormIntegrationProps<T, F>
): UseFormIntegrationResult<F>;

function useRequest<T extends ConcurrentRequestType>(
  props: UseConcurrentRequestStandaloneProps<T>
): StandaloneResult;

function useRequest<T extends SingleRequestType, F extends FieldValues>(
  props: UseSingleRequestFormIntegrationProps<T, F>
): UseFormIntegrationResult<F>;

function useRequest<T extends SingleRequestType>(
  props: UseSingleRequestStandaloneProps<T>
): StandaloneResult;

function useRequest<T extends TRequestType, F extends FieldValues>({
  requestType,
  successCallback,
  errorCallback,
  payloadGenerator: { func: generator, deps = [] },
  setError,
  requestId
}: UseRequestProps<T, F>): UseRequestResult<F> {
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const payload = useMemo(() => generator, deps);

  const [startRequestAction, resetRequestAction, selectRequestSelector] =
    useMemo(() => {
      if (isConcurrentRequestType(requestType)) {
        const id = requestId as string;
        return [
          startRequest(requestType, id),
          resetRequest(requestType, id),
          selectRequest(requestType, id)
        ];
      }
      return [
        startRequest(requestType),
        resetRequest(requestType),
        selectRequest(requestType)
      ];
    }, [requestType, requestId]);

  const [isInvoked, setInvoked] = useState(false);

  const requestState = useSelector(selectRequestSelector);
  const isLoading = requestState.status === RequestStatus.InProgress;
  const isSuccess = requestState.status === RequestStatus.Succeeded;
  const isError = requestState.status === RequestStatus.Failed;

  useEffect(() => {
    dispatch(resetRequestAction);
    return () => {
      dispatch(resetRequestAction);
    };
  }, [dispatch, resetRequestAction]);

  useEffect(() => {
    setInvoked(false);
  }, [requestType, requestId]);

  useEffect(() => {
    if (!isInvoked) {
      return;
    }

    if (isSuccess) {
      setInvoked(false);
      if (successCallback) {
        successCallback();
      }
    } else if (isError) {
      setInvoked(false);
      if (errorCallback) {
        errorCallback(requestState.error);
      }
    }
  }, [
    isInvoked,
    isSuccess,
    isError,
    successCallback,
    errorCallback,
    requestState.error
  ]);

  useEffect(() => {
    if (setError && requestState.error?.errors) {
      for (const [field, errors] of Object.entries(requestState.error.errors)) {
        if (errors.length > 0) {
          setError(field as Path<F>, {
            type: "API",
            message: errors[0]
          });
        }
      }
    }
  }, [setError, requestState.error]);

  const invokerFunction = useCallback(
    (data: any) => {
      if (!isInvoked) {
        dispatch(startRequestAction(...payload(data)));
        setInvoked(true);
      }
    },
    [dispatch, isInvoked, startRequestAction, payload]
  );

  return useMemo(
    () => ({
      invokerFunction,
      isLoading,
      isSuccess,
      isError
    }),
    [invokerFunction, isLoading, isSuccess, isError]
  );
}

export { useRequest };
