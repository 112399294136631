import { Icon, Table } from "@equinor/eds-core-react";
import React, { memo, useMemo, useRef } from "react";
import styled from "styled-components";

import { useParamSelector } from "../../../hooks";
import DrillingStatus from "../../../models/drillingStatus";
import OverviewLog from "../../../models/overviewLog";
import { selectLogSelectorFactory } from "../../../state/overview/selectors";
import { colors } from "../../../styles/Colors";
import { dateFormatter } from "../../../utils/dateFormatter";
import StatusLabel from "../../StatusLabel/StatusLabel";

const { Cell, Row: EdsRow } = Table;

type Props = {
  logId: string;
  callback: (element: HTMLElement | null, log: OverviewLog) => void;
};

function LogTableRow({ logId, callback }: Props): React.ReactElement {
  const selector = useMemo(selectLogSelectorFactory, []);

  const log = useParamSelector(selector, logId);

  const ref = useRef<HTMLElement>(null);

  return (
    <Row>
      <Cell>{log.wellboreName}</Cell>
      <Cell>{log.fieldName}</Cell>
      <Cell>{log.name}</Cell>
      <Cell>{dateFormatter(log.startTime)}</Cell>
      <Cell>
        <StatusLabel status={log.depthStatusSimple} />
      </Cell>
      <Cell>
        {log.depthStatusSimple !== DrillingStatus.NoOrder &&
          log.depthStatusSimple !== DrillingStatus.NoOrderedCurves && (
            <>
              <span>{dateFormatter(log.updated)}</span>{" "}
              <span>{log.updatedBy}</span>
            </>
          )}
      </Cell>
      <Cell>
        <IconButton>
          <Icon
            ref={ref}
            onClick={() => callback(ref.current, log)}
            name="moreVertical"
            title="Open menu"
            size={24}
            color={colors.interactive.primaryResting}
          />
        </IconButton>
      </Cell>
    </Row>
  );
}

const Row = styled(EdsRow)`
  & > td:not(:last-of-type) {
    padding-right: 0;
  }
  & > td:last-of-type {
    padding-right: 0.5rem;
  }

  & > td:not(:nth-last-of-type(2)),
  & > td:nth-last-of-type(2) > span {
    white-space: nowrap;
  }
`;

const IconButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

export default memo(LogTableRow);
