import { TopBar as EdsTopBar } from "@equinor/eds-core-react";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useContextMenu } from "../../hooks";
import { colors } from "../../styles/Colors";
import Icon from "../../styles/Icons";
import ThemeContextMenu from "./ThemeContextMenu";
import UserContextMenu from "./UserContextMenu";

const { Actions: EdsActions, Header } = EdsTopBar;
const fillColor = colors.interactive.primaryResting;

function TopBar(): React.ReactElement {
  const themeRef = useRef<HTMLElement>(null);
  const userRef = useRef<HTMLElement>(null);

  const [ThemeContext, showThemeMenu, hideThemeMenu] = useContextMenu(
    () => (
      <ThemeContextMenu anchor={themeRef.current} onClose={hideThemeMenu} />
    ),
    [themeRef.current]
  );

  const [UserContext, showUserMenu, hideUserMenu] = useContextMenu(
    () => <UserContextMenu anchor={userRef.current} onClose={hideUserMenu} />,
    [userRef.current]
  );

  return (
    <Container>
      <Header>
        <TitleLink to="/">RIPS Order Web</TitleLink>
      </Header>
      <Actions>
        <Pointer>
          <a
            rel="noopener"
            title="help"
            target="_blank"
            href="https://statoilsrm.sharepoint.com/sites/cw-21579/rips-wo/Pages/home.aspx"
          >
            <Icon name="helpOutline" size={24} color={fillColor} title="Help" />
          </a>
        </Pointer>
        <Pointer>
          <Icon
            ref={userRef}
            name="accountCircle"
            size={24}
            title="user"
            color={fillColor}
            onClick={() => showUserMenu()}
          />
        </Pointer>
        <Pointer>
          <Icon
            ref={themeRef}
            name="accessible"
            onClick={() => showThemeMenu()}
            size={24}
            color={fillColor}
          />
        </Pointer>
      </Actions>
      <ThemeContext />
      <UserContext />
    </Container>
  );
}

const TitleLink = styled(Link)`
  color: ${colors.text.staticIconsDefault};
  text-decoration: none;
  text-transform: uppercase;
`;

const Container = styled(EdsTopBar)`
  flex: 0 0 4rem;
`;

const Actions = styled(EdsActions)`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;

  > :not(:last-child) {
    margin-left: 1rem;
  }
`;

const Pointer = styled.div`
  cursor: pointer;
`;

export default TopBar;
