import { logDepthStatusSimplified } from "../utils/dataConverter";
import { computeLogName } from "../utils/log";
import ApiLog from "./api/apiLog";
import DrillingStatus from "./drillingStatus";
import LogBase from "./logBase";

interface Log extends LogBase {
  name: string;
  depthStatusSimple: DrillingStatus;
}

export function mapApiLog(log: ApiLog): Log {
  return {
    id: log.id,
    version: log.version,
    isDrilling: log.isDrilling,
    name: computeLogName(log),
    runNumber: log.runNumber,
    comment: log.comment,
    sectionName: log.sectionName,
    sectionProjectId: log.sectionProjectId,
    rigName: log.rigName,
    startTime: log.startTime,
    endTime: log.endTime,
    diameter: log.diameter,
    timeStatus: log.timeStatus,
    depthStatus: log.depthStatus,
    depthStatusSimple: logDepthStatusSimplified(log.depthStatus),
    hasSpotchecks: log.hasSpotchecks,
    mwdProviderId: log.mwdProviderId,
    mlProviderId: log.mlProviderId
  };
}

export default Log;
