import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  clearState as clearCurrentOrderState,
  setLogAccordionState
} from "../../../state/currentOrder/reducer";
import {
  selectActiveTab,
  selectWellboreId
} from "../../../state/currentOrder/selectors";
import { OrderPageTab } from "../../../state/currentOrder/types";
import {
  RequestType,
  resetRequest,
  startRequest
} from "../../../state/request";
import ConfigurationPanel from "./ConfigurationPanel/ConfigurationPanel";
import CurvePanel from "./CurvePanel/CurvePanel";
import StatusAndActionBar from "./StatusAndActionBar";
import UnsavedChangesGuard from "./UnsavedChangesGuard";

type OrderPageParams = {
  orderId: string;
};

function OrderPage(): React.ReactElement {
  const { orderId } = useParams<OrderPageParams>();
  const { hash } = useLocation();

  const dispatch = useDispatch();

  const activeTab = useSelector(selectActiveTab);
  const wellboreId = useSelector(selectWellboreId);

  useEffect(() => {
    if (orderId) {
      dispatch(startRequest(RequestType.GetOrder)(orderId));
      dispatch(startRequest(RequestType.GetSiteComGroupsForOrder)(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (wellboreId) {
      dispatch(startRequest(RequestType.GetTargetSystems)(wellboreId));
      dispatch(startRequest(RequestType.GetTargetWellbores)(wellboreId));
      dispatch(startRequest(RequestType.GetProviderServiceTools)(wellboreId));
    }
  }, [dispatch, wellboreId]);

  useEffect(() => {
    dispatch(startRequest(RequestType.GetRoles)());
    return () => {
      dispatch(resetRequest(RequestType.GetRoles));
      dispatch(resetRequest(RequestType.GetOrder));
      dispatch(resetRequest(RequestType.GetSiteComGroupsForOrder));
      dispatch(resetRequest(RequestType.GetTargetSystems));
      dispatch(resetRequest(RequestType.GetTargetWellbores));
      dispatch(resetRequest(RequestType.GetProviderServiceTools));
      dispatch(clearCurrentOrderState());
    };
  }, [dispatch]);

  useEffect(() => {
    const logId = hash.slice(1);
    dispatch(setLogAccordionState(logId, true));
  }, [dispatch, hash]);

  return (
    <>
      <UnsavedChangesGuard />
      <StatusAndActionBar />
      <Container>
        {activeTab === OrderPageTab.Configuration && <ConfigurationPanel />}
        {activeTab === OrderPageTab.CurvePanel && <CurvePanel />}
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
`;

export default OrderPage;
