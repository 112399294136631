export type ToolAccordionState = Record<string, boolean>;
export type ServiceAccordionState = Record<
  string,
  { expanded: boolean; tool: ToolAccordionState }
>;
export type LogAccordionState = Record<
  string,
  { expanded: boolean; service: ServiceAccordionState }
>;

export interface SetToolAccordionStatePayload
  extends SetServiceAccordionStatePayload {
  toolId: string;
}

export interface SetServiceAccordionStatePayload
  extends SetLogAccordionStatePayload {
  serviceId: string;
}

export interface SetLogAccordionStatePayload {
  logId: string;
  expanded: boolean;
}

export const LogAccordionDefaultExpandedState = false;
export const ServiceAccordionDefaultExpandedState = true;
export const ToolAccordionDefaultExpandedState = false;
