import { createSelector } from "@reduxjs/toolkit";

import { BaseRootState } from "../types";

export const selectSnacks = createSelector(
  (state: BaseRootState) => state.snacks,
  (snacks) => Object.values(snacks)
);

export const selectSingleSnack = createSelector(
  selectSnacks,
  (snacks) => snacks[0]
);
