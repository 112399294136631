import { Button, CircularProgress, Icon, Table } from "@equinor/eds-core-react";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useParamSelector, useRequest } from "../../../../hooks";
import DrillingStatus from "../../../../models/drillingStatus";
import {
  selectCurveChangesForLogSelectorFactory,
  selectLogSelectorFactory,
  selectOrderId
} from "../../../../state/currentOrder/selectors";
import { RequestType } from "../../../../state/request";
import { addSnack } from "../../../../state/snacks/reducer";
import { colors } from "../../../../styles/Colors";
import { dateFormatter } from "../../../../utils/dateFormatter";
import StatusLabel from "../../../StatusLabel/StatusLabel";
import Tooltip from "../../../Tooltip";

const { Cell, Row: EdsRow } = Table;

type Props = {
  logId: string;
};

function SaveAndSubmitTableRow({ logId }: Props): React.ReactElement {
  const dispatch = useDispatch();

  const selectLog = useMemo(selectLogSelectorFactory, []);
  const selectCurveChangesForLog = useMemo(
    selectCurveChangesForLogSelectorFactory,
    []
  );

  const orderId = useSelector(selectOrderId);
  const log = useParamSelector(selectLog, logId);
  const curveDiff = useParamSelector(selectCurveChangesForLog, logId);

  const [hasCurveChanges, setHasCurveChanges] = useState<boolean | undefined>();

  useEffect(() => {
    if (hasCurveChanges === undefined) {
      setHasCurveChanges(curveDiff.length > 0);
    }
  }, [hasCurveChanges, curveDiff]);

  const [saveDraftErrorMessage, setSaveDraftErrorMessage] = useState("");
  const [saveAndSubmitErrorMessage, setSaveAndSubmitErrorMessage] =
    useState("");

  const saveDraft = useRequest({
    requestType: RequestType.UpdateCurveOrders,
    requestId: logId,
    successCallback: () =>
      dispatch(addSnack(`Section ${log.name} was successfully saved`)),
    errorCallback: (error) => {
      let errorMessage = `Section ${log.name} could not be saved, please try again`;
      if (error?.status === 400) {
        errorMessage = error.title ?? errorMessage;
      }

      dispatch(addSnack(errorMessage));
      setSaveDraftErrorMessage(errorMessage);
    },
    payloadGenerator: {
      func: () => [orderId, logId, curveDiff],
      deps: [orderId, logId, curveDiff]
    }
  });

  const saveAndSubmit = useRequest({
    requestType: RequestType.UpdateAndSubmitCurveOrders,
    requestId: logId,
    successCallback: () =>
      dispatch(
        addSnack(`Section ${log.name} was successfully saved and submitted`)
      ),
    errorCallback: (error) => {
      let errorMessage = `Section ${log.name} could not be saved and submitted, please try again`;
      if (error?.status === 400) {
        errorMessage = error.title ?? errorMessage;
      }

      dispatch(addSnack(errorMessage));
      setSaveAndSubmitErrorMessage(errorMessage);
    },
    payloadGenerator: {
      func: () => [orderId, logId, curveDiff],
      deps: [orderId, logId, curveDiff]
    }
  });

  const isSubmitted =
    log.depthStatus !== DrillingStatus.Draft &&
    log.depthStatus !== DrillingStatus.NotApplicable;

  return (
    <Row>
      <Cell>
        <SectionCellContent>{log.name}</SectionCellContent>
      </Cell>
      <Cell>
        <CellContent>{dateFormatter(log.startTime)}</CellContent>
      </Cell>
      <Cell>
        <CellContent>
          <StatusLabel status={log.depthStatusSimple} />
        </CellContent>
      </Cell>
      <Cell>
        {!hasCurveChanges ? (
          <CellContent>No changes</CellContent>
        ) : (
          <ButtonContent>
            <Button
              variant="outlined"
              disabled={
                isSubmitted ||
                saveDraft.isLoading ||
                saveDraft.isSuccess ||
                saveAndSubmit.isLoading
              }
              onClick={saveDraft.invokerFunction}
            >
              Save draft
            </Button>
            <IconContainer>
              {saveDraft.isLoading && <Spinner />}
              {saveDraft.isError && (
                <Tooltip placement="top-start" title={saveDraftErrorMessage}>
                  <Icon
                    name="errorOutlined"
                    color={colors.interactive.dangerResting}
                  />
                </Tooltip>
              )}
              {saveDraft.isSuccess && (
                <Icon name="check" color={colors.interactive.successResting} />
              )}
            </IconContainer>
          </ButtonContent>
        )}
      </Cell>
      <Cell>
        {!hasCurveChanges && log.depthStatus !== DrillingStatus.Draft ? (
          <CellContent>No changes</CellContent>
        ) : (
          <ButtonContent>
            <Button
              disabled={
                saveAndSubmit.isLoading ||
                saveAndSubmit.isSuccess ||
                saveDraft.isLoading
              }
              onClick={saveAndSubmit.invokerFunction}
            >
              Save and submit
            </Button>
            <IconContainer>
              {saveAndSubmit.isLoading && <Spinner />}
              {saveAndSubmit.isError && (
                <Tooltip
                  placement="top-start"
                  title={saveAndSubmitErrorMessage}
                >
                  <Icon
                    name="errorOutlined"
                    color={colors.interactive.dangerResting}
                  />
                </Tooltip>
              )}
              {saveAndSubmit.isSuccess && (
                <Icon name="check" color={colors.interactive.successResting} />
              )}
            </IconContainer>
          </ButtonContent>
        )}
      </Cell>
    </Row>
  );
}

const Row = styled(EdsRow)`
  & > td {
    font-weight: 400;

    &:last-child,
    &:nth-last-child(2) {
      padding-right: 0;
    }
  }
`;

const CellContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SectionCellContent = styled(CellContent)`
  max-width: 13rem;
`;

const ButtonContent = styled(CellContent)`
  align-items: center;
  display: flex;
  flex-direction: row;

  & > button {
    height: 1.5rem;
  }
`;

const IconContainer = styled.div`
  display: flex;
  margin-left: 0.25rem;
  width: 1.25rem;
`;

const Spinner = styled(CircularProgress)`
  height: auto;
  margin-left: 0.25rem;
  width: 1rem;
`;

export default memo(SaveAndSubmitTableRow);
