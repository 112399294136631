import { createSelector, OutputParametricSelector } from "@reduxjs/toolkit";

import Contact from "../../models/contact";
import { CurveType } from "../../models/curve";
import DrillingStatus from "../../models/drillingStatus";
import Log from "../../models/log";
import TargetProject from "../../models/targetProject";
import TargetSystem, {
  NonWITSMLTargetSystems,
  TargetSystemType
} from "../../models/targetSystem";
import TargetSystemInstance from "../../models/targetSystemInstance";
import TargetWellbore from "../../models/targetWellbore";
import TimeStatus from "../../models/timeStatus";
import { byAndThenBy, withNumericCollatorBy } from "../../utils/SortHelpers";
import { BaseRootState } from "../types";
import { selectLogIdsWithCurveDiff } from "./curveDiff/selectors";
import {
  SelectServiceProviderIdForProviderServiceToolSelector,
  selectServiceProviderIdForProviderServiceToolSelectorFactory,
  SelectTargetSystemSelector
} from "./referenceData/selectors";
import {
  SelectLogSelector,
  selectLogSelectorFactory,
  selectLogsUnsorted,
  selectTargetSystems,
  selectTargetWellboreIdsGroupedByTargetSystem,
  selectTargetWellbores
} from "./sharedSelectors";
import {
  LogFilterValue,
  LogFilterValueAllSections,
  LogFilterValueDrillingSections,
  OrderPageTab,
  TargetSystemAllValue,
  TargetSystemFilterValue,
  TargetSystemOrderedValue
} from "./types";

export const selectOrderId = (state: BaseRootState): string =>
  state.currentOrder.order.id;

export const selectWellboreName = (state: BaseRootState): string =>
  state.currentOrder.order.wellboreName;

export const selectFieldName = (state: BaseRootState): string =>
  state.currentOrder.order.fieldName;

export const selectWellboreId = (state: BaseRootState): string =>
  state.currentOrder.order.wellboreId;

export const selectContacts = (state: BaseRootState): Contact[] =>
  state.currentOrder.contacts;

export const selectSiteComGroup = (state: BaseRootState): string =>
  state.currentOrder.order.siteComGroupName;

export const selectHasTargetWellbores = createSelector(
  selectTargetWellboreIdsGroupedByTargetSystem,
  (idsBySystem) =>
    Object.entries(idsBySystem).some(
      ([system, ids]) =>
        !NonWITSMLTargetSystems.includes(system) && ids.length > 0
    )
);

export const selectIsExploration = createSelector(
  (state: BaseRootState) => state.currentOrder.order.fieldName,
  (field) => field?.toLowerCase() === "exploration"
);

export const selectActiveTab = createSelector(
  (state: BaseRootState) => state.currentOrder.activeTab,
  selectHasTargetWellbores,
  (activeTab, hasTargetWellbores) => {
    if (isNaN(activeTab)) {
      return hasTargetWellbores
        ? OrderPageTab.CurvePanel
        : OrderPageTab.Configuration;
    } else {
      return activeTab;
    }
  }
);

export const selectHasServiceProviders = createSelector(
  selectLogsUnsorted,
  (logs) => logs.some((log) => log.mlProviderId || log.mwdProviderId)
);

export const selectContactIds = createSelector(selectContacts, (contacts) =>
  contacts.map((contact) => contact.id)
);

type SelectContactSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Contact,
  (res1: Contact[], res2: string) => Contact
>;
export const selectContactSelectorFactory = (): SelectContactSelector =>
  createSelector(
    selectContacts,
    (_: BaseRootState, id: string) => id,
    (contacts, id) =>
      contacts.find((contact) => contact.id === id) ?? ({} as Contact)
  );

export const selectIsOrderCompleted = createSelector(
  selectLogsUnsorted,
  (logs) =>
    logs.every((log) =>
      log.isDrilling
        ? log.depthStatus === DrillingStatus.Completed
        : log.timeStatus === TimeStatus.Completed
    )
);

export const selectUnfilteredLogs = createSelector(selectLogsUnsorted, (logs) =>
  [...logs].sort(
    byAndThenBy(
      (log) => new Date(log.startTime),
      (log) => log.name
    )
  )
);

export const selectLogsWithCurveChanges = createSelector(
  selectUnfilteredLogs,
  selectLogIdsWithCurveDiff,
  (logs, logIdsWithCurveDiff) =>
    logs.filter((log) => logIdsWithCurveDiff.includes(log.id))
);

export const selectUnfilteredLogIds = createSelector(
  selectUnfilteredLogs,
  (logs) => logs.map((log) => log.id)
);

const selectAllIncompleteAndInactiveDrillingLogs = createSelector(
  selectUnfilteredLogs,
  (logs) =>
    logs.filter(
      (log) =>
        log.isDrilling &&
        log.depthStatus !== DrillingStatus.Completed &&
        log.depthStatus !== DrillingStatus.Active
    )
);

export type SelectAllEligibleLogCopyTargetsSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Log[],
  (res1: Log, res2: Log[]) => Log[]
>;
export const selectAllEligibleLogCopyTargetsSelectorFactory = (
  selectLogSelector?: SelectLogSelector
): SelectAllEligibleLogCopyTargetsSelector => {
  if (!selectLogSelector) {
    selectLogSelector = selectLogSelectorFactory();
  }
  return createSelector(
    selectLogSelector,
    selectAllIncompleteAndInactiveDrillingLogs,
    (sourceLog, targetLogs) =>
      targetLogs.filter(
        (log) =>
          log.mwdProviderId === sourceLog.mwdProviderId &&
          log.mlProviderId === sourceLog.mlProviderId &&
          log.id !== sourceLog.id
      )
  );
};

export type SelectAllEligibleLogCopyTargetIdsSelector =
  OutputParametricSelector<
    BaseRootState,
    string,
    string[],
    (res: Log[]) => string[]
  >;
export const selectAllEligibleLogCopyTargetIdsSelectorFactory = (
  selectAllEligibleLogCopyTargets?: SelectAllEligibleLogCopyTargetsSelector
): SelectAllEligibleLogCopyTargetIdsSelector => {
  if (!selectAllEligibleLogCopyTargets) {
    selectAllEligibleLogCopyTargets =
      selectAllEligibleLogCopyTargetsSelectorFactory();
  }
  return createSelector(selectAllEligibleLogCopyTargets, (logs) =>
    logs.map((log) => log.id)
  );
};

export type SelectNumberOfEligibleLogCopyTargetsSelector =
  OutputParametricSelector<
    BaseRootState,
    string,
    number,
    (res: Log[]) => number
  >;
export const selectNumberOfEligibleLogCopyTargetsSelectorFactory = (
  selectEligibleLogsSelector?: SelectAllEligibleLogCopyTargetsSelector
): SelectNumberOfEligibleLogCopyTargetsSelector => {
  if (!selectEligibleLogsSelector) {
    selectEligibleLogsSelector =
      selectAllEligibleLogCopyTargetsSelectorFactory();
  }
  return createSelector(selectEligibleLogsSelector, (logs) => logs.length);
};

export const selectSelectedLog = (state: BaseRootState): LogFilterValue =>
  state.currentOrder.selectedLog;

export const selectLogFilterValues = createSelector(
  selectUnfilteredLogs,
  (logs): LogFilterValue[] => [
    LogFilterValueAllSections,
    LogFilterValueDrillingSections,
    ...logs
  ]
);

export const selectTargetSystemFilterValues = createSelector(
  selectTargetSystems,
  (targetSystems): TargetSystemFilterValue[] =>
    [
      TargetSystemAllValue,
      TargetSystemOrderedValue,
      ...targetSystems.filter(
        (system) => system.name !== TargetSystemType.SiteCom
      )
    ] as TargetSystemFilterValue[]
);

export const selectEarliestStartTime = createSelector(
  selectUnfilteredLogs,
  (logs) => (logs.length > 0 ? logs[0].startTime : undefined)
);

export const selectLogs = createSelector(
  selectUnfilteredLogs,
  selectSelectedLog,
  (logs, selectedLog) => {
    if (selectedLog.id === LogFilterValueAllSections.id) {
      return logs;
    } else if (selectedLog.id === LogFilterValueDrillingSections.id) {
      return logs.filter((log) => log.isDrilling);
    } else {
      return logs.filter((log) => log.id === selectedLog.id);
    }
  }
);

export const selectIsFilterActive = (state: BaseRootState): boolean =>
  !!state.currentOrder.curveFilter ||
  !(state.currentOrder.selectedTargetSystem.id === TargetSystemAllValue.id);

export const selectLogIds = createSelector(selectLogs, (logs) =>
  logs.map((log) => log.id)
);

type SelectTargetWellboresForSystem = OutputParametricSelector<
  BaseRootState,
  string,
  TargetWellbore[],
  (res1: TargetSystem, res2: TargetWellbore[]) => TargetWellbore[]
>;
export const selectTargetWellboresForSystemSelectorFactory = (
  selectTargetSystem: SelectTargetSystemSelector
): SelectTargetWellboresForSystem =>
  createSelector(
    selectTargetSystem,
    selectTargetWellbores,
    (targetSystem, targetWellbores) => {
      const projectIds = targetSystem.instances
        .flatMap((instance) => instance.projects)
        .map((project) => project.id);
      return targetWellbores.filter((targetWellbore) =>
        projectIds.includes(targetWellbore.targetProjectId)
      );
    }
  );

type TargetSystemWellbore = {
  targetWellboreId: string;
  active: boolean;
  projectId: string;
  projectName: string;
  instanceId: string;
  instanceName: string;
  systemId: string;
  systemName: string;
};
const createTargetSystemWellbore = (
  system: TargetSystem,
  instance: TargetSystemInstance,
  project: TargetProject,
  targetWellbore: TargetWellbore
): TargetSystemWellbore => ({
  targetWellboreId: targetWellbore.id,
  active: targetWellbore.isActive,
  projectId: project.id,
  projectName: project.name,
  instanceId: instance.id,
  instanceName: instance.name,
  systemId: system.id,
  systemName: system.name
});

const selectTargetSystemWellbores = createSelector(
  selectTargetSystems,
  selectTargetWellbores,
  (targetSystems, targetWellbores) => {
    const result = [] as TargetSystemWellbore[];

    targetSystems.forEach((system) => {
      system.instances.forEach((instance) => {
        instance.projects.forEach((project) => {
          targetWellbores
            .filter(
              (targetWellbore) => targetWellbore.targetProjectId === project.id
            )
            .forEach((targetWellbore) => {
              result.push(
                createTargetSystemWellbore(
                  system,
                  instance,
                  project,
                  targetWellbore
                )
              );
            });
        });
      });
    });

    return result;
  }
);

type CurveTableColumn = {
  targetWellboreId: string;
  name: string;
  instanceDescription?: string;
  systemDescriptions?: string[];
  disabled: boolean;
  type: CurveType;
};
const createCurveTableColumn = (
  system: TargetSystem,
  destination: TargetSystemWellbore,
  instanceDescriptionGenerator?: (d: TargetSystemWellbore) => string
): CurveTableColumn[] => {
  const instanceDescription = instanceDescriptionGenerator
    ? instanceDescriptionGenerator(destination)
    : undefined;

  return system.capabilities.map((capability) => ({
    targetWellboreId: destination.targetWellboreId,
    name: destination.systemName,
    instanceDescription: instanceDescription,
    systemDescriptions: system.descriptions
      .filter((desc) => desc.capability === capability)
      .map((desc) => desc.text),
    disabled: !destination.active,
    type: capability
  }));
};

type SelectCurveTableColumnsSelector = OutputParametricSelector<
  BaseRootState,
  string,
  CurveTableColumn[],
  (
    res1: TargetSystem[],
    res2: TargetSystemWellbore[],
    res3: string
  ) => CurveTableColumn[]
>;
export const selectCurveTableColumnsSelectorFactory = (
  selectProviderServiceId?: SelectServiceProviderIdForProviderServiceToolSelector
): SelectCurveTableColumnsSelector => {
  if (!selectProviderServiceId) {
    selectProviderServiceId =
      selectServiceProviderIdForProviderServiceToolSelectorFactory();
  }

  return createSelector(
    selectTargetSystems,
    selectTargetSystemWellbores,
    selectProviderServiceId,
    (targetSystems, sources, providerServiceId) => {
      const result = [] as CurveTableColumn[];

      targetSystems
        .filter(
          (system) =>
            NonWITSMLTargetSystems.includes(system.name) ||
            system.serviceProviderId === providerServiceId
        )
        .forEach((system) => {
          const systemSources = sources.filter(
            (source) => source.systemId === system.id
          );

          if (systemSources.length > 1) {
            system.instances.forEach((instance) => {
              const instanceSources = systemSources.filter(
                (source) => source.instanceId === instance.id
              );

              const instanceDescription = (s: TargetSystemWellbore) =>
                instanceSources.length > 1
                  ? `${s.projectName} ${s.instanceName}`
                  : s.instanceName;

              instanceSources.forEach((source) =>
                result.push(
                  ...createCurveTableColumn(system, source, instanceDescription)
                )
              );
            });
          } else {
            systemSources.forEach((source) =>
              result.push(...createCurveTableColumn(system, source))
            );
          }
        });

      return result;
    }
  );
};

type SelectMainTargetWellboreIdForServiceSelector = OutputParametricSelector<
  BaseRootState,
  string,
  string,
  (
    res1: string,
    res2: TargetSystem[],
    res3: TargetWellbore[],
    res4: Record<string, string[]>
  ) => string
>;
export const selectMainTargetWellboreIdForServiceSelectorFactory = (
  selectServiceProviderId?: SelectServiceProviderIdForProviderServiceToolSelector
): SelectMainTargetWellboreIdForServiceSelector => {
  if (!selectServiceProviderId) {
    selectServiceProviderId =
      selectServiceProviderIdForProviderServiceToolSelectorFactory();
  }
  return createSelector(
    selectServiceProviderId,
    selectTargetSystems,
    selectTargetWellbores,
    selectTargetWellboreIdsGroupedByTargetSystem,
    (
      serviceProviderId,
      targetSystems,
      targetWellbores,
      wellboreIdsBySystem
    ) => {
      const system = targetSystems.find(
        (ts) => ts.serviceProviderId === serviceProviderId
      );

      if (!system) {
        return "";
      }

      const wellboreIds = wellboreIdsBySystem[system.name];
      return (
        targetWellbores
          .filter((tw) => tw.isActive && wellboreIds.includes(tw.id))
          .map((tw) => tw.id)
          .sort(withNumericCollatorBy((id) => id, { ascending: false }))[0] ??
        ""
      );
    }
  );
};

export * from "./azureAd/selectors";
export * from "./curveDiff/selectors";
export * from "./referenceData/selectors";
export * from "./sharedSelectors";
