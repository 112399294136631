import { AnyAction } from "redux";
import { put, PutEffect } from "redux-saga/effects";

// The redux-batched store enhancer enhances the store's dispatch and subscribe handler.
// Enabling us to group actions into a single batch, that will only trigger a single subscriber event.
// I.e., selectors and components will only recompute/rerender once when all of the batched actions
// have completed. Batched actions are executed sequentially in the order they are defined.
//
// This utility function adds typings to support the new enhanced signature.
export function putBatched(actions: AnyAction[]): PutEffect {
  return put(actions as unknown as AnyAction);
}
