import { TextField } from "@equinor/eds-core-react";
import styled, { css } from "styled-components";

export default styled(TextField)<{ readOnly?: boolean }>`
  && > div {
    ${({ readOnly }) =>
      readOnly &&
      css`
        outline: none;

        & > input:hover {
          cursor: default;
        }
      `}
  }
`;
