import { Location } from "history";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import { Prompt } from "react-router-dom";

import { selectIsCurveDiff } from "../../../state/currentOrder/selectors";
import UnsavedChangesDialog from "./UnsavedChangesDialog";

const UnsavedChangesGuard = (): React.ReactElement => {
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const isCurveDiff = useSelector(selectIsCurveDiff);

  const [showUnsavedChangesDialog, hideUnsavedChangesDialog] = useModal(() => (
    <UnsavedChangesDialog
      onCancel={hideUnsavedChangesDialog}
      onConfirm={() => setConfirmedNavigation(true)}
    />
  ));

  const handleNavigation = (location: Location) => {
    if (!confirmedNavigation) {
      // exist curve diff and user has not opted to discard changes, block navigation and display dialog
      setLastLocation(location);
      showUnsavedChangesDialog();
      return false;
    }
    // exist curve diff and user has opted to discard changes, allow navigation
    return true;
  };

  const handleUnloadEvent = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  }, []);

  useEffect(() => {
    if (isCurveDiff && !confirmedNavigation) {
      window.addEventListener("beforeunload", handleUnloadEvent);
    }
    return () => {
      if (isCurveDiff && !confirmedNavigation) {
        window.removeEventListener("beforeunload", handleUnloadEvent);
      }
    };
  }, [isCurveDiff, confirmedNavigation, handleUnloadEvent]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      location.assign(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return <Prompt when={isCurveDiff} message={handleNavigation} />;
};

export default memo(UnsavedChangesGuard);
