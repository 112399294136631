import { useSelector } from "react-redux";
import { OutputParametricSelector } from "reselect";

import { BaseRootState } from "../state/types";

export const useParamSelector = <P, R>(
  selector: OutputParametricSelector<BaseRootState, P, R, any>,
  props: P,
  ...rest: any[]
): R => {
  return useSelector((state: BaseRootState) => selector(state, props, ...rest));
};
