import DrillingStatus from "../models/drillingStatus";

export const logDepthStatusSimplified = (
  depthStatus: DrillingStatus
): DrillingStatus => {
  switch (depthStatus) {
    case DrillingStatus.Ready:
    case DrillingStatus.ReadyToEstablish:
    case DrillingStatus.Verified:
      return DrillingStatus.Submitted;
    default:
      return depthStatus;
  }
};
