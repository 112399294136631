import { nanoid } from "@reduxjs/toolkit";

import { createApiError } from "../models/api/apiError";
import { CurveType } from "../models/curve";
import ProviderServiceTool from "../models/providerServiceTool";
import TargetSystem from "../models/targetSystem";
import TargetWellbore from "../models/targetWellbore";
import apiClient from "./apiClient";

async function getTargetSystems(
  abortSignal: AbortSignal,
  wellboreId: string
): Promise<TargetSystem[]> {
  const response = await apiClient.get(
    `/wellbores/${wellboreId}/targetsystems`,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to fetch target systems", json);
  }
}

async function getProviderServiceTools(
  abortSignal: AbortSignal,
  wellboreId: string
): Promise<ProviderServiceTool[]> {
  const response = await apiClient.get(
    `/wellbores/${wellboreId}/tools`,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json.map((providerServiceTool: ProviderServiceTool) => {
      return {
        ...providerServiceTool,
        id: nanoid(),
        tools: providerServiceTool.tools.map((tool) => {
          return {
            ...tool,
            depthCurves: tool.depthCurves.map((curve) => {
              return {
                ...curve,
                type: CurveType.Depth
              };
            }),
            timeCurves: tool.timeCurves.map((curve) => {
              return {
                ...curve,
                type: CurveType.Time
              };
            })
          };
        })
      };
    });
  } else {
    throw createApiError("Failed to fetch provider service tools", json);
  }
}

async function getTargetWellbores(
  abortSignal: AbortSignal,
  wellboreId: string
): Promise<TargetWellbore[]> {
  const response = await apiClient.get(
    `/wellbores/${wellboreId}/targetwellbores`,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to fetch target wellbores", json);
  }
}

interface CreateTargetWellborePayload {
  name: string;
  targetProjectId: string;
}

interface UpdateTargetWellborePayload
  extends Partial<CreateTargetWellborePayload> {
  id: string;
}

async function createOrUpdateTargetWellbore(
  abortSignal: AbortSignal,
  wellboreId: string,
  targetWellbore: CreateTargetWellborePayload | UpdateTargetWellborePayload
): Promise<TargetWellbore> {
  if ("id" in targetWellbore) {
    return updateTargetWellbore(abortSignal, wellboreId, targetWellbore);
  } else {
    return createTargetWellbore(abortSignal, wellboreId, targetWellbore);
  }
}

async function createTargetWellbore(
  abortSignal: AbortSignal,
  wellboreId: string,
  targetWellbore: CreateTargetWellborePayload
): Promise<TargetWellbore> {
  const body = JSON.stringify(targetWellbore);
  const response = await apiClient.post(
    `/wellbores/${wellboreId}/targetwellbores`,
    body,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to create target wellbore", json);
  }
}

async function updateTargetWellbore(
  abortSignal: AbortSignal,
  wellboreId: string,
  targetWellbore: UpdateTargetWellborePayload
): Promise<TargetWellbore> {
  const { id, ...content } = targetWellbore;

  const patchOperations = Object.entries(content)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => ({ op: "replace", path: `/${key}`, value }));

  const body = JSON.stringify(patchOperations);
  const response = await apiClient.patch(
    `/wellbores/${wellboreId}/targetwellbores/${id}`,
    body,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to update target wellbore", json);
  }
}

export default {
  getTargetSystems,
  getTargetWellbores,
  createOrUpdateTargetWellbore,
  getProviderServiceTools
};
