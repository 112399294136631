import { Checkbox } from "@equinor/eds-core-react";
import React, { memo, useMemo } from "react";
import { useDispatch } from "react-redux";

import { useParamSelector } from "../../../../hooks";
import { addCurve, removeCurve } from "../../../../state/currentOrder/reducer";
import {
  selectCurveSelectorFactory,
  selectCurveTooltipSelectorFactory,
  selectIsCurveDisabledSelectorFactory
} from "../../../../state/currentOrder/referenceData/selectors";
import {
  selectLogCurveSelectorFactory,
  selectLogSelectorFactory
} from "../../../../state/currentOrder/selectors";
import Tooltip from "../../../Tooltip";

type Props = {
  logId: string;
  targetWellboreId: string;
  serviceId: string;
  curveId?: string;
  disabled: boolean;
};

function CurveCheckbox({
  logId,
  targetWellboreId,
  serviceId,
  curveId,
  disabled
}: Props): React.ReactElement {
  const dispatch = useDispatch();

  const selectLog = useMemo(selectLogSelectorFactory, []);
  const selectCurve = useMemo(selectCurveSelectorFactory, []);
  const selectLogCurve = useMemo(selectLogCurveSelectorFactory, []);
  const selectIsCurveDisabled = useMemo(
    () => selectIsCurveDisabledSelectorFactory(selectCurve, selectLog),
    [selectCurve, selectLog]
  );
  const selectCurveTooltip = useMemo(
    () =>
      selectCurveTooltipSelectorFactory(selectCurve, selectLog, selectLogCurve),
    [selectCurve, selectLog, selectLogCurve]
  );

  const curve = useParamSelector(selectCurve, logId, curveId);
  const tooltip = useParamSelector(
    selectCurveTooltip,
    logId,
    curveId,
    targetWellboreId
  );
  const isDisabled = useParamSelector(
    selectIsCurveDisabled,
    logId,
    curveId,
    targetWellboreId
  );
  const logCurve = useParamSelector(
    selectLogCurve,
    logId,
    curveId,
    targetWellboreId
  );

  const toggleCheckbox = () => {
    if (curveId && curve) {
      if (logCurve) {
        dispatch(
          removeCurve(logCurve.id, logId, targetWellboreId, curveId, curve.type)
        );
      } else {
        dispatch(
          addCurve(logId, targetWellboreId, curveId, curve.type, serviceId)
        );
      }
    }
  };

  return (
    <Tooltip placement="right" title={tooltip}>
      <Checkbox
        checked={!!logCurve}
        onChange={toggleCheckbox}
        disabled={disabled || isDisabled}
      />
    </Tooltip>
  );
}

export default memo(CurveCheckbox);
