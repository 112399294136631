import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useRequestStatus } from "../../../hooks";
import { clearState as clearOverviewState } from "../../../state/overview/reducer";
import {
  selectSelectedField,
  selectSelectedLogType
} from "../../../state/overview/selectors";
import {
  RequestType,
  resetRequest,
  startRequest
} from "../../../state/request";
import AppBanner from "../../AppBanner/AppBanner";
import LogTable from "./LogTable";
import SearchAndFilterBar from "./SearchAndFilterBar";
import SearchFilterDescription from "./SearchFilterDescription";

function OverviewPage(): React.ReactElement {
  const dispatch = useDispatch();

  const [showBanner, setShowBanner] = useState(true);

  const selectedField = useSelector(selectSelectedField);
  const selectedStatus = useSelector(selectSelectedLogType);
  const { isReady } = useRequestStatus(RequestType.GetLogs);

  useEffect(() => {
    dispatch(startRequest(RequestType.GetLogs)(selectedStatus, selectedField));
  }, [dispatch, selectedStatus, selectedField]);

  useEffect(() => {
    dispatch(startRequest(RequestType.GetFields)());
    return () => {
      dispatch(resetRequest(RequestType.GetFields));
      dispatch(resetRequest(RequestType.GetLogs));
      dispatch(clearOverviewState());
    };
  }, [dispatch]);

  return (
    <>
      <AppBanner visible={showBanner} />
      <SearchAndFilterBar
        showBanner={showBanner}
        toggleBanner={setShowBanner}
      />
      <Container>
        {isReady && <SearchFilterDescription />}
        <LogTable />
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 0 2rem 3rem;
`;

export default OverviewPage;
