import { Button } from "@equinor/eds-core-react";
import React from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { selectTheme } from "../../../../state/settings/selectors";
import { Theme } from "../../../../state/settings/types";
import LogAccordion from "./LogAccordion";
import SaveAndSubmitDialog from "./SaveAndSubmitDialog";
import SearchAndFilterBar from "./SearchAndFilterBar";

function CurvePanel(): React.ReactElement {
  const theme = useSelector(selectTheme);

  const [showSaveAndSubmitDialog, hideSaveAndSubmitDialog] = useModal(
    () => <SaveAndSubmitDialog onClose={hideSaveAndSubmitDialog} />,
    []
  );

  return (
    <>
      <Container theme={theme}>
        <SearchAndFilterBar />
        <Button onClick={showSaveAndSubmitDialog}>
          Save and submit sections
        </Button>
      </Container>
      <LogAccordion />
    </>
  );
}

const Container = styled.div<{ theme: Theme }>`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;

  ${(props: any) =>
    props.theme === Theme.Compact
      ? "padding: 1rem 2rem"
      : "padding: 3rem 2rem 2rem 2rem"}
`;

export default CurvePanel;
