import { Table } from "@equinor/eds-core-react";
import React, { memo, useMemo } from "react";

import { useParamSelector } from "../../../../hooks";
import { CurveType } from "../../../../models/curve";
import {
  selectCurveDescriptionSelectorFactory,
  selectCurveNameSelectorFactory,
  selectCurvesSelectorFactory,
  selectCurveTableColumnsSelectorFactory,
  selectCurveTypesSelectorFactory
} from "../../../../state/currentOrder/selectors";
import Tooltip from "../../../Tooltip";
import CurveCheckbox from "./CurveCheckbox";

const { Cell, Row } = Table;

type Props = {
  logId: string;
  serviceId: string;
  curveIds: string[];
};

function CurveTableRow({
  logId,
  serviceId,
  curveIds
}: Props): React.ReactElement {
  const selectCurves = useMemo(selectCurvesSelectorFactory, []);
  const selectCurveName = useMemo(
    () => selectCurveNameSelectorFactory(selectCurves),
    [selectCurves]
  );
  const selectCurveDescription = useMemo(
    () => selectCurveDescriptionSelectorFactory(selectCurves),
    [selectCurves]
  );

  const selectCurveTypes = useMemo(
    () => selectCurveTypesSelectorFactory(selectCurves),
    [selectCurves]
  );
  const selectColumns = useMemo(selectCurveTableColumnsSelectorFactory, []);

  const columns = useParamSelector(selectColumns, serviceId);
  const curveId = useParamSelector(selectCurveTypes, curveIds);
  const curveName = useParamSelector(selectCurveName, curveIds);
  const curveDescription = useParamSelector(selectCurveDescription, curveIds);

  return (
    <Row>
      <Cell>
        <Tooltip placement="right" title={curveDescription} enterDelay={400}>
          <span>{curveName}</span>
        </Tooltip>
      </Cell>
      {columns.map((column) => (
        <Cell key={`${column.targetWellboreId}-${column.type}`}>
          <CurveCheckbox
            logId={logId}
            targetWellboreId={column.targetWellboreId}
            serviceId={serviceId}
            curveId={curveId[column.type as CurveType]}
            disabled={column.disabled}
          />
        </Cell>
      ))}
    </Row>
  );
}

export default memo(CurveTableRow);
