import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import AADUser from "../../../models/aadUser";

export interface AzureAdState {
  users: AADUser[];
}

const initialState = {
  users: []
} as AzureAdState;

const azureAdSlice = createSlice({
  name: "currentOrder/azureAd",
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<AADUser[]>) {
      state.users = action.payload;
    },
    clearState() {
      return initialState;
    }
  }
});

export const { setUsers, clearState: clearAzureAdState } = azureAdSlice.actions;
export default azureAdSlice.reducer;
