import {
  Button,
  Dialog as EdsDialog,
  Typography
} from "@equinor/eds-core-react";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { useParamSelector } from "../../../../hooks";
import Log from "../../../../models/log";
import { clearCurvesForLog } from "../../../../state/currentOrder/actions";
import { selectNumberOfClearableCurvesSelectorFactory } from "../../../../state/currentOrder/selectors";

const { Title, Header } = EdsDialog;

type Props = {
  log: Log;
  onClose: () => void;
};

const LogClearConfirmationDialog = ({
  log,
  onClose
}: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const selectNumberOfClearableCurves = useMemo(
    selectNumberOfClearableCurvesSelectorFactory,
    []
  );

  const numberOfClearableCurves = useParamSelector(
    selectNumberOfClearableCurves,
    log.id
  );

  return (
    <Dialog open={true}>
      <Header>
        <Title>Clear Section?</Title>
      </Header>
      <Row>
        <Typography variant="body_long">
          The section{" "}
          <Typography as="span" bold>
            {log.name}
          </Typography>{" "}
          will be cleared and{" "}
          <Typography as="span" bold>
            {numberOfClearableCurves}
          </Typography>{" "}
          curve selections will be deleted.
        </Typography>
      </Row>
      <ActionButtons>
        <Button color="danger" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={() => {
            dispatch(clearCurvesForLog(log));
            onClose();
          }}
        >
          Clear
        </Button>
      </ActionButtons>
    </Dialog>
  );
};

const Dialog = styled(EdsDialog)`
  width: 31.5rem;
`;

const Row = styled.div`
  margin: 0 1rem 1rem;
`;

const ActionButtons = styled(Row)`
  align-items: center;
  display: flex;

  & > button,
  & > button:disabled {
    margin-right: 1rem;
  }
`;

export default LogClearConfirmationDialog;
