import {
  Button as EdsButton,
  CircularProgress,
  Icon
} from "@equinor/eds-core-react";
import React, { memo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useRequest } from "../../../../hooks";
import SiteComGroup from "../../../../models/siteComGroup";
import {
  selectAvailableSiteComGroups,
  selectIsOrderCompleted,
  selectOrderId,
  selectSiteComGroup
} from "../../../../state/currentOrder/selectors";
import { RequestType } from "../../../../state/request";
import { addSnack } from "../../../../state/snacks/reducer";
import { colors } from "../../../../styles/Colors";
import { SelectMenu } from "../../../SelectMenu/SelectMenu";
import Tooltip from "../../../Tooltip";

const TargetWellboreListSiteComEntry = (): React.ReactElement => {
  const dispatch = useDispatch();

  const orderId = useSelector(selectOrderId);
  const availableSiteComGroups = useSelector(selectAvailableSiteComGroups);
  const siteComGroup = useSelector(selectSiteComGroup);
  const isOrderCompleted = useSelector(selectIsOrderCompleted);

  const errorMessage = "The access group could not be set, please try again";

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
    setError
  } = useForm({
    defaultValues: {
      siteComGroup: availableSiteComGroups.find(
        (group) => group.name === siteComGroup
      )
    },
    criteriaMode: "all"
  });

  const {
    invokerFunction: onSubmit,
    isLoading,
    isSuccess,
    isError
  } = useRequest({
    requestType: RequestType.UpdateSiteComGroupForOrder,
    successCallback: () => {
      reset(getValues());
      dispatch(addSnack("The access group was successfully updated"));
    },
    errorCallback: () => dispatch(addSnack(errorMessage)),
    payloadGenerator: {
      func: (data) => [orderId, data.siteComGroup as SiteComGroup],
      deps: [orderId]
    },
    setError
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="siteComGroup"
        control={control}
        rules={{ required: "This field is required" }}
        render={({
          field: { ref, onChange, value, ...props },
          fieldState: { invalid }
        }) => (
          <SelectMenu
            {...props}
            label="Access Group"
            inputRef={ref}
            onSelect={(group) => onChange(group)}
            data={availableSiteComGroups}
            value={value?.id ?? ""}
            disabled={isLoading || isOrderCompleted}
            errorText={invalid ? "This field is required" : undefined}
            variant="text-field"
          />
        )}
      />
      <Tooltip
        title={
          isOrderCompleted
            ? "Changes cannot be made to the order after completion"
            : ""
        }
      >
        <Button
          type="submit"
          variant="ghost"
          disabled={!isDirty || isLoading || isOrderCompleted}
        >
          <Icon name="save" size={20} />
        </Button>
      </Tooltip>
      <IconContainer>
        {isLoading && <Spinner />}
        {isSuccess && !isDirty && (
          <Icon
            name="check"
            size={20}
            color={colors.interactive.successResting}
          />
        )}
        {isError && (
          <Tooltip title={errorMessage}>
            <Icon
              name="errorOutlined"
              size={20}
              color={colors.interactive.dangerResting}
            />
          </Tooltip>
        )}
      </IconContainer>
    </Form>
  );
};

const Button = styled(EdsButton)`
  &,
  &:disabled {
    margin-top: 1rem;
  }
`;

const Form = styled.form`
  display: contents;

  &&& > div:first-of-type {
    grid-column: 1 / 3;
    min-width: 7.25rem;
    width: 100%;
  }
`;

const IconContainer = styled.div`
  margin-top: 1rem;
  width: 1.25rem;
`;

const Spinner = styled(CircularProgress)`
  height: auto;
  width: 1rem;
`;

export default memo(TargetWellboreListSiteComEntry);
