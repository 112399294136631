import { Table, Typography } from "@equinor/eds-core-react";
import React from "react";
import styled from "styled-components";

import DrillingStatus from "../../../../models/drillingStatus";
import StatusLabel from "../../../StatusLabel/StatusLabel";

const { Cell, Row: EdsRow } = Table;

type Props = {
  drillingStatusLabel?: DrillingStatus;
  label?: string;
  header: string;
  text: string;
};

function SaveAndSubmitStatusTableRow({
  drillingStatusLabel,
  label,
  header,
  text
}: Props): React.ReactElement {
  return (
    <Row>
      {drillingStatusLabel ? (
        <Cell>{<StatusLabel status={drillingStatusLabel} />}</Cell>
      ) : (
        <Cell>
          <Typography color="primary">{label}</Typography>
        </Cell>
      )}
      <Cell>
        <ContainerCell>
          <Typography variant="overline">{header}</Typography>
          <Typography variant="caption">{text}</Typography>
        </ContainerCell>
      </Cell>
    </Row>
  );
}

const Row = styled(EdsRow)`
  & > td {
    border-bottom: 0;

    &:first-child {
      text-align: right;
    }
  }
`;

const ContainerCell = styled.div`
  display: flex;
  flex-direction: column;
`;

export default SaveAndSubmitStatusTableRow;
