import { Table } from "@equinor/eds-core-react";
import React, { memo } from "react";
import styled from "styled-components";

const { Cell, Head, Row } = Table;

function LogTableHeader(): React.ReactElement {
  return (
    <Head sticky>
      <Row>
        <Cell>Wellbore name</Cell>
        <Cell>Field name</Cell>
        <Cell>Section</Cell>
        <Cell>Start time</Cell>
        <Cell>Status</Cell>
        <Cell>Last updated</Cell>
        <EllipsisCell>{""}</EllipsisCell>
      </Row>
    </Head>
  );
}

const EllipsisCell = styled(Cell)`
  width: 2rem;
`;

export default memo(LogTableHeader);
