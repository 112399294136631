import {
  DependencyList,
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

type ContextMenu = FunctionComponent<any>;
type ShowContextMenu = () => void;
type HideContextMenu = () => void;

const emptyComponent = memo(() => null);

export const useContextMenu = (
  component: FunctionComponent<any>,
  deps: DependencyList = []
): [ContextMenu, ShowContextMenu, HideContextMenu] => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextMenu = useMemo(() => component, deps);

  const [isShown, setShown] = useState(false);
  const [container, setContainer] = useState<ContextMenu>(emptyComponent);

  const showContextMenu = useCallback(() => setShown(true), []);
  const hideContextMenu = useCallback(() => setShown(false), []);

  useEffect(() => {
    if (isShown) {
      setContainer(memo(contextMenu));
    } else {
      setContainer(emptyComponent);
    }
  }, [contextMenu, isShown]);

  return [container, showContextMenu, hideContextMenu];
};
