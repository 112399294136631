import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FailedRequestPayload,
  isConcurrentRequestType,
  Request,
  RequestError,
  RequestPayload,
  RequestState,
  RequestStatus,
  RequestType,
  TRequestType
} from "./types";

export const initialState = Object.values(RequestType).reduce(
  (state, request) => {
    if (isConcurrentRequestType(request)) {
      state[request] = {} as Record<string, Request>;
    } else {
      state[request] = { status: RequestStatus.Idle };
    }
    return state;
  },
  {} as RequestState
);

const requestsSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    fetchRequest: {
      reducer(
        state,
        { payload: { request, id } }: PayloadAction<RequestPayload>
      ) {
        const progressState = { status: RequestStatus.InProgress };

        if (isConcurrentRequestType(request)) {
          if (id) {
            state[request][id] = progressState;
          }
        } else {
          state[request] = progressState;
        }
      },
      prepare: (request: TRequestType, id?: string) => ({
        payload: { request, id }
      })
    },
    requestSucceeded: {
      reducer(
        state,
        { payload: { request, id } }: PayloadAction<RequestPayload>
      ) {
        const successState = { status: RequestStatus.Succeeded };

        if (isConcurrentRequestType(request)) {
          if (id) {
            state[request][id] = successState;
          }
        } else {
          state[request] = successState;
        }
      },
      prepare: (request: TRequestType, id?: string) => ({
        payload: { request, id }
      })
    },
    requestFailed: {
      reducer(
        state,
        { payload: { request, id, error } }: PayloadAction<FailedRequestPayload>
      ) {
        const errorState = { status: RequestStatus.Failed, error };

        if (isConcurrentRequestType(request)) {
          if (id) {
            state[request][id] = errorState;
          }
        } else {
          state[request] = errorState;
        }
      },
      prepare: (error: RequestError, request: TRequestType, id?: string) => ({
        payload: {
          request,
          id,
          error
        }
      })
    },
    resetRequest: {
      reducer(
        state,
        { payload: { request, id } }: PayloadAction<RequestPayload>
      ) {
        const initialRequestState = { status: RequestStatus.Idle };

        if (isConcurrentRequestType(request)) {
          if (id) {
            state[request][id] = initialRequestState;
          } else {
            state[request] = {};
          }
        } else {
          state[request] = initialRequestState;
        }
      },
      prepare: (request: TRequestType, id?: string) => ({
        payload: { request, id }
      })
    }
  }
});

export const { fetchRequest, resetRequest, requestSucceeded, requestFailed } =
  requestsSlice.actions;

export default requestsSlice.reducer;
