import { createApiError } from "../models/api/apiError";
import Field from "../models/field";
import apiClient from "./apiClient";

async function getFields(abortSignal: AbortSignal): Promise<Field[]> {
  const response = await apiClient.get("/fields", abortSignal);

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to fetch fields", json);
  }
}

export default {
  getFields
};
