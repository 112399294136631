import { Accordion as EdsAccordion, Typography } from "@equinor/eds-core-react";
import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useParamSelector } from "../../../../hooks";
import { selectIsAccordionExpandedSelectorFactory } from "../../../../state/currentOrder/accordionState/selectors";
import { setServiceAccordionState } from "../../../../state/currentOrder/reducer";
import { selectProviderServiceToolSelectorFactory } from "../../../../state/currentOrder/selectors";
import { selectTheme } from "../../../../state/settings/selectors";
import { Theme } from "../../../../state/settings/types";
import { colors } from "../../../../styles/Colors";
import ServiceSelectedCurvesLabel from "./ServiceSelectedCurvesLabel";
import ToolAccordionItem from "./ToolAccordionItem";

const {
  Header: EdsAccordionHeader,
  Item: EdsAccordionItem,
  Panel: EdsAccordionPanel
} = EdsAccordion;

type Props = {
  logId: string;
  serviceId: string;
};

function ServiceAccordionItem({
  logId,
  serviceId,
  ...rest
}: Props): React.ReactElement {
  const dispatch = useDispatch();

  const selectProviderServiceTool = useMemo(
    selectProviderServiceToolSelectorFactory,
    []
  );
  const selectExpanded = useMemo(selectIsAccordionExpandedSelectorFactory, []);

  const service = useParamSelector(selectProviderServiceTool, logId, serviceId);
  const expanded = useParamSelector(selectExpanded, logId, serviceId);
  const theme = useSelector(selectTheme);

  return (
    <AccordionItem {...rest} isExpanded={expanded} theme={theme}>
      <EdsAccordionHeader
        onToggle={() =>
          dispatch(setServiceAccordionState(logId, serviceId, !expanded))
        }
      >
        <HeaderItem>{service.serviceName}</HeaderItem>
        <HeaderItem>
          <Typography color="secondary">
            {service.serviceProviderName}
          </Typography>
        </HeaderItem>
        <HeaderSpace />
        <HeaderItemLabel>
          <ServiceSelectedCurvesLabel logId={logId} serviceId={serviceId} />
        </HeaderItemLabel>
      </EdsAccordionHeader>
      <AccordionPanel cTheme={theme}>
        {expanded && (
          <ToolAccordion>
            {service.tools.map((tool) => (
              <ToolAccordionItem
                key={tool.id}
                logId={logId}
                serviceId={serviceId}
                toolId={tool.id}
              />
            ))}
          </ToolAccordion>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

const ToolAccordion = styled(EdsAccordion)`
  & > div > h2 {
    border-right: unset;
  }

  & > div:first-child > h2 {
    border-top: unset;
  }

  & > div:last-child > * {
    border-bottom: unset;
  }
`;

const AccordionItem = styled(EdsAccordionItem)<{
  theme: Theme;
  isExpanded: boolean;
}>`
  & > h2 {
    background: ${colors.ui.backgroundDefault};
    display: flex;
    position: sticky;
    z-index: 4;
    ${({ isExpanded }) =>
      isExpanded
        ? `&&& { border-bottom: 1px solid ${colors.ui.backgroundMedium}; }`
        : ""}
    ${({ theme }) => (theme === Theme.Compact ? `top: 2rem;` : `top: 3rem;`)}
    & > button {
      ${({ theme }) => (theme === Theme.Compact ? `height: 2rem;` : ``)}
    }
  }
`;

const AccordionPanel = styled(EdsAccordionPanel)<{ cTheme: Theme }>`
  border-right: unset;
  border-top: unset;
  min-height: auto;
  padding: 0 0 0 3rem;
  position: sticky;
  top: ${({ cTheme }) => (cTheme === Theme.Compact ? "4rem" : "6rem")};
  z-index: 2;
`;

const HeaderItem = styled.div`
  flex-shrink: 0;
  overflow: hidden;
  padding-right: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10rem;
`;

const HeaderItemLabel = styled.div`
  flex-shrink: 1;
  margin-left: 1rem;
  width: 13rem;
  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const HeaderSpace = styled.div`
  flex-shrink: 10;
  min-width: 1rem;
  overflow: hidden;
  padding-right: 1rem;
  width: 41rem;
`;

export default memo(ServiceAccordionItem);
