import { all } from "redux-saga/effects";

import {
  watchAddCurve,
  watchClearCurvesForLog,
  watchCopyCurvesForLog
} from "./currentOrder/saga";
import { watchRequestFlow } from "./request";

export default function* rootSaga(): Generator<any> {
  yield all([
    watchRequestFlow(),
    watchAddCurve(),
    watchClearCurvesForLog(),
    watchCopyCurvesForLog()
  ]);
}
