import "./index.css";

import { InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import GenericErrorPanel from "./components/GenericErrorPanel/GenericErrorPanel";
import Loading from "./Loading";
import { authRequest, msalInstance } from "./msal/MsalAuthProvider";
import store from "./state/state";
import Unauthorized from "./Unauthorized";

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <UnauthenticatedTemplate>
        <Unauthorized />
      </UnauthenticatedTemplate>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        loadingComponent={Loading}
        errorComponent={GenericErrorPanel}
      >
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
