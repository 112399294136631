export type FieldErrors = Record<string, string[]>;

type ApiErrorPayload = Partial<{
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: FieldErrors;
}>;

type ApiError = ApiErrorPayload & { message: string };

export const createApiError = (
  message: string,
  payload: ApiErrorPayload
): Error => {
  const error = new Error(message);
  return enhanceError(error, payload);
};

const enhanceError = (error: Error, payload: ApiErrorPayload) => {
  Object.assign(error, { isApiError: true, payload });
  return error;
};

export default ApiError;
