import { EdsProvider, Table as EdsTable } from "@equinor/eds-core-react";
import React, { memo, useCallback, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useContextMenu } from "../../../../hooks";
import Contact from "../../../../models/contact";
import { selectContactIds } from "../../../../state/currentOrder/selectors";
import { selectTheme } from "../../../../state/settings/selectors";
import ContactContextMenu from "./ContactContextMenu";
import ContactDeleteDialog from "./ContactDeleteDialog";
import ContactDialog from "./ContactDialog";
import ContactTableHeader from "./ContactTableHeader";
import ContactTableRow from "./ContactTableRow";

const { Body } = EdsTable;

function ContactTable(): React.ReactElement {
  const contactIds = useSelector(selectContactIds);
  const anchor = useRef(null as HTMLElement | null);
  const [selectedContact, setSelectedContact] = useState({} as Contact);

  const [showContactDialog, hideContactDialog] = useModal(
    () => (
      <ContactDialog contact={selectedContact} onClose={hideContactDialog} />
    ),
    [selectedContact]
  );

  const [showContactDeleteDialog, hideContactDeleteDialog] = useModal(
    () => (
      <ContactDeleteDialog
        contact={selectedContact}
        onClose={hideContactDeleteDialog}
      />
    ),
    [selectedContact]
  );

  const [ContextMenu, showContactContextMenu, hideContactContextMenu] =
    useContextMenu(
      () => (
        <ContactContextMenu
          anchor={anchor.current}
          onClose={hideContactContextMenu}
          onEdit={showContactDialog}
          onDelete={showContactDeleteDialog}
        />
      ),
      [anchor.current, showContactDeleteDialog, showContactDialog]
    );

  const toggleContactContextMenu = useCallback(
    (element: HTMLElement | null, contact: Contact) => {
      if (anchor.current) {
        hideContactContextMenu();
        anchor.current = null;
      }
      if (element) {
        anchor.current = element;
        setSelectedContact(contact);
        showContactContextMenu();
      }
    },
    [hideContactContextMenu, showContactContextMenu]
  );

  const theme = useSelector(selectTheme);

  return (
    <Container>
      <EdsProvider density={theme}>
        <Table>
          <ContactTableHeader />
          <Body>
            {contactIds.map((id) => (
              <ContactTableRow
                key={id}
                id={id}
                callback={toggleContactContextMenu}
              />
            ))}
          </Body>
        </Table>
        <ContextMenu />
      </EdsProvider>
    </Container>
  );
}

const Container = styled.div`
  flex: 1 1 100%;
  overflow: auto;
`;

const Table = styled(EdsTable)`
  width: 100%;
`;

export default memo(ContactTable);
