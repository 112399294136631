import { createSelector, OutputParametricSelector } from "@reduxjs/toolkit";

import Curve, { CurveType } from "../../../models/curve";
import DrillingStatus from "../../../models/drillingStatus";
import Log from "../../../models/log";
import LogDepthCurve from "../../../models/logDepthCurve";
import LogTimeCurve from "../../../models/logTimeCurve";
import ProviderServiceTool from "../../../models/providerServiceTool";
import SiteComGroup from "../../../models/siteComGroup";
import TargetProject from "../../../models/targetProject";
import TargetSystem, {
  NonWITSMLTargetSystems,
  TargetSystemType
} from "../../../models/targetSystem";
import TimeStatus from "../../../models/timeStatus";
import Tool from "../../../models/tool";
import { by, byAndThenBy } from "../../../utils/SortHelpers";
import { BaseRootState } from "../../types";
import {
  SelectLogDepthCurvesSelector,
  selectLogDepthCurvesSelectorFactory,
  SelectLogSelector,
  selectLogSelectorFactory,
  SelectLogTimeCurvesSelector,
  selectLogTimeCurvesSelectorFactory,
  SelectTargetSystemNameForTargetWellboreSelector,
  selectTargetSystemNameForTargetWellboreSelectorFactory,
  selectTargetSystems,
  selectTargetWellboreIdsGroupedByTargetSystem
} from "../sharedSelectors";
import {
  TargetSystemAllValue,
  TargetSystemFilterValue,
  TargetSystemOrderedValue
} from "../types";

export const selectAvailableSiteComGroups = (
  state: BaseRootState
): SiteComGroup[] => state.currentOrder.referenceData.siteComGroups;

export const selectCurveFilter = (state: BaseRootState): string =>
  state.currentOrder.curveFilter;

export const selectRoles = createSelector(
  (state: BaseRootState) => state.currentOrder.referenceData.roles,
  (roles) => [...roles].sort(by((role) => role.name))
);

export const selectSelectedTargetSystem = (
  state: BaseRootState
): TargetSystemFilterValue => state.currentOrder.selectedTargetSystem;

export const selectAllProviderServiceTools = (
  state: BaseRootState
): ProviderServiceTool[] =>
  state.currentOrder.referenceData.providerServiceTools;

export type SelectServiceProviderIdForProviderServiceToolSelector =
  OutputParametricSelector<
    BaseRootState,
    string,
    string,
    (res1: ProviderServiceTool[], res2: string) => string
  >;
export const selectServiceProviderIdForProviderServiceToolSelectorFactory =
  (): SelectServiceProviderIdForProviderServiceToolSelector =>
    createSelector(
      selectAllProviderServiceTools,
      (_: BaseRootState, serviceId: string) => serviceId,
      (providerServiceTools, serviceId) => {
        const providerServiceTool = providerServiceTools.find(
          (providerServiceTool) => providerServiceTool.id === serviceId
        );
        return providerServiceTool ? providerServiceTool.serviceProviderId : "";
      }
    );

export const selectAllEligibleServiceProviderToolsSelector = createSelector(
  selectAllProviderServiceTools,
  selectTargetSystems,
  selectTargetWellboreIdsGroupedByTargetSystem,
  (providerServiceTools, systems, idsBySystem) => {
    const eligibleSystems = systems
      .filter((system) => !NonWITSMLTargetSystems.includes(system.name))
      .reduce(
        (result, system) =>
          result.set(
            system.serviceProviderId,
            idsBySystem[system.name]?.length > 0
          ),
        new Map<string, boolean>()
      );

    return providerServiceTools.filter((providerServiceTool) =>
      eligibleSystems.get(providerServiceTool.serviceProviderId)
    );
  }
);

type SelectProviderServiceToolsForProvidersSelector = OutputParametricSelector<
  BaseRootState,
  string,
  ProviderServiceTool[],
  (res1: ProviderServiceTool[], res2: Log) => ProviderServiceTool[]
>;
export const selectProviderServiceToolsForProvidersSelectorFactory = (
  selectLog?: SelectLogSelector
): SelectProviderServiceToolsForProvidersSelector => {
  if (!selectLog) {
    selectLog = selectLogSelectorFactory();
  }
  return createSelector(
    selectAllEligibleServiceProviderToolsSelector,
    selectLog,
    (providerServiceTools, log) =>
      providerServiceTools.filter(
        (tool) =>
          (tool.serviceName === "MWD" &&
            tool.serviceProviderId === log.mwdProviderId) ||
          (tool.serviceName === "ML" &&
            tool.serviceProviderId === log.mlProviderId)
      )
  );
};

type SelectProviderServiceToolsFilteredByCurveSelector =
  OutputParametricSelector<
    BaseRootState,
    string,
    ProviderServiceTool[],
    (res1: ProviderServiceTool[], res2: string) => ProviderServiceTool[]
  >;
export const selectProviderServiceToolsFilteredByCurveSelectorFactory = (
  selectProviderServiceToolsForProviders?: SelectProviderServiceToolsForProvidersSelector
): SelectProviderServiceToolsFilteredByCurveSelector => {
  if (!selectProviderServiceToolsForProviders) {
    selectProviderServiceToolsForProviders =
      selectProviderServiceToolsForProvidersSelectorFactory();
  }
  return createSelector(
    selectProviderServiceToolsForProviders,
    selectCurveFilter,
    (providerServiceTools, filter) => {
      if (!filter) {
        return providerServiceTools;
      }

      const result: ProviderServiceTool[] = [];
      for (const providerServiceTool of providerServiceTools) {
        const tools = filterToolByNameOrDescription(
          providerServiceTool,
          filter
        );
        if (tools.length > 0) {
          result.push({
            ...providerServiceTool,
            tools
          });
        }
      }
      return result;
    }
  );
};

const selectFilteredLogCurveIdsSelectorFactory = (
  selectLogTimeCurves?: SelectLogTimeCurvesSelector,
  selectLogDepthCurves?: SelectLogDepthCurvesSelector
) => {
  if (!selectLogTimeCurves) {
    selectLogTimeCurves = selectLogTimeCurvesSelectorFactory();
  }
  if (!selectLogDepthCurves) {
    selectLogDepthCurves = selectLogDepthCurvesSelectorFactory();
  }

  return createSelector(
    selectSelectedTargetSystem,
    selectTargetWellboreIdsGroupedByTargetSystem,
    selectLogTimeCurves,
    selectLogDepthCurves,
    (filter, targetWellboreIds, logTimeCurves, logDepthCurves) => {
      if (filter.id === TargetSystemAllValue.id) {
        return undefined;
      }

      const filteredTargetWellboreIds =
        findTargetWellboreIdsByTargetSystemFilter(filter, targetWellboreIds);

      return findCurveIdsFilteredByTargetWellboreIds(
        filteredTargetWellboreIds,
        logDepthCurves,
        logTimeCurves
      );
    }
  );
};

type SelectProviderServiceToolsFilteredByTargetSystemSelector =
  OutputParametricSelector<
    BaseRootState,
    string,
    ProviderServiceTool[],
    (
      res1: ProviderServiceTool[],
      res2?: { depthCurveIds: string[]; timeCurveIds: string[] }
    ) => ProviderServiceTool[]
  >;
export const selectProviderServiceToolsFilteredByTargetSystemSelectorFactory = (
  selectProviderServiceToolsFilteredByCurve?: SelectProviderServiceToolsFilteredByCurveSelector
): SelectProviderServiceToolsFilteredByTargetSystemSelector => {
  if (!selectProviderServiceToolsFilteredByCurve) {
    selectProviderServiceToolsFilteredByCurve =
      selectProviderServiceToolsFilteredByCurveSelectorFactory();
  }
  const selectFilteredLogCurveIds = selectFilteredLogCurveIdsSelectorFactory();

  return createSelector(
    selectProviderServiceToolsFilteredByCurve,
    selectFilteredLogCurveIds,
    (providerServiceTools, logCurveIds) => {
      if (!logCurveIds) {
        // filter is not set, nothing to filter by
        return providerServiceTools;
      }

      return filterProviderServiceToolsByTargetWellboreIds(
        providerServiceTools,
        logCurveIds
      );
    }
  );
};

export const selectAllTools = createSelector(
  selectAllProviderServiceTools,
  (providerServiceTools) =>
    providerServiceTools.flatMap(
      (providerServiceTool) => providerServiceTool.tools
    )
);

export type SelectToolByIdSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Tool | undefined,
  (res1: Tool[], res2: string) => Tool | undefined
>;
export const selectToolByIdSelectorFactory = (): SelectToolByIdSelector =>
  createSelector(
    selectAllTools,
    (_: BaseRootState, __: string, toolId: string) => toolId,
    (tools, toolId) => tools.find((tool) => tool.id === toolId)
  );

export type SelectProviderServiceToolByIdSelector = OutputParametricSelector<
  BaseRootState,
  string,
  ProviderServiceTool | undefined,
  (res1: ProviderServiceTool[], res2: string) => ProviderServiceTool | undefined
>;
export const selectProviderServiceToolByIdSelectorFactory =
  (): SelectProviderServiceToolByIdSelector =>
    createSelector(
      selectAllProviderServiceTools,
      (_: BaseRootState, __: string, serviceId: string) => serviceId,
      (providerServiceTools, serviceId) =>
        providerServiceTools.find(
          (providerService) => providerService.id === serviceId
        )
    );

type SelectServiceCurvesNormalizedSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Set<string>,
  (res: ProviderServiceTool | undefined) => Set<string>
>;
export const selectServiceCurvesNormalizedSelectorFactory = (
  selectProviderServiceTool?: SelectProviderServiceToolByIdSelector
): SelectServiceCurvesNormalizedSelector => {
  if (!selectProviderServiceTool) {
    selectProviderServiceTool = selectProviderServiceToolByIdSelectorFactory();
  }

  return createSelector(selectProviderServiceTool, (providerServiceTool) => {
    const normalizedServiceCurves = new Set<string>();

    if (!providerServiceTool) {
      return normalizedServiceCurves;
    }

    providerServiceTool.tools.forEach((tool) => {
      tool.timeCurves.forEach((curve) => normalizedServiceCurves.add(curve.id));
      tool.depthCurves.forEach((curve) =>
        normalizedServiceCurves.add(curve.id)
      );
    });

    return normalizedServiceCurves;
  });
};

type SelectNumberOfOrderedTimeCurvesForServiceSelector =
  OutputParametricSelector<
    BaseRootState,
    string,
    number,
    (res1: Set<string>, res2: Set<string>) => number
  >;
export const selectNumberOfOrderedTimeCurvesForServiceSelectorFactory = (
  selectTimeCurves?: SelectUniqueTimeCurveIdsOrderedForLogSelector,
  selectServiceCurves?: SelectServiceCurvesNormalizedSelector
): SelectNumberOfOrderedTimeCurvesForServiceSelector => {
  if (!selectTimeCurves) {
    selectTimeCurves = selectUniqueTimeCurveIdsOrderedForLogSelectorFactory();
  }
  if (!selectServiceCurves) {
    selectServiceCurves = selectServiceCurvesNormalizedSelectorFactory();
  }

  return createSelector(
    selectTimeCurves,
    selectServiceCurves,
    (timeCurves, serviceCurves) =>
      [...timeCurves].filter((timeCurves) => serviceCurves.has(timeCurves))
        .length
  );
};

type SelectNumberOfOrderedDepthCurvesForServiceSelector =
  OutputParametricSelector<
    BaseRootState,
    string,
    number,
    (res1: Set<string>, res2: Set<string>) => number
  >;
export const selectNumberOfOrderedDepthCurvesForServiceSelectorFactory = (
  selectDepthCurves?: SelectUniqueDepthCurveIdsOrderedForLogSelector,
  selectServiceCurves?: SelectServiceCurvesNormalizedSelector
): SelectNumberOfOrderedDepthCurvesForServiceSelector => {
  if (!selectDepthCurves) {
    selectDepthCurves = selectUniqueDepthCurveIdsOrderedForLogSelectorFactory();
  }
  if (!selectServiceCurves) {
    selectServiceCurves = selectServiceCurvesNormalizedSelectorFactory();
  }

  return createSelector(
    selectDepthCurves,
    selectServiceCurves,
    (depthCurves, serviceCurves) =>
      [...depthCurves].filter((depthCurve) => serviceCurves.has(depthCurve))
        .length
  );
};

type SelectNumberOfOrderedCurvesForServiceSelector = OutputParametricSelector<
  BaseRootState,
  string,
  number,
  (res1: number, res2: number) => number
>;
export const selectNumberOfOrderedCurvesForServiceSelectorFactory = (
  selectNumberOfOrderedTimeCurves?: SelectNumberOfOrderedTimeCurvesForServiceSelector,
  selectNumberOfOrderedDepthCurves?: SelectNumberOfOrderedDepthCurvesForServiceSelector
): SelectNumberOfOrderedCurvesForServiceSelector => {
  if (!selectNumberOfOrderedTimeCurves) {
    selectNumberOfOrderedTimeCurves =
      selectNumberOfOrderedTimeCurvesForServiceSelectorFactory();
  }
  if (!selectNumberOfOrderedDepthCurves) {
    selectNumberOfOrderedDepthCurves =
      selectNumberOfOrderedDepthCurvesForServiceSelectorFactory();
  }

  return createSelector(
    selectNumberOfOrderedTimeCurves,
    selectNumberOfOrderedDepthCurves,
    (timeCurves, depthCurves) => timeCurves + depthCurves
  );
};

type SelectToolCurvesNormalizedSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Set<string>,
  (res: Tool | undefined) => Set<string>
>;
export const selectToolCurvesNormalizedSelectorFactory = (
  selectTool?: SelectToolByIdSelector
): SelectToolCurvesNormalizedSelector => {
  if (!selectTool) {
    selectTool = selectToolByIdSelectorFactory();
  }

  return createSelector(selectTool, (tool) => {
    const normalizedCurves = new Set<string>();

    if (!tool) {
      return normalizedCurves;
    }

    tool.timeCurves.forEach((curve) => normalizedCurves.add(curve.id));
    tool.depthCurves.forEach((curve) => normalizedCurves.add(curve.id));

    return normalizedCurves;
  });
};

export const selectAllTimeCurves = createSelector(selectAllTools, (tools) =>
  tools.flatMap((tool) => tool.timeCurves)
);

export const selectAllDepthCurves = createSelector(selectAllTools, (tools) =>
  tools.flatMap((tool) => tool.depthCurves)
);

export const selectAllCurves = createSelector(
  selectAllTimeCurves,
  selectAllDepthCurves,
  (time, depth) => time.concat(depth)
);

export const selectAllCurvesNormalized = createSelector(
  selectAllCurves,
  (curves) =>
    curves.reduce(
      (result, curve) => ({ ...result, [curve.id]: curve }),
      {} as Record<string, Curve>
    )
);

type SelectCurveSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Curve | undefined,
  (res1: Curve[], res2: string | undefined) => Curve | undefined
>;
export const selectCurveSelectorFactory = (): SelectCurveSelector =>
  createSelector(
    selectAllCurves,
    (_: BaseRootState, __: string, id: string | undefined) => id,
    (curves, id) => curves.find((curve) => curve.id === id)
  );

type SelectIsCurveDisabledSelector = OutputParametricSelector<
  BaseRootState,
  string,
  boolean,
  (
    res1: Log,
    res2: Curve | undefined,
    res3: string,
    res4: LogTimeCurve[],
    res5: LogDepthCurve[],
    res6: Record<string, string[]>
  ) => boolean
>;
export const selectIsCurveDisabledSelectorFactory = (
  selectCurve?: SelectCurveSelector,
  selectLog?: SelectLogSelector,
  selectTargetSystemName?: SelectTargetSystemNameForTargetWellboreSelector,
  selectTimeCurves?: SelectLogTimeCurvesSelector,
  selectDepthCurves?: SelectLogDepthCurvesSelector
): SelectIsCurveDisabledSelector => {
  if (!selectCurve) {
    selectCurve = selectCurveSelectorFactory();
  }
  if (!selectLog) {
    selectLog = selectLogSelectorFactory();
  }
  if (!selectTargetSystemName) {
    selectTargetSystemName =
      selectTargetSystemNameForTargetWellboreSelectorFactory();
  }
  if (!selectTimeCurves) {
    selectTimeCurves = selectLogTimeCurvesSelectorFactory();
  }
  if (!selectDepthCurves) {
    selectDepthCurves = selectLogDepthCurvesSelectorFactory();
  }
  return createSelector(
    selectLog,
    selectCurve,
    selectTargetSystemName,
    selectTimeCurves,
    selectDepthCurves,
    selectTargetWellboreIdsGroupedByTargetSystem,
    (log, curve, system, logTimeCurves, logDepthCurves, idsBySystem) => {
      if (
        curve === undefined ||
        curveIsRiserRequired(curve, log.diameter) ||
        curveIsDefault(curve) ||
        curveIsLogLocked(curve, log) ||
        curveIsNotSupportedByTargetSystem(curve, system)
      ) {
        return true;
      }

      if (NonWITSMLTargetSystems.includes(system)) {
        return false;
      }

      const ids = NonWITSMLTargetSystems.flatMap((ts) => idsBySystem[ts]);
      return curveIsOrderedFor(logTimeCurves, logDepthCurves, curve.id, ...ids);
    }
  );
};

type SelectCurveTooltipSelector = OutputParametricSelector<
  BaseRootState,
  string,
  string,
  (
    res1: Log,
    res2: Curve,
    res3: LogDepthCurve | LogTimeCurve | undefined
  ) => string
>;
export const selectCurveTooltipSelectorFactory = (
  selectCurve?: SelectCurveSelector,
  selectLog?: SelectLogSelector,
  selectLogCurve?: SelectLogCurveSelector
): SelectCurveTooltipSelector => {
  if (!selectCurve) {
    selectCurve = selectCurveSelectorFactory();
  }
  if (!selectLog) {
    selectLog = selectLogSelectorFactory();
  }
  if (!selectLogCurve) {
    selectLogCurve = selectLogCurveSelectorFactory();
  }
  return createSelector(
    selectLog,
    selectCurve,
    selectLogCurve,
    (log, curve, logCurve) => {
      if (curveIsRiserRequired(curve, log.diameter)) {
        return "Riser is required. Cannot be ordered";
      }
      if (curveIsDefault(curve) && logCurve) {
        return "Included by default. Cannot be removed";
      }
      return "";
    }
  );
};

type SelectCurves = OutputParametricSelector<
  BaseRootState,
  string[],
  Curve[],
  (res1: Curve[], res2: string[]) => Curve[]
>;
export const selectCurvesSelectorFactory = (): SelectCurves =>
  createSelector(
    selectAllCurves,
    (_: BaseRootState, ids: string[]) => ids,
    (curves, ids) => curves.filter((curve) => ids.find((id) => curve.id === id))
  );

type SelectCurveTypes = OutputParametricSelector<
  BaseRootState,
  string[],
  Record<CurveType, string>,
  (res1: Curve[], res2: string[]) => Record<CurveType, string>
>;
export const selectCurveTypesSelectorFactory = (
  selectCurves: SelectCurves
): SelectCurveTypes =>
  createSelector(selectCurves, (curves) =>
    curves.reduce(
      (result, curve) => ({
        ...result,
        [curve.type]: curve.id
      }),
      {} as Record<CurveType, string>
    )
  );

type SelectCurveName = OutputParametricSelector<
  BaseRootState,
  string[],
  string,
  (res1: Curve[], res2: string[]) => string
>;
export const selectCurveNameSelectorFactory = (
  selectCurves: SelectCurves
): SelectCurveName =>
  createSelector(selectCurves, (curves) =>
    curves.length > 0 ? curves[0].name : ""
  );

type SelectCurveDescription = OutputParametricSelector<
  BaseRootState,
  string[],
  string,
  (res1: Curve[], res2: string[]) => string
>;

export const selectCurveDescriptionSelectorFactory = (
  selectCurves: SelectCurves
): SelectCurveDescription =>
  createSelector(selectCurves, (curves) =>
    curves.length > 0 && curves[0].description ? curves[0].description : ""
  );

type SelectLogCurveSelector = OutputParametricSelector<
  BaseRootState,
  string,
  LogTimeCurve | LogDepthCurve | undefined,
  (
    res1: LogTimeCurve[],
    res2: LogDepthCurve[],
    res3: string,
    res4: string
  ) => LogTimeCurve | LogDepthCurve | undefined
>;
export const selectLogCurveSelectorFactory = (
  selectTimeCurves?: SelectLogTimeCurvesSelector,
  selectDepthCurves?: SelectLogDepthCurvesSelector
): SelectLogCurveSelector => {
  if (!selectTimeCurves) {
    selectTimeCurves = selectLogTimeCurvesSelectorFactory();
  }
  if (!selectDepthCurves) {
    selectDepthCurves = selectLogDepthCurvesSelectorFactory();
  }
  return createSelector(
    selectTimeCurves,
    selectDepthCurves,
    (_: any, __: any, curveId: string) => curveId,
    (_: any, __: any, ___: any, targetWellboreId: string) => targetWellboreId,
    (timeCurves, depthCurves, curveId, targetWellboreId) => {
      const timeCurve = timeCurves.find(
        (curve) =>
          curve.timeCurveId === curveId &&
          curve.targetWellboreId === targetWellboreId
      );
      if (timeCurve) {
        return timeCurve;
      }
      return depthCurves.find(
        (curve) =>
          curve.depthCurveId === curveId &&
          curve.targetWellboreId === targetWellboreId
      );
    }
  );
};

type SelectUniqueTimeCurveIdsOrderedForLogSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Set<string>,
  (res: LogTimeCurve[]) => Set<string>
>;
export const selectUniqueTimeCurveIdsOrderedForLogSelectorFactory = (
  selectTimeCurves?: SelectLogTimeCurvesSelector
): SelectUniqueTimeCurveIdsOrderedForLogSelector => {
  if (!selectTimeCurves) {
    selectTimeCurves = selectLogTimeCurvesSelectorFactory();
  }
  return createSelector(selectTimeCurves, (curves) =>
    curves.reduce(
      (result, curve) => result.add(curve.timeCurveId),
      new Set<string>()
    )
  );
};

type SelectUniqueDepthCurveIdsOrderedForLogSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Set<string>,
  (res: LogDepthCurve[]) => Set<string>
>;
export const selectUniqueDepthCurveIdsOrderedForLogSelectorFactory = (
  selectDepthCurves?: SelectLogDepthCurvesSelector
): SelectUniqueDepthCurveIdsOrderedForLogSelector => {
  if (!selectDepthCurves) {
    selectDepthCurves = selectLogDepthCurvesSelectorFactory();
  }
  return createSelector(selectDepthCurves, (curves) =>
    curves.reduce(
      (result, curve) => result.add(curve.depthCurveId),
      new Set<string>()
    )
  );
};

type SelectNumberOfOrderedCurvesForLogSelector = OutputParametricSelector<
  BaseRootState,
  string,
  number,
  (res1: Set<string>, res2: Set<string>) => number
>;
export const selectNumberOfOrderedCurvesForLogSelectorFactory = (
  selectTimeCurves?: SelectUniqueTimeCurveIdsOrderedForLogSelector,
  selectDepthCurves?: SelectUniqueDepthCurveIdsOrderedForLogSelector
): SelectNumberOfOrderedCurvesForLogSelector => {
  if (!selectTimeCurves) {
    selectTimeCurves = selectUniqueTimeCurveIdsOrderedForLogSelectorFactory();
  }
  if (!selectDepthCurves) {
    selectDepthCurves = selectUniqueDepthCurveIdsOrderedForLogSelectorFactory();
  }
  return createSelector(
    selectTimeCurves,
    selectDepthCurves,
    (timeCurves, depthCurves) => timeCurves.size + depthCurves.size
  );
};

type SelectNumberOfOrderedTimeCurvesForToolSelector = OutputParametricSelector<
  BaseRootState,
  string,
  number,
  (res1: Set<string>, res2: Set<string>) => number
>;
export const selectNumberOfOrderedTimeCurvesForToolSelectorFactory = (
  selectTimeCurves?: SelectUniqueTimeCurveIdsOrderedForLogSelector,
  selectToolCurves?: SelectToolCurvesNormalizedSelector
): SelectNumberOfOrderedTimeCurvesForToolSelector => {
  if (!selectTimeCurves) {
    selectTimeCurves = selectUniqueTimeCurveIdsOrderedForLogSelectorFactory();
  }
  if (!selectToolCurves) {
    selectToolCurves = selectToolCurvesNormalizedSelectorFactory();
  }

  return createSelector(
    selectTimeCurves,
    selectToolCurves,
    (timeCurves, toolCurves) =>
      [...timeCurves].filter((timeCurve) => toolCurves.has(timeCurve)).length
  );
};

type SelectNumberOfOrderedDepthCurvesForToolSelector = OutputParametricSelector<
  BaseRootState,
  string,
  number,
  (res1: Set<string>, res2: Set<string>) => number
>;
export const selectNumberOfOrderedDepthCurvesForToolSelectorFactory = (
  selectDepthCurves?: SelectUniqueDepthCurveIdsOrderedForLogSelector,
  selectToolCurves?: SelectToolCurvesNormalizedSelector
): SelectNumberOfOrderedDepthCurvesForToolSelector => {
  if (!selectDepthCurves) {
    selectDepthCurves = selectUniqueDepthCurveIdsOrderedForLogSelectorFactory();
  }
  if (!selectToolCurves) {
    selectToolCurves = selectToolCurvesNormalizedSelectorFactory();
  }

  return createSelector(
    selectDepthCurves,
    selectToolCurves,
    (depthCurves, toolCurves) =>
      [...depthCurves].filter((depthCurve) => toolCurves.has(depthCurve)).length
  );
};

type SelectNumberOfOrderedCurvesForToolSelector = OutputParametricSelector<
  BaseRootState,
  string,
  number,
  (res1: number, res2: number) => number
>;
export const selectNumberOfOrderedCurvesForToolSelectorFactory = (
  selectNumberOfOrderedTimeCurves?: SelectNumberOfOrderedTimeCurvesForToolSelector,
  selectNumberOfOrderedDepthCurves?: SelectNumberOfOrderedDepthCurvesForToolSelector
): SelectNumberOfOrderedCurvesForToolSelector => {
  if (!selectNumberOfOrderedTimeCurves) {
    selectNumberOfOrderedTimeCurves =
      selectNumberOfOrderedTimeCurvesForToolSelectorFactory();
  }
  if (!selectNumberOfOrderedDepthCurves) {
    selectNumberOfOrderedDepthCurves =
      selectNumberOfOrderedDepthCurvesForToolSelectorFactory();
  }
  return createSelector(
    selectNumberOfOrderedTimeCurves,
    selectNumberOfOrderedDepthCurves,
    (timeCurves, depthCurves) => timeCurves + depthCurves
  );
};

type SelectOrderedTimeCurves = OutputParametricSelector<
  BaseRootState,
  string,
  Curve[],
  (res1: Curve[], res2: Set<string>) => Curve[]
>;
export const selectOrderedTimeCurvesSelectorFactory = (
  selectTimeCurveIds?: SelectUniqueTimeCurveIdsOrderedForLogSelector
): SelectOrderedTimeCurves => {
  if (!selectTimeCurveIds) {
    selectTimeCurveIds = selectUniqueTimeCurveIdsOrderedForLogSelectorFactory();
  }
  return createSelector(
    selectAllTimeCurves,
    selectTimeCurveIds,
    (timeCurves, curveIds) =>
      timeCurves.filter((curve) => curveIds.has(curve.id))
  );
};

type SelectOrderedDepthCurves = OutputParametricSelector<
  BaseRootState,
  string,
  Curve[],
  (res1: Curve[], res2: Set<string>) => Curve[]
>;
export const selectOrderedDepthCurvesSelectorFactory = (
  selectDepthCurveIds?: SelectUniqueDepthCurveIdsOrderedForLogSelector
): SelectOrderedDepthCurves => {
  if (!selectDepthCurveIds) {
    selectDepthCurveIds =
      selectUniqueDepthCurveIdsOrderedForLogSelectorFactory();
  }
  return createSelector(
    selectAllDepthCurves,
    selectDepthCurveIds,
    (depthCurves, curveIds) =>
      depthCurves.filter((curve) => curveIds.has(curve.id))
  );
};

type SelectClearableLogTimeCurvesSelector = OutputParametricSelector<
  BaseRootState,
  string,
  LogTimeCurve[],
  (res1: Log, res2: LogTimeCurve[], res3: Curve[]) => LogTimeCurve[]
>;
export const selectClearableLogTimeCurvesSelectorFactory = (
  selectLog?: SelectLogSelector,
  selectLogTimeCurves?: SelectLogTimeCurvesSelector,
  selectTimeCurves?: SelectOrderedTimeCurves
): SelectClearableLogTimeCurvesSelector => {
  if (!selectLog) {
    selectLog = selectLogSelectorFactory();
  }
  if (!selectLogTimeCurves) {
    selectLogTimeCurves = selectLogTimeCurvesSelectorFactory();
  }
  if (!selectTimeCurves) {
    selectTimeCurves = selectOrderedTimeCurvesSelectorFactory(
      selectUniqueTimeCurveIdsOrderedForLogSelectorFactory(selectLogTimeCurves)
    );
  }
  return createSelector(
    selectLog,
    selectLogTimeCurves,
    selectTimeCurves,
    (log, logCurves, curves) => {
      const clearableCurves = curves
        .filter((curve) => !curveIsDefault(curve))
        .filter((curve) => !curveIsRiserRequired(curve, log.diameter))
        .reduce((result, curve) => result.add(curve.id), new Set<string>());

      return logCurves.filter((curve) =>
        clearableCurves.has(curve.timeCurveId)
      );
    }
  );
};

type SelectClearableLogDepthCurvesSelector = OutputParametricSelector<
  BaseRootState,
  string,
  LogDepthCurve[],
  (res1: Log, res2: LogDepthCurve[], res3: Curve[]) => LogDepthCurve[]
>;
export const selectClearableLogDepthCurvesSelectorFactory = (
  selectLog?: SelectLogSelector,
  selectLogDepthCurves?: SelectLogDepthCurvesSelector,
  selectDepthCurves?: SelectOrderedDepthCurves
): SelectClearableLogDepthCurvesSelector => {
  if (!selectLog) {
    selectLog = selectLogSelectorFactory();
  }
  if (!selectLogDepthCurves) {
    selectLogDepthCurves = selectLogDepthCurvesSelectorFactory();
  }
  if (!selectDepthCurves) {
    selectDepthCurves = selectOrderedDepthCurvesSelectorFactory(
      selectUniqueDepthCurveIdsOrderedForLogSelectorFactory(
        selectLogDepthCurves
      )
    );
  }
  return createSelector(
    selectLog,
    selectLogDepthCurves,
    selectDepthCurves,
    (log, logCurves, curves) => {
      const clearableCurves = curves
        .filter((curve) => !curveIsRiserRequired(curve, log.diameter))
        .reduce((result, curve) => result.add(curve.id), new Set<string>());

      return logCurves.filter((curve) =>
        clearableCurves.has(curve.depthCurveId)
      );
    }
  );
};

type SelectNumberOfClearableCurvesSelector = OutputParametricSelector<
  BaseRootState,
  string,
  number,
  (res2: LogTimeCurve[], res3: LogDepthCurve[]) => number
>;
export const selectNumberOfClearableCurvesSelectorFactory = (
  selectTimeCurves?: SelectClearableLogTimeCurvesSelector,
  selectDepthCurves?: SelectClearableLogDepthCurvesSelector
): SelectNumberOfClearableCurvesSelector => {
  if (!selectTimeCurves) {
    selectTimeCurves = selectClearableLogTimeCurvesSelectorFactory();
  }
  if (!selectDepthCurves) {
    selectDepthCurves = selectClearableLogDepthCurvesSelectorFactory();
  }
  return createSelector(
    selectTimeCurves,
    selectDepthCurves,
    (timeCurves, depthCurves) =>
      (timeCurves?.length ?? 0) + (depthCurves?.length ?? 0)
  );
};

type SelectToolsFilteredSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Tool[],
  (res1: ProviderServiceTool[]) => Tool[]
>;
export const selectToolsFilteredSelectorFactory = (
  selectProviderServiceTools?: SelectProviderServiceToolsSelector
): SelectToolsFilteredSelector => {
  if (!selectProviderServiceTools) {
    selectProviderServiceTools = selectProviderServiceToolsSelectorFactory();
  }

  return createSelector(selectProviderServiceTools, (providerServiceTools) =>
    providerServiceTools.flatMap(
      (providerServiceTool) => providerServiceTool.tools
    )
  );
};

type CurveIdsGroupedByName = { name: string; ids: string[] };
type SelectCurveIdsGroupedByNameForToolSelector = OutputParametricSelector<
  BaseRootState,
  string,
  CurveIdsGroupedByName[],
  (res1: Tool[], res2: string) => CurveIdsGroupedByName[]
>;
export const selectCurveIdsGroupedByNameForToolSelectorFactory = (
  selectToolsFiltered?: SelectToolsFilteredSelector
): SelectCurveIdsGroupedByNameForToolSelector => {
  if (!selectToolsFiltered) {
    selectToolsFiltered = selectToolsFilteredSelectorFactory();
  }

  return createSelector(
    selectToolsFiltered,
    (_: BaseRootState, __: any, toolId: string) => toolId,
    (tools, id) => {
      const tool = tools.find((tool) => tool.id === id);
      if (tool) {
        const curveIdsGroupedByName = tool.timeCurves
          .concat(tool.depthCurves)
          .reduce(
            (result, curve) => ({
              ...result,
              [curve.name]: [...(result[curve.name] || []), curve.id]
            }),
            {} as Record<string, string[]>
          );
        return Object.entries(curveIdsGroupedByName)
          .sort(by(([name]) => name))
          .map(([name, ids]) => ({
            name,
            ids
          }));
      }
      return [];
    }
  );
};

type SelectProviderServiceToolsSelector = OutputParametricSelector<
  BaseRootState,
  string,
  ProviderServiceTool[],
  (res1: ProviderServiceTool[]) => ProviderServiceTool[]
>;
export const selectProviderServiceToolsSelectorFactory = (
  selectProviderServiceTools?: SelectProviderServiceToolsFilteredByTargetSystemSelector
): SelectProviderServiceToolsSelector => {
  if (!selectProviderServiceTools) {
    selectProviderServiceTools =
      selectProviderServiceToolsFilteredByTargetSystemSelectorFactory();
  }
  return createSelector(selectProviderServiceTools, (providerServiceTools) =>
    providerServiceTools
      .map((providerServiceTool) => ({
        ...providerServiceTool,
        tools: providerServiceTool.tools
          .map((tool) => ({
            ...tool,
            depthCurves: [...tool.depthCurves].sort(by((curve) => curve.name)),
            timeCurves: [...tool.timeCurves].sort(by((curve) => curve.name))
          }))
          .sort(by((tool) => tool.name))
      }))
      .sort(
        byAndThenBy(
          (providerServiceTool) => providerServiceTool.serviceProviderName,
          (providerServiceTool) => providerServiceTool.serviceName
        )
      )
  );
};

export type SelectProviderServiceToolSelector = OutputParametricSelector<
  BaseRootState,
  string,
  ProviderServiceTool,
  (res1: ProviderServiceTool[], res2: string) => ProviderServiceTool
>;
export const selectProviderServiceToolSelectorFactory = (
  selectProviderServiceTools?: SelectProviderServiceToolsSelector
): SelectProviderServiceToolSelector => {
  if (!selectProviderServiceTools) {
    selectProviderServiceTools = selectProviderServiceToolsSelectorFactory();
  }
  return createSelector(
    selectProviderServiceTools,
    (_: BaseRootState, __: any, id: string) => id,
    (providerServiceTools, id) =>
      providerServiceTools.find(
        (providerServiceTool) => providerServiceTool.id === id
      ) ?? ({} as ProviderServiceTool)
  );
};

type SelectProviderServiceToolIdsSelector = OutputParametricSelector<
  BaseRootState,
  string,
  string[],
  (res1: ProviderServiceTool[]) => string[]
>;
export const selectProviderServiceToolIdsSelectorFactory = (
  selectProviderServiceTools?: SelectProviderServiceToolsSelector
): SelectProviderServiceToolIdsSelector => {
  if (!selectProviderServiceTools) {
    selectProviderServiceTools = selectProviderServiceToolsSelectorFactory();
  }
  return createSelector(selectProviderServiceTools, (providerServiceTools) =>
    providerServiceTools.map((providerServiceTool) => providerServiceTool.id)
  );
};

type SelectToolNameSelector = OutputParametricSelector<
  BaseRootState,
  string,
  string,
  (res1: Tool[], res2: string) => string
>;
export const selectToolNameSelectorFactory = (): SelectToolNameSelector =>
  createSelector(
    selectAllTools,
    (_: BaseRootState, id: string) => id,
    (tools, id) => {
      const tool = tools.find((tool) => tool.id === id);
      return tool ? tool.name : "";
    }
  );

export type SelectTargetSystemSelector = OutputParametricSelector<
  BaseRootState,
  string,
  TargetSystem,
  (res1: TargetSystem[], res2: string) => TargetSystem
>;
export const selectTargetSystemSelectorFactory =
  (): SelectTargetSystemSelector =>
    createSelector(
      selectTargetSystems,
      (_: BaseRootState, id: string) => id,
      (targetSystems, id) =>
        targetSystems.find((targetSystem) => targetSystem.id === id) ??
        ({} as TargetSystem)
    );

export const selectWitsmlTargetSystems = createSelector(
  selectTargetSystems,
  (targetSystems) =>
    targetSystems.filter(
      (targetSystem) => !NonWITSMLTargetSystems.includes(targetSystem.name)
    )
);

export const selectNonSiteComWitsmlTargetSystemNames = createSelector(
  selectWitsmlTargetSystems,
  (targetSystems) =>
    targetSystems
      .map((targetSystem) => targetSystem.name)
      .filter((targetSystem) => targetSystem !== TargetSystemType.SiteCom)
);

export type SelectTargetSystemProjectsWithInstanceNameSelector =
  OutputParametricSelector<
    BaseRootState,
    string,
    TargetProject[],
    (res: TargetSystem) => TargetProject[]
  >;
export const selectTargetSystemProjectsWithInstanceNameSelectorFactory = (
  selectTargetSystem?: SelectTargetSystemSelector
): SelectTargetSystemProjectsWithInstanceNameSelector => {
  if (!selectTargetSystem) {
    selectTargetSystem = selectTargetSystemSelectorFactory();
  }
  return createSelector(selectTargetSystem, (targetSystem) => {
    const result = [] as TargetProject[];

    if (targetSystem.instances?.length > 0) {
      targetSystem.instances.forEach((instance) => {
        result.push(
          ...instance.projects.map((project) => ({
            ...project,
            name: `${project.name} (${instance.name})`
          }))
        );
      });
    }

    return result;
  });
};

function filterProviderServiceToolsByTargetWellboreIds(
  providerServiceTools: ProviderServiceTool[],
  {
    depthCurveIds,
    timeCurveIds
  }: { depthCurveIds: string[]; timeCurveIds: string[] }
) {
  const result = [];
  for (const providerServiceTool of providerServiceTools) {
    const tools = filterToolsByCurveIds(
      providerServiceTool,
      timeCurveIds,
      depthCurveIds
    );

    if (tools.length > 0) {
      result.push({
        ...providerServiceTool,
        tools
      });
    }
  }
  return result;
}

function filterToolByNameOrDescription(
  providerServiceTool: ProviderServiceTool,
  filter: string
): Tool[] {
  const result = [];
  for (const tool of providerServiceTool.tools) {
    const depthCurves = tool.depthCurves.filter(
      (curve) =>
        curve.name.toLowerCase().includes(filter) ||
        curve.description?.toLowerCase().includes(filter)
    );
    const timeCurves = tool.timeCurves.filter(
      (curve) =>
        curve.name.toLowerCase().includes(filter) ||
        curve.description?.toLowerCase().includes(filter)
    );

    if (timeCurves.length + depthCurves.length > 0) {
      result.push({
        ...tool,
        depthCurves,
        timeCurves
      });
    }
  }
  return result;
}

function filterToolsByCurveIds(
  providerServiceTool: ProviderServiceTool,
  timeCurveIds: string[],
  depthCurveIds: string[]
) {
  const result = [];
  for (const tool of providerServiceTool.tools) {
    const timeCurves = tool.timeCurves.filter((timeCurve) =>
      timeCurveIds.includes(timeCurve.id)
    );
    const depthCurves = tool.depthCurves.filter((depthCurve) =>
      depthCurveIds.includes(depthCurve.id)
    );

    if (timeCurves.length + depthCurves.length > 0) {
      result.push({
        ...tool,
        depthCurves,
        timeCurves
      });
    }
  }
  return result;
}

function findCurveIdsFilteredByTargetWellboreIds(
  targetWellboreIds: string[],
  logDepthCurves: LogDepthCurve[],
  logTimeCurves: LogTimeCurve[]
) {
  const depthCurveIds = logDepthCurves
    .filter((curve) => targetWellboreIds.includes(curve.targetWellboreId))
    .map((curve) => curve.depthCurveId);
  const timeCurveIds = logTimeCurves
    .filter((curve) => targetWellboreIds.includes(curve.targetWellboreId))
    .map((curve) => curve.timeCurveId);
  return { depthCurveIds, timeCurveIds };
}

function findTargetWellboreIdsByTargetSystemFilter(
  { id: filterId, name: filterName }: TargetSystemFilterValue,
  targetWellboreIds: Record<string, string[]>
) {
  if (filterId === TargetSystemOrderedValue.id) {
    return Object.values(targetWellboreIds).flatMap((value) => value);
  }

  return filterName in targetWellboreIds ? targetWellboreIds[filterName] : [];
}

export const curveIsDefault = (curve?: Curve): boolean =>
  !!(curve && curve.isDefault && curve.type === CurveType.Time);

export const curveIsRiserRequired = (
  curve?: Curve,
  diameter?: number
): boolean => !!(curve && diameter && curve.isRiserRequired && diameter >= 26);

const curveIsLogLocked = (curve: Curve, log?: Log) =>
  log &&
  (log.isDrilling
    ? log.depthStatus === DrillingStatus.Completed
    : curve.type === CurveType.Depth ||
      (curve.type === CurveType.Time &&
        log.timeStatus === TimeStatus.Completed));

const curveIsNotSupportedByTargetSystem = (
  curve: Curve,
  system: string
): boolean =>
  curve.isImageArrayCurve === true && system === TargetSystemType.OpenWorks;

const curveIsOrderedFor = (
  timeCurves: LogTimeCurve[],
  depthCurves: LogDepthCurve[],
  curveId: string,
  ...targetWellboreIds: string[]
) => {
  const isOrdered = depthCurves.some(
    (curve) =>
      curve.depthCurveId === curveId &&
      targetWellboreIds.includes(curve.targetWellboreId)
  );
  if (!isOrdered) {
    return timeCurves.some(
      (curve) =>
        curve.timeCurveId === curveId &&
        targetWellboreIds.includes(curve.targetWellboreId)
    );
  }
  return isOrdered;
};
