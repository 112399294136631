import contactService from "../../services/contactService";
import fieldService from "../../services/fieldService";
import logService from "../../services/logService";
import msGraphService from "../../services/msGraphService";
import orderService from "../../services/orderService";
import roleService from "../../services/roleService";
import runService from "../../services/runService";
import siteComGroupService from "../../services/siteComGroupService";
import wellboreService from "../../services/wellboreService";
import {
  addContact,
  addLog,
  addOrUpdateTargetWellbore,
  removeContact,
  removeLog,
  setOrder,
  setProviderServiceTools,
  setRoles,
  setSiteComGroups,
  setTargetSystems,
  setTargetWellbores,
  setUsers,
  updateContact,
  updateLog,
  updateOrderSiteComGroup
} from "../currentOrder/reducer";
import { setFields, setLogs, setOrdersByField } from "../overview/reducer";
import { RequestType, TRequestType } from "./types";

// Need this to set the objects index type while inferring the property return types
const createRequestManager = <
  T extends {
    serviceHandlers: { [K in TRequestType]: any };
    actionGenerators: { [K in TRequestType]: any };
  }
>(
  manager: T
): {
  serviceHandlers: { [K in TRequestType]: T["serviceHandlers"][K] };
  actionGenerators: { [K in TRequestType]: T["actionGenerators"][K] };
} => manager;

// Request manager responsible for mapping a RequestType to it's corresponding service handler and action generator.
// All request types must have a service method and action generator configured for the requestFlow saga to properly
// fetch the related resource on a START_REQUEST action.
const RequestManager = createRequestManager({
  serviceHandlers: {
    [RequestType.GetLogs]: logService.getLogs,
    [RequestType.GetOrdersGroupedByField]: orderService.getOrdersGroupedByField,
    [RequestType.GetOrder]: orderService.getOrder,
    [RequestType.CreateOrder]: orderService.createOrder,
    [RequestType.UpdateCurveOrders]: orderService.updateCurveOrders,
    [RequestType.UpdateAndSubmitCurveOrders]:
      orderService.updateAndSubmitCurveOrders,
    [RequestType.GetFields]: fieldService.getFields,
    [RequestType.GetRoles]: roleService.getRoles,
    [RequestType.GetTargetSystems]: wellboreService.getTargetSystems,
    [RequestType.GetTargetWellbores]: wellboreService.getTargetWellbores,
    [RequestType.GetProviderServiceTools]:
      wellboreService.getProviderServiceTools,
    [RequestType.GetAzureADUsers]:
      msGraphService.getUsersWithDisplayNameStartingWith,
    [RequestType.CreateContact]: contactService.createContact,
    [RequestType.UpdateContact]: contactService.updateContact,
    [RequestType.DeleteContact]: contactService.deleteContact,
    [RequestType.CreateOrUpdateTargetWellbore]:
      wellboreService.createOrUpdateTargetWellbore,
    [RequestType.CreateRun]: runService.createRun,
    [RequestType.DeleteRun]: runService.deleteRun,
    [RequestType.GetSiteComGroupsForOrder]:
      siteComGroupService.getSiteComGroupsForOrder,
    [RequestType.UpdateSiteComGroupForOrder]:
      siteComGroupService.updateSiteComGroupForOrder
  },
  actionGenerators: {
    [RequestType.GetLogs]: setLogs,
    [RequestType.GetOrder]: setOrder,
    [RequestType.GetOrdersGroupedByField]: setOrdersByField,
    [RequestType.CreateOrder]: setOrder,
    [RequestType.UpdateCurveOrders]: updateLog,
    [RequestType.UpdateAndSubmitCurveOrders]: updateLog,
    [RequestType.GetFields]: setFields,
    [RequestType.GetRoles]: setRoles,
    [RequestType.GetTargetSystems]: setTargetSystems,
    [RequestType.GetTargetWellbores]: setTargetWellbores,
    [RequestType.GetProviderServiceTools]: setProviderServiceTools,
    [RequestType.GetAzureADUsers]: setUsers,
    [RequestType.CreateContact]: addContact,
    [RequestType.UpdateContact]: updateContact,
    [RequestType.DeleteContact]: removeContact,
    [RequestType.CreateOrUpdateTargetWellbore]: addOrUpdateTargetWellbore,
    [RequestType.CreateRun]: addLog,
    [RequestType.DeleteRun]: removeLog,
    [RequestType.GetSiteComGroupsForOrder]: setSiteComGroups,
    [RequestType.UpdateSiteComGroupForOrder]: updateOrderSiteComGroup
  }
});

export default RequestManager;
