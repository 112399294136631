import { combineReducers } from "redux";

import currentOrderReducer from "./currentOrder/reducer";
import overviewReducer from "./overview/reducer";
import { reducer as requestReducer } from "./request";
import settingsReducer from "./settings/reducer";
import snacksReducer from "./snacks/reducer";

const rootReducer = combineReducers({
  currentOrder: currentOrderReducer,
  overview: overviewReducer,
  request: requestReducer,
  settings: settingsReducer,
  snacks: snacksReducer
});

export default rootReducer;
