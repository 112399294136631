import { Snackbar } from "@equinor/eds-core-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { removeSnack } from "../../state/snacks/reducer";
import { selectSingleSnack } from "../../state/snacks/selectors";

function SnackProvider(): React.ReactElement {
  const dispatch = useDispatch();

  const snack = useSelector(selectSingleSnack);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (snack) {
      setOpen(true);
    }
  }, [snack]);

  return (
    <>
      {snack && (
        <StyledSnackbar
          open={open}
          onClose={() => {
            setOpen(false);
            dispatch(removeSnack(snack));
          }}
          autoHideDuration="5000"
        >
          {snack.message}
        </StyledSnackbar>
      )}
    </>
  );
}

const StyledSnackbar = styled(Snackbar)`
  z-index: 550;
`;

export default SnackProvider;
