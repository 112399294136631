import { createAction } from "@reduxjs/toolkit";

import ApiLog from "../../models/api/apiLog";
import { CurveType } from "../../models/curve";
import Log from "../../models/log";
import { CLEAR_CURVES_FOR_LOG, COPY_CURVES_FOR_LOG } from "./types";

const addCurve = createAction(
  "currentOrder/addCurve",
  (
    logId: string,
    targetWellboreId: string,
    curveId: string,
    curveType: CurveType,
    serviceId?: string
  ) => ({
    payload: { serviceId, logId, targetWellboreId, curveId, curveType }
  })
);

const removeCurve = createAction(
  "currentOrder/removeCurve",
  (
    id: string,
    logId: string,
    targetWellboreId: string,
    curveId: string,
    curveType: CurveType
  ) => ({ payload: { id, logId, targetWellboreId, curveId, curveType } })
);

const updateLog = createAction<ApiLog>("currentOrder/updateLog");

const removeLog = createAction<string>("currentOrder/removeLog");

const clearCurvesForLog = createAction(CLEAR_CURVES_FOR_LOG, (log: Log) => ({
  payload: { id: log.id, name: log.name }
}));

const copyCurvesForLog = createAction(
  COPY_CURVES_FOR_LOG,
  (source: Log, ...destination: string[]) => ({
    payload: {
      sourceId: source.id,
      sourceName: source.name,
      targetIds: destination
    }
  })
);

export {
  addCurve,
  removeCurve,
  updateLog,
  removeLog,
  clearCurvesForLog,
  copyCurvesForLog
};
