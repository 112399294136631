import { Accordion as EdsAccordion } from "@equinor/eds-core-react";
import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useParamSelector } from "../../../../hooks";
import { selectIsAccordionExpandedSelectorFactory } from "../../../../state/currentOrder/accordionState/selectors";
import { setToolAccordionState } from "../../../../state/currentOrder/reducer";
import { selectToolNameSelectorFactory } from "../../../../state/currentOrder/selectors";
import { selectTheme } from "../../../../state/settings/selectors";
import { Theme } from "../../../../state/settings/types";
import { colors } from "../../../../styles/Colors";
import CurveTable from "./CurveTable";
import ToolSelectedCurvesLabel from "./ToolSelectedCurvesLabel";

const {
  Header: EdsAccordionHeader,
  Item: EdsAccordionItem,
  Panel: EdsAccordionPanel
} = EdsAccordion;

type Props = {
  logId: string;
  serviceId: string;
  toolId: string;
};

function ToolAccordionItem({
  logId,
  serviceId,
  toolId,
  ...rest
}: Props): React.ReactElement {
  const dispatch = useDispatch();

  const selectToolName = useMemo(selectToolNameSelectorFactory, []);
  const selectExpanded = useMemo(selectIsAccordionExpandedSelectorFactory, []);

  const toolName = useParamSelector(selectToolName, toolId);
  const expanded = useParamSelector(selectExpanded, logId, serviceId, toolId);
  const theme = useSelector(selectTheme);

  return (
    <AccordionItem {...rest} isExpanded={expanded} theme={theme}>
      <EdsAccordionHeader
        onToggle={() =>
          dispatch(setToolAccordionState(logId, serviceId, toolId, !expanded))
        }
      >
        <HeaderItem>{toolName}</HeaderItem>
        <HeaderSpace />
        <HeaderItemLabel>
          <ToolSelectedCurvesLabel logId={logId} toolId={toolId} />
        </HeaderItemLabel>
      </EdsAccordionHeader>
      <AccordionPanel cTheme={theme}>
        {expanded && (
          <CurveTable logId={logId} serviceId={serviceId} toolId={toolId} />
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

const AccordionItem = styled(EdsAccordionItem)<{
  theme: Theme;
  isExpanded: boolean;
}>`
  & > h2 {
    background: ${colors.ui.backgroundDefault};
    display: flex;
    position: sticky;
    z-index: 4;
    ${({ isExpanded }) =>
      isExpanded
        ? `&&& { border-bottom: 1px solid ${colors.ui.backgroundMedium}; }`
        : ""}
    ${({ theme }) => (theme === Theme.Compact ? `top: 4rem;` : `top: 6rem;`)}
    & > button {
      ${({ theme }) => (theme === Theme.Compact ? `height: 2rem;` : ``)}
    }
  }
`;

const AccordionPanel = styled(EdsAccordionPanel)<{ cTheme: Theme }>`
  background: ${colors.ui.backgroundDefault};
  border-right: unset;
  border-top: unset;
  min-height: 2rem;
  padding: ${({ cTheme }) => (cTheme === Theme.Compact ? "0.5rem" : "1rem")};
`;

const HeaderItem = styled.div`
  flex-shrink: 0;
  overflow: hidden;
  padding-right: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 15rem;
`;

const HeaderItemLabel = styled(HeaderItem)`
  flex-shrink: 1;
  margin-left: 1rem;
  width: 13rem;
  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const HeaderSpace = styled.div`
  flex-shrink: 10;
  min-width: 1rem;
  overflow: hidden;
  padding-right: 1rem;
  width: 43rem;
`;

export default memo(ToolAccordionItem);
