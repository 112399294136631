import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Theme } from "./types";

interface SettingsState {
  theme: Theme;
}

const initialState = {
  theme: Theme.Compact
} as SettingsState;

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<Theme>) {
      state.theme = action.payload;
    }
  }
});

export const { setTheme } = settingsSlice.actions;
export default settingsSlice.reducer;
