import { createApiError } from "../models/api/apiError";
import ApiOverviewLog from "../models/api/apiOverviewLog";
import DrillingStatus from "../models/drillingStatus";
import OrderStatus from "../models/orderStatus";
import { SetLogPayload } from "../state/overview/types";
import apiClient from "./apiClient";

async function getLogs(
  abortSignal: AbortSignal,
  orderStatus: OrderStatus,
  field?: string
): Promise<SetLogPayload> {
  const searchParams = new URLSearchParams({
    status: orderStatus.toLowerCase()
  });

  if (field) {
    searchParams.append("field", field.toUpperCase());
  }

  const response = await apiClient.get(
    `/logs?${searchParams.toString()}`,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return {
      logs: json.map((log: ApiOverviewLog) => {
        return {
          ...log,
          depthStatus: !log.orderId
            ? DrillingStatus.NoOrder
            : !log.hasDepthCurves
            ? DrillingStatus.NoOrderedCurves
            : log.depthStatus
        };
      }),
      logType: orderStatus
    };
  } else {
    throw createApiError("Failed to fetch logs", json);
  }
}

export default { getLogs };
