import {
  Button,
  CircularProgress,
  Dialog as EdsDialog,
  Icon,
  Typography
} from "@equinor/eds-core-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useRequest } from "../../../../hooks/useRequest";
import Log from "../../../../models/log";
import { selectOrderId } from "../../../../state/currentOrder/selectors";
import { RequestType } from "../../../../state/request";
import { addSnack } from "../../../../state/snacks/reducer";
import { colors } from "../../../../styles/Colors";
import Tooltip from "../../../Tooltip";

const { Title, Header } = EdsDialog;

type Props = {
  log: Log;
  onClose: () => void;
};

const LogRemoveRunConfirmationDialog = ({
  log,
  onClose
}: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const orderId = useSelector(selectOrderId);
  const errorMessage = `The run ${log.name} could not be deleted, please try again`;

  const {
    invokerFunction: onDelete,
    isError,
    isLoading,
    isSuccess
  } = useRequest({
    requestType: RequestType.DeleteRun,
    successCallback: () => {
      dispatch(addSnack(`The run ${log.name} was successfully deleted`));
      onClose();
    },
    errorCallback: () => dispatch(addSnack(errorMessage)),
    payloadGenerator: {
      func: () => [orderId, log.id],
      deps: [orderId, log.id]
    }
  });

  return (
    <Dialog open={true}>
      <Header>
        <Title>Delete Run?</Title>
      </Header>
      <Row>
        <Typography variant="body_long">
          The run{" "}
          <Typography as="span" bold>
            {log.name}
          </Typography>{" "}
          will be deleted.
        </Typography>
      </Row>
      <ActionButtons>
        <Button
          color="danger"
          variant="outlined"
          onClick={onClose}
          disabled={isLoading || isSuccess}
        >
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={onDelete}
          disabled={isLoading || isSuccess}
        >
          Delete
        </Button>
        {isLoading && <Spinner />}
        {isError && (
          <Tooltip placement="top-start" title={errorMessage}>
            <Icon
              name="errorOutlined"
              color={colors.interactive.dangerResting}
            />
          </Tooltip>
        )}
      </ActionButtons>
    </Dialog>
  );
};

const Dialog = styled(EdsDialog)`
  width: 31.5rem;
`;

const Row = styled.div`
  margin: 0 1rem 1rem;
`;

const ActionButtons = styled(Row)`
  align-items: center;
  display: flex;

  & > button,
  & > button:disabled {
    margin-right: 1rem;
  }
`;

const Spinner = styled(CircularProgress)`
  height: auto;
  width: 1.25rem;
`;

export default LogRemoveRunConfirmationDialog;
