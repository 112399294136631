import { Button, Typography } from "@equinor/eds-core-react";
import React, { memo } from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useRequestStatus } from "../../../../hooks";
import { selectIsOrderCompleted } from "../../../../state/currentOrder/selectors";
import { RequestType } from "../../../../state/request";
import { colors } from "../../../../styles/Colors";
import Icon from "../../../../styles/Icons";
import Tooltip from "../../../Tooltip";
import ContactDialog from "./ContactDialog";
import ContactTable from "./ContactTable";

function ContactPanel(): React.ReactElement {
  const { isReady, isError } = useRequestStatus(
    RequestType.GetOrder,
    RequestType.GetRoles
  );

  const isOrderCompleted = useSelector(selectIsOrderCompleted);

  const [showContactDialog, hideContactDialog] = useModal(
    () => <ContactDialog onClose={hideContactDialog} />,
    []
  );

  return (
    <Container>
      <Heading>
        <Typography variant="h2">Contacts</Typography>
        <ButtonContainer>
          <Button
            onClick={showContactDialog}
            disabled={!isReady || isError || isOrderCompleted}
          >
            <Icon title="add" name="add" size={16} />
            Add contact
          </Button>
          {isOrderCompleted && (
            <Tooltip title="Changes cannot be made to the order after completion">
              <Icon
                name="infoCircle"
                color={colors.interactive.primaryResting}
                size={20}
              />
            </Tooltip>
          )}
        </ButtonContainer>
      </Heading>
      <ContactTable />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  & > svg {
    margin-left: 0.5rem;
  }
`;

const Heading = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  & > h2 {
    line-height: inherit;
  }
`;

export default memo(ContactPanel);
