import { Tooltip as EdsTooltip } from "@equinor/eds-core-react";
import React, { forwardRef, HTMLAttributes } from "react";
import styled from "styled-components";

import HtmlTooltip from "./HtmlTooltip";
import { Placement, Variant } from "./types";

export type TooltipProps = {
  /** Tooltip placement relative to anchor */
  placement?: Placement;
  /** Tooltip title */
  title?: string;
  /** Tooltip html */
  html?: React.ReactNode;
  /** Tooltip variant. Only applicable for html */
  variant?: Variant;
  /** Tooltip anchor element */
  children: React.ReactNode;
  /** Delay in ms, default 100 */
  enterDelay?: number;
} & HTMLAttributes<HTMLDivElement>;

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(function Tooltip(
  { title, html, children, ...rest },
  ref
) {
  if (html) {
    return (
      <HtmlTooltip html={html} ref={ref} {...rest}>
        {children}
      </HtmlTooltip>
    );
  }

  return (
    <StyledEdsTooltip title={title ?? ""} ref={ref} {...rest}>
      {children}
    </StyledEdsTooltip>
  );
});

const StyledEdsTooltip = styled(EdsTooltip)`
  z-index: 1301;
`;

export default Tooltip;
