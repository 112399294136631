import {
  CircularProgress,
  EdsProvider,
  Table as EdsTable
} from "@equinor/eds-core-react";
import React, { memo, useCallback, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useContextMenu, useRequestStatus } from "../../../hooks";
import OverviewLog from "../../../models/overviewLog";
import { selectLogIds } from "../../../state/overview/selectors";
import { RequestType } from "../../../state/request";
import { selectTheme } from "../../../state/settings/selectors";
import GenericErrorPanel from "../../GenericErrorPanel/GenericErrorPanel";
import CreateOrderDialog from "./CreateOrderDialog";
import LogContextMenu from "./LogContextMenu";
import LogTableHeader from "./LogTableHeader";
import LogTableRow from "./LogTableRow";

const { Body } = EdsTable;

function LogTable(): React.ReactElement {
  const logIds = useSelector(selectLogIds);
  const { isReady, isError } = useRequestStatus(
    RequestType.GetFields,
    RequestType.GetLogs
  );

  const anchor = useRef(null as HTMLElement | null);
  const [selectedLog, setSelectedLog] = useState({} as OverviewLog);

  const [showCreateOrderDialog, hideCreateOrderDialog] = useModal(
    () => (
      <CreateOrderDialog log={selectedLog} onClose={hideCreateOrderDialog} />
    ),
    [selectedLog]
  );

  const [ContextMenu, showLogContextMenu, hideLogContextMenu] = useContextMenu(
    () => (
      <LogContextMenu
        log={selectedLog}
        anchor={anchor.current}
        onClose={hideLogContextMenu}
        onCreate={showCreateOrderDialog}
      />
    ),
    [selectedLog, anchor.current, showCreateOrderDialog]
  );

  const toggleLogContextMenu = useCallback(
    (element: HTMLElement | null, log: OverviewLog) => {
      if (anchor.current) {
        hideLogContextMenu();
        anchor.current = null;
      }
      if (element) {
        anchor.current = element;
        setSelectedLog(log);
        showLogContextMenu();
      }
    },
    [hideLogContextMenu, showLogContextMenu]
  );

  const theme = useSelector(selectTheme);

  if (isError) {
    return <GenericErrorPanel />;
  }

  if (!isReady) {
    return <Spinner />;
  }

  return (
    <Container>
      <EdsProvider density={theme}>
        <Table>
          <LogTableHeader />
          <Body>
            {logIds.map((id) => (
              <LogTableRow
                key={id}
                logId={id}
                callback={toggleLogContextMenu}
              />
            ))}
          </Body>
        </Table>
        {logIds.length == 0 && <Placeholder />}
        <ContextMenu />
      </EdsProvider>
    </Container>
  );
}

const Container = styled.div`
  flex: 1 1 100%;
  overflow: auto;
`;

const Table = styled(EdsTable)`
  width: 100%;
`;

const Placeholder = styled.div`
  :empty::after {
    content: "No sections matches filter";
    display: block;
    font-size: 2rem;
    margin: 4.75rem auto;
    text-align: center;
  }
`;

const Spinner = styled(CircularProgress)`
  margin-left: 50%;
  margin-top: 5%;
`;

export default memo(LogTable);
