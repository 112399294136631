import { Paper, TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useDebounce } from "../../../../hooks";
import AADUser from "../../../../models/aadUser";
import { selectAzureAdUsers } from "../../../../state/currentOrder/azureAd/selectors";
import { clearAzureAdState } from "../../../../state/currentOrder/reducer";
import {
  isRequestLoading,
  RequestType,
  resetRequest,
  startRequest
} from "../../../../state/request";
import { colors } from "../../../../styles/Colors";

type Props = {
  callback: (user: string | AADUser | null) => void;
};

function ContactSearchBar({ callback }: Props): React.ReactElement {
  const dispatch = useDispatch();

  const azureAdUsers = useSelector(selectAzureAdUsers);
  const isLoading = useSelector(isRequestLoading(RequestType.GetAzureADUsers));
  const minimumQueryLength = 3;

  const debouncedDispatch = useDebounce(
    (queryString: string) =>
      dispatch(startRequest(RequestType.GetAzureADUsers)(queryString)),
    750
  );

  useEffect(() => {
    return () => {
      dispatch(resetRequest(RequestType.GetAzureADUsers));
      dispatch(clearAzureAdState());
    };
  }, [dispatch]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const queryString = e.target.value;

    if (queryString.length >= minimumQueryLength) {
      debouncedDispatch(queryString);
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={azureAdUsers}
      getOptionLabel={(option) =>
        option
          ? `${option.displayName}${option.mail ? ` (${option.mail})` : ""}`
          : ""
      }
      loading={isLoading}
      onChange={(_: any, value) => callback(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Search for a person"
          id="contacts"
          value={""}
          onChange={onChangeHandler}
        />
      )}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      PaperComponent={styledPaperComponent}
    />
  );
}

const styledPaperComponent = styled(Paper)`
  &&& {
    background: ${colors.ui.backgroundLight};
    color: ${colors.text.staticIconsDefault};
    font-family: Equinor, Arial, serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

const Autocomplete = styled(MuiAutocomplete)`
  & > div:first-child {
    & > label:first-child {
      font-family: Equinor, Arial, serif;
      padding-left: 0.5rem;

      &.MuiInputLabel-shrink {
        color: ${colors.text.staticIconsTertiary};
        font-weight: 500;
      }

      &:not(.MuiInputLabel-shrink) {
        padding-top: 0.125rem;
        pointer-events: none;
        z-index: 1;
      }
    }

    & > div:nth-child(2) {
      background: ${colors.ui.backgroundLight};
      height: 2.25rem;
      outline: 1px solid transparent;

      &::before,
      &::after {
        border-bottom: unset;
        box-shadow: inset 0 -1px 0 0 ${colors.text.staticIconsTertiary};
      }

      &:hover {
        &::before,
        &::after {
          border-bottom: unset;
        }
      }

      &:active,
      &:focus-within {
        background-color: ${colors.ui.backgroundLight};
        outline: 2px solid ${colors.interactive.primaryResting};
        outline-offset: 0;

        &::before,
        &::after {
          border-bottom: unset;
          box-shadow: unset;
        }
      }

      & > input:first-child {
        font-family: Equinor, Arial, serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0.375rem 0 0.375rem 0.5rem;
      }
    }
  }
` as typeof MuiAutocomplete;

export default ContactSearchBar;
