import React, { memo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Icon from "../../../src/styles/Icons";
import DrillingStatus, {
  displayFriendlyString
} from "../../models/drillingStatus";
import { selectTheme } from "../../state/settings/selectors";
import { Theme } from "../../state/settings/types";
import { colors } from "../../styles/Colors";

type Props = {
  status: DrillingStatus;
};

function StatusLabel({ status }: Props): React.ReactElement {
  const theme = useSelector(selectTheme);

  let background;
  const color = colors.text.staticIconsPrimaryWhite;

  switch (status) {
    case DrillingStatus.NoOrder:
    case DrillingStatus.NotApplicable:
      background = colors.interactive.dangerResting;
      break;
    case DrillingStatus.NoOrderedCurves:
      background = colors.interactive.warningText;
      break;
    case DrillingStatus.Draft:
      background = colors.text.staticIconsTertiary;
      break;
    case DrillingStatus.Ready:
    case DrillingStatus.Verified:
    case DrillingStatus.ReadyToEstablish:
    case DrillingStatus.Submitted:
      background = colors.interactive.successText;
      break;
    case DrillingStatus.Active:
      background = colors.infographic.substituteBlueOcean;
      break;
    case DrillingStatus.Completed:
      background = colors.interactive.primaryResting;
      break;
  }

  return (
    <Layout color={color} background={background} theme={theme}>
      {status === DrillingStatus.Completed && <Icon name="check" size={20} />}
      {displayFriendlyString(status)}
    </Layout>
  );
}

const Layout = styled.div<{
  color: string;
  background: string;
  theme: Theme;
}>`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 6.5rem;
  display: grid;
  gap: 0.25rem;
  grid-auto-flow: column;
  line-height: 1.25rem;
  white-space: nowrap;
  width: fit-content;

  ${({ background }) => background && { background }}
  ${({ color }) => color && { color }}
  ${({ theme }) => theme === Theme.Compact && { padding: "0.125rem 0.5rem" }}
  ${({ theme }) =>
    theme === Theme.Comfortable && { padding: "0.375rem 0.75rem" }}
`;

export default memo(StatusLabel);
