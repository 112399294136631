import React, { memo, useMemo } from "react";

import { useParamSelector } from "../../../../hooks";
import { selectNumberOfOrderedCurvesForToolSelectorFactory } from "../../../../state/currentOrder/selectors";
import SelectedCurvesLabel from "./SelectedCurvesLabel";

type Props = {
  logId: string;
  toolId: string;
};

function ToolSelectedCurvesLabel({
  logId,
  toolId
}: Props): React.ReactElement | null {
  const selectNumberOfOrderedCurves = useMemo(
    selectNumberOfOrderedCurvesForToolSelectorFactory,
    []
  );

  const numberOfOrderedCurves = useParamSelector(
    selectNumberOfOrderedCurves,
    logId,
    toolId
  );

  if (!numberOfOrderedCurves) {
    return null;
  }

  return <SelectedCurvesLabel numberOfOrderedCurves={numberOfOrderedCurves} />;
}

export default memo(ToolSelectedCurvesLabel);
