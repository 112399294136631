import { PayloadAction } from "@reduxjs/toolkit";
import {
  call,
  put,
  race,
  StrictEffect,
  take,
  takeEvery
} from "redux-saga/effects";

import { START_REQUEST } from "./actions";
import {
  fetchRequest,
  requestFailed,
  requestSucceeded,
  resetRequest
} from "./reducer";
import RequestManager from "./requestManager";
import { RequestError, RequestPayload, StartRequestAction } from "./types";

async function errorWrapper<Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
): Promise<{ response: ReturnType<Fn> } | { error: RequestError }> {
  try {
    return { response: await fn(...args) };
  } catch (error: any) {
    if (error.isApiError) {
      return { error: { message: error.message, ...error.payload } };
    }
    return { error: { message: error.message } };
  }
}

export function* requestFlow(
  { payload: { type, id, args } }: StartRequestAction,
  abortController: AbortController
): Generator<StrictEffect, void, any> {
  yield put(fetchRequest(type, id));

  const handler = RequestManager.serviceHandlers[type];
  const { response, error } = yield call(
    errorWrapper,
    handler,
    ...[abortController.signal, ...args]
  );

  if (response) {
    const setResponse = RequestManager.actionGenerators[type];
    yield put(setResponse(response));
    yield put(requestSucceeded(type, id));
  } else {
    yield put(requestFailed(error, type, id));
  }
}

export function* requestCancellationFlow(
  { payload: { type, id } }: StartRequestAction,
  abortController: AbortController
): Generator<StrictEffect, void, StartRequestAction> {
  while (true) {
    const action = yield take(START_REQUEST);

    if (type === action.payload.type && id === action.payload.id) {
      abortController.abort();
      return;
    }
  }
}

export function* requestResetFlow(
  { payload: { type, id } }: StartRequestAction,
  abortController: AbortController
): Generator<StrictEffect, void, PayloadAction<RequestPayload>> {
  while (true) {
    const action = yield take(resetRequest.type);

    if (
      resetRequest.match(action) &&
      type === action.payload.request &&
      id === action.payload.id
    ) {
      abortController.abort();
      return;
    }
  }
}

export function* watchRequestFlow(): Generator<
  StrictEffect,
  void,
  StartRequestAction
> {
  yield takeEvery(START_REQUEST, function* (action: StartRequestAction) {
    const abortController = new AbortController();

    yield race([
      requestFlow(action, abortController),
      requestResetFlow(action, abortController),
      requestCancellationFlow(action, abortController)
    ]);
  });
}
