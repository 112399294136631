import React, {
  cloneElement,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  SVGProps,
  useEffect,
  useRef,
  useState
} from "react";
import * as ReactDom from "react-dom";
import { usePopper } from "react-popper";
import styled from "styled-components";

import { useCombinedRefs, useId, useIsMounted } from "../../hooks";
import { colors } from "../../styles/Colors";
import { Placement, Variant } from "./types";

type StyleProps = {
  $variant: Variant;
};

type ArrowProps = {
  ref?: React.MutableRefObject<null>;
} & StyleProps &
  SVGProps<SVGSVGElement>;

type HtmlTooltipProps = {
  placement?: Placement;
  variant?: Variant;
  html: React.ReactNode;
  enterDelay?: number;
} & HTMLAttributes<HTMLDivElement>;

const HtmlTooltip = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<HtmlTooltipProps>
>(function HtmlTooltip(
  {
    html,
    placement = "bottom",
    variant = "light",
    children,
    enterDelay = 100,
    id,
    ...rest
  },
  ref
) {
  const isMounted = useIsMounted();

  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const anchorRef = useRef<HTMLDivElement>();
  const openTimer = useRef<number>();
  const tooltipRef = useCombinedRefs<HTMLDivElement>(setPopperEl, ref);

  const [open, setOpen] = useState(false);

  const containerId = "eds-tooltip-container";
  const tooltipId = useId(id, "tooltip");

  useEffect(() => {
    if (document.getElementById(containerId) === null) {
      const tooltipContainerElement = document.createElement("div");
      tooltipContainerElement.id = containerId;
      document.body.appendChild(tooltipContainerElement);
    }
    return () => clearTimeout(openTimer.current);
  }, []);

  const openTooltip = () => {
    if (isMounted) {
      clearTimeout(openTimer.current);

      openTimer.current = setTimeout(() => {
        setOpen(true);
      }, enterDelay) as unknown as number;
    }
  };

  const closeTooltip = () => {
    clearTimeout(openTimer.current);
    setOpen(false);
  };

  const { styles, attributes } = usePopper(anchorRef.current, popperEl, {
    placement,
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowRef
        }
      },
      {
        name: "offset",
        options: {
          offset: [0, 14]
        }
      }
    ]
  });

  const props = {
    open,
    ...rest,
    ...attributes.popper
  };

  const updatedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    return cloneElement(child as ReactElement, {
      "ref": anchorRef,
      "aria-describedby": open ? tooltipId : null,
      "onMouseOver": openTooltip,
      "onMouseLeave": closeTooltip,
      "onPointerEnter": openTooltip,
      "onPointerLeave": closeTooltip,
      "onBlur": closeTooltip,
      "onFocus": openTooltip
    });
  });

  return (
    <>
      {isMounted &&
        open &&
        ReactDom.createPortal(
          <StyledTooltip
            id={tooltipId}
            role="tooltip"
            ref={tooltipRef}
            style={styles.popper}
            $variant={variant}
            {...props}
          >
            {html}
            <ArrowWrapper
              ref={setArrowRef}
              style={styles.arrow}
              className="arrow"
            >
              <TooltipArrow className="arrowSvg" $variant={variant}>
                <path d="M0.504838 4.86885C-0.168399 4.48524 -0.168399 3.51476 0.504838 3.13115L6 8.59227e-08L6 8L0.504838 4.86885Z" />
              </TooltipArrow>
            </ArrowWrapper>
          </StyledTooltip>,
          document.getElementById(containerId) ?? document.body
        )}
      {updatedChildren}
    </>
  );
});

const StyledTooltip = styled.div<StyleProps>`
  border-radius: 4px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 0.5rem;
  z-index: 1301;

  ${({ $variant }) =>
    $variant === "light" && {
      background: colors.ui.backgroundDefault,
      color: colors.text.staticIconsDefault
    }}
  ${({ $variant }) =>
    $variant === "dark" && {
      background: colors.ui.backgroundOverlay,
      color: colors.text.staticIconsPrimaryWhite
    }}

  .arrow {
    height: 8px;
    width: 6px;
    z-index: -1;
  }

  &[data-popper-placement^="top"] > .arrow {
    bottom: -6px;

    .arrowSvg {
      transform: rotate(-90deg);
    }
  }

  &[data-popper-placement^="bottom"] > .arrow {
    top: -6px;

    .arrowSvg {
      transform: rotate(90deg);
    }
  }

  &[data-popper-placement^="left"] > .arrow {
    right: -6px;

    .arrowSvg {
      transform: rotate(-180deg);
    }
  }

  &[data-popper-placement^="right"] > .arrow {
    left: -6px;
  }
`;

const ArrowWrapper = styled.div`
  &,
  &::before {
    height: 8px;
    position: absolute;
    width: 6px;
    z-index: -1;
  }

  &::before {
    content: "";
  }
`;

const TooltipArrow = styled.svg<ArrowProps>`
  height: 8px;
  position: absolute;
  width: 6px;

  ${({ $variant }) =>
    $variant === "light" && {
      fill: colors.ui.backgroundDefault
    }}
  ${({ $variant }) =>
    $variant === "dark" && {
      fill: colors.ui.backgroundOverlay
    }}
`;

export default HtmlTooltip;
