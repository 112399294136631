import { CurveType } from "./curve";
import TargetSystemDescription from "./targetSystemDescription";
import TargetSystemInstance from "./targetSystemInstance";

type TargetSystemCapability = CurveType;

export enum TargetSystemType {
  Geolog = "Geolog",
  OpenWorks = "OpenWorks",
  SiteCom = "SiteCom"
}
export const NonWITSMLTargetSystems: string[] = [
  TargetSystemType.Geolog,
  TargetSystemType.OpenWorks
];

type TargetSystem = {
  id: string;
  name: string;
  serviceProviderId: string;
  descriptions: TargetSystemDescription[];
  capabilities: TargetSystemCapability[];
  instances: TargetSystemInstance[];
};

export default TargetSystem;
