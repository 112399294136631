import { PayloadAction } from "@reduxjs/toolkit";

import { resetRequest as resetRequestActionGenerator } from "./reducer";
import {
  ConcurrentRequestType,
  isConcurrentRequestType,
  RequestPayload,
  SingleRequestType,
  StartActionGenerator,
  StartConcurrentRequestActionPayload,
  StartSingleRequestActionPayload,
  TRequestType
} from "./types";

export const START_REQUEST = "saga/startRequest";

export function resetRequest(
  request: SingleRequestType
): PayloadAction<RequestPayload>;

export function resetRequest(
  request: ConcurrentRequestType,
  id: string
): PayloadAction<RequestPayload>;

export function resetRequest(
  request: TRequestType,
  id?: string
): PayloadAction<RequestPayload> {
  return resetRequestActionGenerator(request, id);
}

export function startRequest<T extends SingleRequestType>(
  type: T
): StartActionGenerator<T, StartSingleRequestActionPayload>;

export function startRequest<T extends ConcurrentRequestType>(
  type: T,
  id: string
): StartActionGenerator<T, StartConcurrentRequestActionPayload>;

export function startRequest<T extends TRequestType>(
  type: T,
  id?: string
): StartActionGenerator<
  T,
  StartSingleRequestActionPayload | StartConcurrentRequestActionPayload
> {
  return (...args) => {
    const action = { type: START_REQUEST };

    if (isConcurrentRequestType(type)) {
      return {
        ...action,
        payload: {
          type,
          args,
          id: id as string
        }
      };
    }

    return {
      ...action,
      payload: {
        type,
        args
      }
    };
  };
}
