import { Menu, Typography } from "@equinor/eds-core-react";
import React from "react";
import { useHistory } from "react-router-dom";

import OverviewLog from "../../../models/overviewLog";
import { colors } from "../../../styles/Colors";
import Icon from "../../../styles/Icons";

const { Item: MenuItem } = Menu;

type Props = {
  log: OverviewLog;
  anchor?: EventTarget | null;
  onClose: () => void;
  onCreate: () => void;
};

function LogContextMenu({
  anchor,
  log,
  onClose,
  onCreate
}: Props): React.ReactElement {
  const history = useHistory();
  const isOpen = Boolean(anchor);

  const onClickOpen = () => history.push(`/orders/${log.orderId}#${log.id}`);

  const onClickCreate = () => onCreate();

  if (!log.orderId) {
    return (
      <Menu
        open={isOpen}
        anchorEl={anchor}
        placement="left-start"
        onClose={onClose}
      >
        <MenuItem onClick={onClickCreate}>
          <Icon
            name="add"
            title="add"
            size={20}
            color={colors.interactive.primaryResting}
          />
          <Typography group="navigation" variant="menu_title" color="primary">
            Create order
          </Typography>
        </MenuItem>
      </Menu>
    );
  }

  return (
    <Menu
      open={isOpen}
      anchorEl={anchor}
      placement="left-start"
      onClose={onClose}
    >
      <MenuItem onClick={onClickOpen}>
        <Icon
          name="openInBrowser"
          title="open"
          size={20}
          color={colors.interactive.primaryResting}
        />
        <Typography group="navigation" variant="menu_title" color="primary">
          Open
        </Typography>
      </MenuItem>
    </Menu>
  );
}

export default LogContextMenu;
