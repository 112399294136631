import { CurveType } from "../../models/curve";

export const CLEAR_CURVES_FOR_LOG = "saga/clearCurvesForLog";

export const COPY_CURVES_FOR_LOG = "saga/copyCurvesForLog";

export enum OrderPageTab {
  Configuration,
  CurvePanel
}

export const LogFilterValueAllSections = {
  id: "-1",
  name: "All Sections"
};

export const LogFilterValueDrillingSections = {
  id: "-2",
  name: "Drilling Sections"
};

export const TargetSystemAllValue = {
  id: "-1",
  name: "All Curves"
};

export const TargetSystemOrderedValue = {
  id: "-2",
  name: "Ordered"
};

export type LogFilterValue = {
  id: string;
  name: string;
};

export type TargetSystemFilterValue = {
  id: string;
  name: string;
};

export type LogCurvePayload = {
  logId: string;
  targetWellboreId: string;
  curveId: string;
  curveType: CurveType;
};

export type RemoveLogCurvePayload = LogCurvePayload & {
  id: string;
};

export type AddLogCurvePayload = LogCurvePayload & {
  serviceId?: string;
};

export type ClearCurvesForLogPayload = {
  id: string;
  name: string;
};

export type CopyCurvesForLogPayload = {
  sourceId: string;
  sourceName: string;
  targetIds: string[];
};
