import { PayloadAction } from "@reduxjs/toolkit";
import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery
} from "redux-saga/effects";

import Curve, { CurveType } from "../../models/curve";
import LogDepthCurve from "../../models/logDepthCurve";
import LogTimeCurve from "../../models/logTimeCurve";
import { NonWITSMLTargetSystems } from "../../models/targetSystem";
import { putBatched } from "../../utils/saga";
import { addSnack } from "../snacks/reducer";
import { addCurve, removeCurve } from "./reducer";
import {
  curveIsDefault,
  curveIsRiserRequired,
  selectAllCurvesNormalized,
  selectAllEligibleLogCopyTargetIdsSelectorFactory,
  selectClearableLogDepthCurvesSelectorFactory,
  selectClearableLogTimeCurvesSelectorFactory,
  selectLogCurveSelectorFactory,
  selectLogDepthCurvesSelectorFactory,
  selectLogSelectorFactory,
  selectLogTimeCurvesSelectorFactory,
  selectMainTargetWellboreIdForServiceSelectorFactory,
  selectTargetSystemNameForTargetWellboreSelectorFactory
} from "./selectors";
import {
  AddLogCurvePayload,
  CLEAR_CURVES_FOR_LOG,
  ClearCurvesForLogPayload,
  COPY_CURVES_FOR_LOG,
  CopyCurvesForLogPayload,
  LogCurvePayload
} from "./types";

const selectLog = selectLogSelectorFactory();
const selectEligibleTargetIds =
  selectAllEligibleLogCopyTargetIdsSelectorFactory();
const selectLogTimeCurves = selectLogTimeCurvesSelectorFactory();
const selectLogDepthCurves = selectLogDepthCurvesSelectorFactory();
const selectTargetSystemName =
  selectTargetSystemNameForTargetWellboreSelectorFactory();
const selectMainTargetWellboreIdForService =
  selectMainTargetWellboreIdForServiceSelectorFactory();
const selectLogCurve = selectLogCurveSelectorFactory();
const selectClearableLogTimeCurves =
  selectClearableLogTimeCurvesSelectorFactory();
const selectClearableLogDepthCurves =
  selectClearableLogDepthCurvesSelectorFactory();

function* copyCurves(
  sourceTimeCurves: LogTimeCurve[],
  sourceDepthCurves: LogDepthCurve[],
  targetId: string,
  availableCurves: Record<string, Curve>
): Generator<
  StrictEffect,
  { name: string; actions: PayloadAction<LogCurvePayload>[] },
  any
> {
  const log = yield select(selectLog, targetId);
  const targetTimeCurves = yield select(selectLogTimeCurves, targetId);
  const targetDepthCurves = yield select(selectLogDepthCurves, targetId);

  const actions = [...sourceTimeCurves, ...sourceDepthCurves]
    .filter((source) => {
      const curveId =
        "timeCurveId" in source ? source.timeCurveId : source.depthCurveId;
      const targetCurves =
        "timeCurveId" in source ? targetTimeCurves : targetDepthCurves;

      return (
        curveId in availableCurves &&
        !curveIsDefault(availableCurves[curveId]) &&
        !curveIsRiserRequired(availableCurves[curveId], log.diameter) &&
        !targetCurves.some(
          (target: LogTimeCurve | LogDepthCurve) =>
            target.targetWellboreId === source.targetWellboreId &&
            ("timeCurveId" in target
              ? target.timeCurveId === curveId
              : target.depthCurveId === curveId)
        )
      );
    })
    .map((source) => {
      const curveId =
        "timeCurveId" in source ? source.timeCurveId : source.depthCurveId;
      const curveType =
        "timeCurveId" in source ? CurveType.Time : CurveType.Depth;

      return addCurve(targetId, source.targetWellboreId, curveId, curveType);
    });

  return { name: log.name, actions };
}

function* processCopyCurvesForLog({
  payload: { sourceId, sourceName, targetIds }
}: PayloadAction<CopyCurvesForLogPayload>): Generator<StrictEffect, void, any> {
  if (targetIds.length === 0) {
    return;
  }

  const sourceTimeCurves = yield select(selectLogTimeCurves, sourceId);
  const sourceDepthCurves = yield select(selectLogDepthCurves, sourceId);
  const availableCurves = yield select(selectAllCurvesNormalized);
  const eligibleTargets = yield select(selectEligibleTargetIds, sourceId);
  const targets = yield all(
    targetIds
      .filter((targetId) => eligibleTargets.includes(targetId))
      .map((targetId) =>
        call(
          copyCurves,
          sourceTimeCurves,
          sourceDepthCurves,
          targetId,
          availableCurves
        )
      )
  );

  const actions = targets.flatMap((target: any) => target.actions);
  yield putBatched(actions);

  const names = targets.map((target: any) => target.name);
  const targetNames =
    names.length > 1
      ? `${names.slice(0, -1).join(",")} and ${names.slice(-1)}`
      : names[0];

  yield put(
    addSnack(
      `Ordered curves successfully copied from ${sourceName} to ${targetNames}`
    )
  );
}

export function* watchCopyCurvesForLog(): Generator<StrictEffect, void, any> {
  yield takeEvery(COPY_CURVES_FOR_LOG, processCopyCurvesForLog);
}

function* processClearCurvesForLog({
  payload: { id, name }
}: PayloadAction<ClearCurvesForLogPayload>): Generator<
  StrictEffect,
  void,
  (LogTimeCurve | LogDepthCurve)[]
> {
  const logTimeCurves = yield select(selectClearableLogTimeCurves, id);
  const logDepthCurves = yield select(selectClearableLogDepthCurves, id);

  const actions = [...logTimeCurves, ...logDepthCurves].map((curve) => {
    const curveId =
      "timeCurveId" in curve ? curve.timeCurveId : curve.depthCurveId;
    const curveType = "timeCurveId" in curve ? CurveType.Time : CurveType.Depth;

    return removeCurve(
      curve.id,
      id,
      curve.targetWellboreId,
      curveId,
      curveType
    );
  });

  yield putBatched(actions);
  yield put(addSnack(`The section ${name} was successfully cleared`));
}

export function* watchClearCurvesForLog(): Generator<StrictEffect, void, any> {
  yield takeEvery(CLEAR_CURVES_FOR_LOG, processClearCurvesForLog);
}

function* processNonWitsmlCurveOrders({
  payload: { serviceId, logId, targetWellboreId, curveId, curveType }
}: PayloadAction<AddLogCurvePayload>): Generator<StrictEffect, void, any> {
  if (curveType === CurveType.Time || !serviceId) {
    return;
  }

  const targetSystemName = yield select(
    selectTargetSystemName,
    logId,
    curveId,
    targetWellboreId
  );

  if (!NonWITSMLTargetSystems.includes(targetSystemName)) {
    return;
  }

  const targetId = yield select(
    selectMainTargetWellboreIdForService,
    serviceId
  );
  const logCurve = yield select(selectLogCurve, logId, curveId, targetId);

  if (!logCurve) {
    yield put(addCurve(logId, targetId, curveId, curveType));
  }
}

export function* watchAddCurve(): Generator<StrictEffect, void, any> {
  yield takeEvery(addCurve.type, processNonWitsmlCurveOrders);
}
