export enum CurveType {
  Depth = "Depth",
  Time = "Time"
}

type Curve = {
  id: string;
  name: string;
  description?: string;
  type: CurveType;
  isDefault?: boolean;
  isRiserRequired?: boolean;
  isImageArrayCurve?: boolean;
};

export default Curve;
