import { Typography } from "@equinor/eds-core-react";
import React from "react";

type Props = {
  numberOfOrderedCurves: number;
};

function SelectedCurvesLabel({
  numberOfOrderedCurves: numberOfOrderedCurves
}: Props): React.ReactElement {
  const labelText = `${numberOfOrderedCurves} curve${
    numberOfOrderedCurves !== 1 ? "s" : ""
  } selected`;

  return <Typography>{labelText}</Typography>;
}

export default SelectedCurvesLabel;
