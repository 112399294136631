type SortOptions = {
  ascending: boolean;
};

const defaultSortOptions = {
  ascending: true
};

const collator = new Intl.Collator("nb-NO", { ignorePunctuation: true });
const numericCollator = new Intl.Collator([], { numeric: true });

export function by<T, U extends string | number | Date>(
  selector: (object: T) => U,
  { ascending }: SortOptions = defaultSortOptions
): (a: T, b: T) => number {
  if (ascending) {
    return (a: T, b: T) => (selector(a) > selector(b) ? 1 : -1);
  }
  return (a: T, b: T) => (selector(a) < selector(b) ? 1 : -1);
}

export function byAndThenBy<T, U1, U2 extends string | number | Date>(
  selector1: (object: T) => U1,
  selector2: (object: T) => U2,
  { ascending }: SortOptions = defaultSortOptions
): (a: T, b: T) => number {
  if (ascending) {
    return (a, b) => {
      if (selector1(a) > selector1(b)) {
        return 1;
      }
      if (selector1(a) < selector1(b)) {
        return -1;
      }
      return selector2(a) > selector2(b) ? 1 : -1;
    };
  }
  return (a, b) => {
    if (selector1(a) < selector1(b)) {
      return 1;
    }
    if (selector1(a) > selector1(b)) {
      return -1;
    }
    return selector2(a) < selector2(b) ? 1 : -1;
  };
}

export function withNumericCollatorBy<T, U extends string>(
  selector: (object: T) => U,
  { ascending }: SortOptions = defaultSortOptions
): (a: T, b: T) => number {
  if (ascending) {
    return (a, b) => numericCollator.compare(selector(a), selector(b));
  }
  return (a, b) => numericCollator.compare(selector(b), selector(a));
}

export function withCollatorBy<T, U extends string>(
  selector: (object: T) => U,
  { ascending }: SortOptions = defaultSortOptions
): (a: T, b: T) => number {
  if (ascending) {
    return (a, b) => collator.compare(selector(a), selector(b));
  }
  return (a, b) => collator.compare(selector(b), selector(a));
}

export function withCollatorFirstByAndThenBy<T, U extends string>(
  selector1: (object: T) => U,
  selector2: (object: T) => U,
  { ascending }: SortOptions = defaultSortOptions
): (a: T, b: T) => number {
  if (ascending) {
    return (a, b) => {
      const result = collator.compare(selector1(a), selector1(b));
      if (result === 0) {
        return collator.compare(selector2(a), selector2(b));
      }
      return result;
    };
  }
  return (a, b) => {
    const result = collator.compare(selector1(b), selector1(a));
    if (result === 0) {
      return collator.compare(selector2(b), selector2(a));
    }
    return result;
  };
}
