import { Typography } from "@equinor/eds-core-react";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  selectEarliestStartTime,
  selectFieldName,
  selectWellboreName
} from "../../../../state/currentOrder/selectors";
import { dateFormatter } from "../../../../utils/dateFormatter";
import { TextField } from "../../../TextField";

function PropertiesPanel(): React.ReactElement {
  const wellboreName = useSelector(selectWellboreName);
  const fieldName = useSelector(selectFieldName);
  const startTime = useSelector(selectEarliestStartTime);

  return (
    <Layout>
      <Typography variant="h2">Properties</Typography>
      <Row>
        <TextField
          id="wellbore"
          value={wellboreName}
          label="Wellbore"
          readOnly
        />
        <TextField
          id="starttime"
          value={dateFormatter(startTime, { dateOnly: true })}
          label="Start time"
          readOnly
        />
      </Row>
      <Row>
        <TextField id="field" value={fieldName} label="Field" readOnly />
      </Row>
    </Layout>
  );
}

const Layout = styled.div`
  margin: 3rem 0 0 2rem;

  & > h2 {
    margin: 1.5rem 0;
  }

  & > div:last-child {
    margin-top: 1rem;
  }
`;

const Row = styled.div`
  display: flex;

  & > div {
    width: 20rem;
  }
`;

export default memo(PropertiesPanel);
