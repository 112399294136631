import { Icon, Table } from "@equinor/eds-core-react";
import React, { memo, useMemo, useRef } from "react";
import styled from "styled-components";

import { useParamSelector } from "../../../../hooks";
import Contact from "../../../../models/contact";
import { selectContactSelectorFactory } from "../../../../state/currentOrder/selectors";
import { colors } from "../../../../styles/Colors";

const { Cell, Row: EdsRow } = Table;

type Props = {
  id: string;
  callback: (e: HTMLElement | null, contact: Contact) => void;
};

function ContactTableRow({ id, callback }: Props): React.ReactElement {
  const selector = useMemo(selectContactSelectorFactory, []);

  const contact = useParamSelector(selector, id);

  const ref = useRef<HTMLElement>(null);

  return (
    <Row>
      <Cell>{contact.name}</Cell>
      <Cell>{contact.role}</Cell>
      <Cell>
        {contact.isMainContact && (
          <Icon
            name="check"
            size={24}
            color={colors.interactive.primaryResting}
          />
        )}
      </Cell>
      <Cell>{contact.phone}</Cell>
      <Cell>{contact.email}</Cell>
      <Cell>{contact.availability}</Cell>
      <Cell>
        <IconButton>
          <Icon
            ref={ref}
            onClick={() => callback(ref.current, contact)}
            name="moreVertical"
            title="Open menu"
            size={24}
            color={colors.interactive.primaryResting}
          />
        </IconButton>
      </Cell>
    </Row>
  );
}

const Row = styled(EdsRow)`
  & > td:not(:last-of-type) {
    padding-right: 0;
    white-space: nowrap;
  }
  & > td:last-of-type {
    padding-right: 0.5rem;
  }
`;

const IconButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

export default memo(ContactTableRow);
