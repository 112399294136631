import { useEffect, useState } from "react";

export const useId = (id?: string, type?: string): string => {
  const [defaultId, setDefaultId] = useState(0);

  useEffect(() => {
    setDefaultId(Math.round(Math.random() * Number.MAX_SAFE_INTEGER));
  }, []);

  return id ?? `row-${type ?? ""}${defaultId}`;
};
