import { createApiError } from "../models/api/apiError";
import ApiLog from "../models/api/apiLog";
import apiClient from "./apiClient";

async function createRun(
  abortSignal: AbortSignal,
  orderId: string,
  sourceLogId: string
): Promise<ApiLog> {
  const response = await apiClient.post(
    `/orders/${orderId}/logs/${sourceLogId}`,
    "",
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to create run", json);
  }
}

async function deleteRun(
  abortSignal: AbortSignal,
  orderId: string,
  logId: string
): Promise<string> {
  const response = await apiClient.delete(
    `/orders/${orderId}/logs/${logId}`,
    abortSignal
  );

  if (response.ok) {
    return logId;
  } else {
    const json = await response.json();
    throw createApiError("Failed to delete run", json);
  }
}

export default {
  createRun,
  deleteRun
};
