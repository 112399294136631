import { cleanAndSplit } from "../utils/stringHelpers";

const envConfigTemplateStart = "${";

type Value = {
  attributeName: string;
  processEnvValue?: string;
  isArray?: true;
};

const createVariables = <T extends { [K: string]: Value }>(
  variables: T
): {
  [K in keyof T]: T[K];
} => variables;

const variables = createVariables({
  CLIENT_ID: {
    attributeName: "data-client-id",
    processEnvValue: process.env.REACT_APP_CLIENT_ID
  },
  SCOPE_PARAMETER_GRAPH_API: {
    attributeName: "data-scope-parameter-graph-api",
    processEnvValue: process.env.REACT_APP_SCOPE_PARAMETER_GRAPH_API,
    isArray: true
  },
  SCOPE_PARAMETER_RIPS_API: {
    attributeName: "data-scope-parameter-rips-api",
    processEnvValue: process.env.REACT_APP_SCOPE_PARAMETER_RIPS_API,
    isArray: true
  },
  TENANT_ID: {
    attributeName: "data-tenant-id",
    processEnvValue: process.env.REACT_APP_TENANT_ID
  },
  URL_GRAPH_API: {
    attributeName: "data-url-graph-api",
    processEnvValue: process.env.REACT_APP_URL_GRAPH_API
  },
  URL_RIPS_API: {
    attributeName: "data-url-rips-api",
    processEnvValue: process.env.REACT_APP_URL_RIPS_API
  },
  URL_RIPS_ORDER_WEB: {
    attributeName: "data-url-rips-order-web",
    processEnvValue: process.env.REACT_APP_URL_RIPS_ORDER_WEB
  }
});

type Key = keyof typeof variables;
type EnvironmentVariables = {
  [K in Key]: (typeof variables)[K] extends { isArray: true }
    ? string[]
    : string;
};

const getVariable = (key: Key) => {
  const { attributeName, processEnvValue, isArray }: Value = variables[key];

  const attributeValue = document.body.getAttribute(attributeName);
  const value =
    attributeValue && !attributeValue.startsWith(envConfigTemplateStart)
      ? attributeValue
      : processEnvValue ?? "";

  return isArray ? cleanAndSplit(value) : value;
};

export default Object.keys(variables).reduce(
  (result, value) => ({
    ...result,
    [value]: getVariable(value as Key)
  }),
  {} as EnvironmentVariables
);
