import React from "react";
import { ModalProvider } from "react-modal-hook";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import OrderPage from "./components/Pages/Order/OrderPage";
import OverviewPage from "./components/Pages/Overview/OverviewPage";
import SnackProvider from "./components/SnackProvider/SnackProvider";
import TopBar from "./components/TopBar/TopBar";

function App(): React.ReactElement {
  return (
    <>
      <ModalProvider>
        <Layout>
          <TopBar />
          <Switch>
            <Route path="/orders/:orderId">
              <OrderPage />
            </Route>
            <Route exact path="/">
              <OverviewPage />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Layout>
      </ModalProvider>
      <SnackProvider />
    </>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

export default App;
