import { createApiError } from "../models/api/apiError";
import Role from "../models/role";
import apiClient from "./apiClient";

async function getRoles(abortSignal: AbortSignal): Promise<Role[]> {
  const response = await apiClient.get(`/orders/roles`, abortSignal);

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to fetch roles", json);
  }
}

export default {
  getRoles
};
