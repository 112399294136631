import AADUser from "../models/aadUser";
import { createApiError } from "../models/api/apiError";
import graphApiClient from "./graphApiClient";

async function getSelf(): Promise<AADUser> {
  const response = await graphApiClient.get("/me");

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to fetch self from Azure AD", {
      status: response.status
    });
  }
}

async function getUsersWithDisplayNameStartingWith(
  abortSignal: AbortSignal,
  queryString: string
): Promise<AADUser[]> {
  const response = await graphApiClient.get(
    `/users?$filter=startswith(displayName,'${queryString}')`,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json.value;
  } else {
    throw createApiError("Failed to fetch Azure AD users", {
      status: response.status
    });
  }
}

export default { getSelf, getUsersWithDisplayNameStartingWith };
