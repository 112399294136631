import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog as EdsDialog,
  Divider,
  Icon
} from "@equinor/eds-core-react";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useRequest, useRequestStatus } from "../../../hooks";
import OverviewLog from "../../../models/overviewLog";
import { selectOrderId } from "../../../state/currentOrder/selectors";
import {
  selectFieldsAndWellboresWithOrder,
  selectFieldsAndWellboresWithoutOrder,
  selectSelectedField
} from "../../../state/overview/selectors";
import {
  RequestType,
  resetRequest,
  startRequest
} from "../../../state/request";
import { addSnack } from "../../../state/snacks/reducer";
import { colors } from "../../../styles/Colors";
import SelectMenu from "../../SelectMenu";
import Tooltip from "../../Tooltip";

const { Title, Header } = EdsDialog;

type Props = {
  log?: OverviewLog;
  onClose: () => void;
};

function CreateOrderDialog({ log, onClose }: Props): React.ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const orderId = useSelector(selectOrderId);
  const fieldFilter = useSelector(selectSelectedField);
  const wellboresByField = useSelector(selectFieldsAndWellboresWithoutOrder);
  const wellboresWithOrderByField = useSelector(
    selectFieldsAndWellboresWithOrder
  );

  const { isReady: isFetchReady, isError: isFetchError } = useRequestStatus(
    RequestType.GetOrdersGroupedByField
  );

  const errorMessage = `The order could not be created, please try again`;

  const camelCase = (str: string): string => {
    return str.replace(
      /(?:^\wÆØÅæøå|[A-ZÆØÅ]|\b\wÆØÅæøå)/g,
      function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }
    );
  };

  const { handleSubmit, control, setValue, setError, clearErrors, watch } =
    useForm({
      defaultValues: {
        field: "",
        wellboreId: "",
        isCopy: false,
        copyFrom: {
          field: "",
          orderId: ""
        }
      },
      criteriaMode: "all"
    });

  useEffect(() => {
    dispatch(startRequest(RequestType.GetOrdersGroupedByField)());
    return () => {
      dispatch(resetRequest(RequestType.GetOrdersGroupedByField));
    };
  }, [dispatch]);

  useEffect(() => {
    if (log) {
      setValue("field", log.fieldName);
      setValue("copyFrom.field", log.fieldName);

      if (!log.orderId) {
        setValue("wellboreId", log.wellboreId);
      } else {
        setValue("isCopy", true);
        setValue("copyFrom.orderId", log.orderId);
      }
    }
  }, [log, setValue]);

  useEffect(() => {
    if (fieldFilter) {
      setValue("field", fieldFilter);
      setValue("copyFrom.field", fieldFilter);
    }
  }, [fieldFilter, setValue]);

  const {
    invokerFunction: onSubmit,
    isLoading,
    isSuccess,
    isError
  } = useRequest({
    requestType: RequestType.CreateOrder,
    successCallback: () => {
      dispatch(addSnack("The order was successfully created"));
      history.push(`/orders/${orderId}`);
    },
    errorCallback: () => dispatch(addSnack(errorMessage)),
    payloadGenerator: {
      func: (data) => [
        data.wellboreId as string,
        data.isCopy ? data.copyFrom.orderId : undefined
      ],
      deps: []
    },
    setError
  });

  const fields = useMemo(
    () =>
      Object.keys(wellboresByField).map((field, idx) => ({
        id: String(idx),
        name: field
      })),
    [wellboresByField]
  );

  const selectedField = watch("field");
  const isCopy = watch("isCopy");

  return (
    <Dialog open={true}>
      <Header>
        <Title>Create order</Title>
      </Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Controller
            name="field"
            control={control}
            rules={{ required: "This field is required" }}
            render={({
              field: { ref, onChange, ...props },
              fieldState: { error }
            }) => (
              <SelectMenu
                {...props}
                label="Field"
                inputRef={ref}
                data={fields}
                errorText={error?.message}
                onSelect={(field) => {
                  setValue("copyFrom", { field: field.name, orderId: "" });
                  setValue("wellboreId", "");

                  if (isCopy) {
                    clearErrors("copyFrom.field");
                  }

                  onChange(field.name);
                }}
                readOnly={!!fieldFilter}
                variant="text-field"
                required
              />
            )}
          />
          <Controller
            name="wellboreId"
            control={control}
            rules={{ required: "This field is required" }}
            render={({
              field: { ref, onChange, ...props },
              fieldState: { error }
            }) => (
              <SelectMenu
                {...props}
                label="Wellbore"
                inputRef={ref}
                data={wellboresByField[selectedField] ?? []}
                onSelect={(wellbore) => onChange(wellbore.id)}
                disabled={!selectedField}
                errorText={error?.message}
                variant="text-field"
                required
              />
            )}
          />
        </Row>
        <Divider />
        <Controller
          name="isCopy"
          control={control}
          render={({ field: { onChange, ...props } }) => (
            <Checkbox
              {...props}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.value) {
                  clearErrors("copyFrom");
                }
                onChange(e);
              }}
              label="Copy from existing order"
            />
          )}
        />
        <Row>
          <Controller
            name="copyFrom.field"
            control={control}
            rules={{
              required: {
                value: isCopy,
                message: "This field is required"
              }
            }}
            render={({
              field: { ref, value, onChange, ...props },
              fieldState: { error }
            }) => (
              <SelectMenu
                {...props}
                label="Source field"
                value={isCopy ? value : ""}
                inputRef={ref}
                data={fields}
                onSelect={(field) => {
                  setValue("field", field.name);
                  clearErrors("field");
                  setValue("copyFrom.orderId", "");
                  setValue("wellboreId", "");
                  onChange(field.name);
                }}
                disabled={!isCopy || !isFetchReady || isFetchError}
                readOnly={!!fieldFilter && isCopy}
                errorText={error?.message}
                variant="text-field"
                required={isCopy}
              />
            )}
          />
          <Controller
            name="copyFrom.orderId"
            control={control}
            rules={{
              required: {
                value: isCopy,
                message: "This field is required"
              }
            }}
            render={({
              field: { ref, value, onChange, ...props },
              fieldState: { error }
            }) => (
              <SelectMenu
                {...props}
                label="Source wellbore"
                inputRef={ref}
                value={isCopy ? value : ""}
                data={wellboresWithOrderByField[camelCase(selectedField)] ?? []}
                onSelect={(wellbore) => onChange(wellbore.id)}
                disabled={
                  !isCopy || !selectedField || !isFetchReady || isFetchError
                }
                errorText={error?.message}
                variant="text-field"
                required={isCopy}
              />
            )}
          />
        </Row>
        <Divider />
        <ActionButtons>
          <Button type="submit" disabled={isLoading || isSuccess}>
            Create
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={isLoading || isSuccess}
          >
            Cancel
          </Button>
          {(isLoading || (isCopy && !isFetchReady && !isFetchError)) && (
            <Spinner />
          )}
          {(isError || (isCopy && isFetchError)) && (
            <Tooltip
              placement="top-start"
              title={
                isFetchError
                  ? "The list of wellbores to copy from could not be loaded, please try again"
                  : errorMessage
              }
            >
              <Icon
                name="errorOutlined"
                color={colors.interactive.dangerResting}
              />
            </Tooltip>
          )}
        </ActionButtons>
      </form>
    </Dialog>
  );
}

const Dialog = styled(EdsDialog)`
  width: 31.5rem;

  & > form {
    & > label {
      margin-left: 0.5rem;
    }

    & > hr:nth-child(2) {
      margin-bottom: 0;
    }
  }
`;

const Row = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1.25rem;

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  &&& > * {
    width: 100%;
  }
`;

const ActionButtons = styled.div`
  align-items: center;
  display: flex;
  margin: 0 1rem 1.375rem;

  & > button,
  & > button:disabled {
    margin-right: 1rem;
  }
`;

const Spinner = styled(CircularProgress)`
  height: auto;
  width: 1.25rem;
`;

export default CreateOrderDialog;
