// @ts-ignore
import { tokens } from "@equinor/eds-tokens";

export const colors = {
  infographic: {
    substitutePinkRose: tokens.colors.infographic.substitute__pink_rose.rgba,
    substituteBlueOvercast:
      tokens.colors.infographic.substitute__blue_overcast.rgba,
    substituteBlueOcean: tokens.colors.infographic.substitute__blue_ocean.rgba
  },
  interactive: {
    dangerHover: tokens.colors.interactive.danger__hover.rgba,
    dangerResting: tokens.colors.interactive.danger__resting.rgba,
    primaryResting: tokens.colors.interactive.primary__resting.rgba,
    successResting: tokens.colors.interactive.success__resting.rgba,
    successText: tokens.colors.interactive.success__text.rgba,
    warningResting: tokens.colors.interactive.warning__resting.rgba,
    warningText: tokens.colors.interactive.warning__text.rgba
  },
  text: {
    danger: tokens.colors.interactive.danger__text.rgba,
    disabled: tokens.colors.interactive.disabled__text.rgba,
    staticIconsDefault: tokens.colors.text.static_icons__default.rgba,
    staticIconsTertiary: tokens.colors.text.static_icons__tertiary.rgba,
    staticIconsPrimaryWhite: tokens.colors.text.static_icons__primary_white.rgba
  },
  ui: {
    backgroundDefault: tokens.colors.ui.background__default.rgba,
    backgroundLight: tokens.colors.ui.background__light.rgba,
    backgroundMedium: tokens.colors.ui.background__medium.rgba,
    backgroundOverlay: tokens.colors.ui.background__overlay.rgba
  }
};
