import environment from "../config/environment";
import { getAccessToken } from "../msal/MsalAuthProvider";
import { RequestInit } from "./apiClient";

function get(
  pathName: string,
  abortSignal: AbortSignal | null = null
): Promise<Response> {
  const requestInit: RequestInit = {
    signal: abortSignal
  };

  return runHttpRequest(pathName, requestInit);
}

async function getCommonHeaders(): Promise<any> {
  const accessToken = await getAccessToken(
    environment.SCOPE_PARAMETER_GRAPH_API
  );

  return {
    "Authorization": `Bearer ${accessToken}`,
    "Content-Type": "application/json"
  };
}

async function runHttpRequest(pathName: string, requestInit: RequestInit) {
  const headers = await getCommonHeaders();

  return new Promise<Response>((resolve, reject) => {
    const url = new URL(`${environment.URL_GRAPH_API}${pathName}`);
    fetch(url.href, { ...requestInit, headers: headers })
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        }
        reject(error);
      });
  });
}

export default {
  get
};
