import {
  Accordion as EdsAccordion,
  CircularProgress,
  Typography
} from "@equinor/eds-core-react";
import React, { memo, useCallback, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  useContextMenu,
  useRequest,
  useRequestStatus
} from "../../../../hooks";
import Log from "../../../../models/log";
import {
  selectHasServiceProviders,
  selectHasTargetWellbores,
  selectLogIds,
  selectNonSiteComWitsmlTargetSystemNames,
  selectOrderId
} from "../../../../state/currentOrder/selectors";
import { RequestType } from "../../../../state/request";
import { addSnack } from "../../../../state/snacks/reducer";
import { colors } from "../../../../styles/Colors";
import GenericErrorPanel from "../../../GenericErrorPanel/GenericErrorPanel";
import SupportEmail from "../../../SupportEmail";
import LogAccordionContextMenu from "./LogAccordionContextMenu";
import LogAccordionItem from "./LogAccordionItem";
import LogClearConfirmationDialog from "./LogClearConfirmationDialog";
import LogCopyDialog from "./LogCopyDialog";
import LogRemoveRunConfirmationDialog from "./LogRemoveRunConfirmationDialog";

function LogAccordion(): React.ReactElement {
  const dispatch = useDispatch();

  const logIds = useSelector(selectLogIds);
  const orderId = useSelector(selectOrderId);
  const hasTargetWellbores = useSelector(selectHasTargetWellbores);
  const hasServiceProviders = useSelector(selectHasServiceProviders);
  const targetSystemNames = useSelector(
    selectNonSiteComWitsmlTargetSystemNames
  );

  const { isReady, isError } = useRequestStatus(
    RequestType.GetOrder,
    RequestType.GetProviderServiceTools,
    RequestType.GetTargetWellbores,
    RequestType.GetTargetSystems
  );

  const anchor = useRef<HTMLDivElement | null>(null);
  const [selectedLog, setSelectedLog] = useState({} as Log);

  const [showLogClearConfirmationDialog, hideLogClearConfirmationDialog] =
    useModal(
      () => (
        <LogClearConfirmationDialog
          log={selectedLog}
          onClose={hideLogClearConfirmationDialog}
        />
      ),
      [selectedLog]
    );

  const [
    showLogRemoveRunConfirmationDialog,
    hideLogRemoveRunConfirmationDialog
  ] = useModal(
    () => (
      <LogRemoveRunConfirmationDialog
        log={selectedLog}
        onClose={hideLogRemoveRunConfirmationDialog}
      />
    ),
    [selectedLog]
  );

  const [showLogCopyModal, hideLogCopyModal] = useModal(
    () => <LogCopyDialog log={selectedLog} onClose={hideLogCopyModal} />,
    [selectedLog]
  );

  const { invokerFunction: addRun } = useRequest({
    requestType: RequestType.CreateRun,
    successCallback: () => {
      dispatch(
        addSnack(`The new run for ${selectedLog.name} was successfully created`)
      );
    },
    errorCallback: () => {
      dispatch(
        addSnack(
          `The new run for ${selectedLog.name} could not be created, please try again`
        )
      );
    },
    payloadGenerator: {
      func: () => [orderId, selectedLog.id],
      deps: [orderId, selectedLog.id]
    }
  });

  const [ContextMenu, showContextMenu, hideContextMenu] = useContextMenu(
    () => (
      <LogAccordionContextMenu
        anchor={anchor.current}
        log={selectedLog}
        onCopy={showLogCopyModal}
        onClear={showLogClearConfirmationDialog}
        onAddRun={addRun}
        onRemoveRun={showLogRemoveRunConfirmationDialog}
        onClose={hideContextMenu}
      />
    ),
    [
      anchor.current,
      selectedLog,
      showLogCopyModal,
      showLogClearConfirmationDialog,
      addRun,
      showLogRemoveRunConfirmationDialog
    ]
  );

  const toggleContextMenu = useCallback(
    (element: HTMLDivElement | null, log: Log) => {
      if (anchor.current) {
        hideContextMenu();
        anchor.current = null;
      }
      if (element) {
        anchor.current = element;
        setSelectedLog(log);
        showContextMenu();
      }
    },
    [hideContextMenu, showContextMenu]
  );

  if (isError) {
    return <GenericErrorPanel />;
  }

  if (!isReady) {
    return <Spinner />;
  }

  if (!hasTargetWellbores) {
    return (
      <Container>
        <TextContainer>
          <Typography>
            No WITSML systems are configured for this order
          </Typography>
          {targetSystemNames.length > 0 && (
            <Typography>
              Please configure{" "}
              {targetSystemNames.length > 1
                ? targetSystemNames.join(" or ")
                : targetSystemNames[0]}{" "}
              in the configuration tab
            </Typography>
          )}
        </TextContainer>
      </Container>
    );
  }

  if (!hasServiceProviders) {
    return (
      <Container>
        <TextContainer>
          <Typography>
            No service providers are registered in WellCom for this order
          </Typography>
          <Typography>
            Please contact <SupportEmail /> for assistance
          </Typography>
        </TextContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Accordion>
        {logIds.map((id) => (
          <LogAccordionItem key={id} logId={id} callback={toggleContextMenu} />
        ))}
      </Accordion>
      <ContextMenu />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  padding: 0 2rem 3rem;
`;

const Accordion = styled(EdsAccordion)`
  border: 1px solid ${colors.ui.backgroundMedium};
  overflow: auto;

  :empty::after {
    content: "No curves matches filter";
    display: block;
    font-size: 2rem;
    margin: 4.75rem auto;
    text-align: center;
  }

  & > div > h2 {
    border-left: unset;
    border-right: unset;
  }

  & > div:first-child > h2 {
    border-top: unset;
  }

  & > div:last-child > * {
    border-bottom: unset;
  }
`;

const TextContainer = styled.div`
  border: 1px solid ${colors.ui.backgroundMedium};
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: center;

  & > * {
    :first-child {
      font-size: 2rem;
    }

    text-align: center;
  }
`;

const Spinner = styled(CircularProgress)`
  margin-left: 50%;
  margin-top: 5%;
`;

export default memo(LogAccordion);
