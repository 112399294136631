import { MutableRefObject, Ref, RefCallback, useCallback } from "react";

export const useCombinedRefs = <T extends HTMLElement>(
  ...refs: Ref<T>[]
): RefCallback<T> => {
  return useCallback(
    (element) =>
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(element);
        } else if (ref && typeof ref === "object") {
          (ref as MutableRefObject<T | null>).current = element;
        }
      }),
    [refs]
  );
};
