import { Button, Search, Typography } from "@equinor/eds-core-react";
import React, { ChangeEvent, memo } from "react";
import { useModal } from "react-modal-hook";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useDebounce, useRequestStatus } from "../../../hooks";
import OrderStatus from "../../../models/orderStatus";
import {
  setLogType,
  setSelectedField,
  setWellboreFilter
} from "../../../state/overview/reducer";
import {
  selectFields,
  selectIsWellboresWithoutOrder,
  selectSelectedField,
  selectSelectedLogType
} from "../../../state/overview/selectors";
import { RequestType } from "../../../state/request";
import { colors } from "../../../styles/Colors";
import Icon from "../../../styles/Icons";
import SelectMenu from "../../SelectMenu";
import Tooltip from "../../Tooltip";
import CreateOrderDialog from "./CreateOrderDialog";

type Props = {
  showBanner: boolean;
  toggleBanner: (s: boolean) => void;
};

function SearchAndFilterBar({
  showBanner,
  toggleBanner
}: Props): React.ReactElement {
  const dispatch = useDispatch();

  const fields = useSelector(selectFields);
  const selectedField = useSelector(selectSelectedField);
  const selectedStatus = useSelector(selectSelectedLogType);
  const isWellboresWithoutOrder = useSelector(selectIsWellboresWithoutOrder);
  const { isReady, isError } = useRequestStatus(
    RequestType.GetFields,
    RequestType.GetLogs
  );

  const [showCreateOrderDialog, hideCreateOrderDialog] = useModal(() => (
    <CreateOrderDialog onClose={hideCreateOrderDialog} />
  ));

  const debounceDispatchWellboreFilter = useDebounce(
    (filter: string) => dispatch(setWellboreFilter(filter)),
    250
  );

  return (
    <Container>
      <Typography variant="h2">Order sections</Typography>
      <FilterContainer>
        <SelectMenu
          name="Field"
          data={fields}
          onSelect={(field) => dispatch(setSelectedField(field?.name))}
          value={selectedField}
          allowEmpty
          disabled={!isReady || isError}
          variant="search"
        />
        <SelectMenu
          name="Filter"
          data={Object.keys(OrderStatus).map((status, idx) => ({
            id: String(idx),
            name: status
          }))}
          onSelect={(status) =>
            dispatch(setLogType(status.name as OrderStatus))
          }
          value={selectedStatus}
          disabled={!isReady || isError}
          variant="search"
        />
        <Search
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            debounceDispatchWellboreFilter(e.target.value)
          }
          aria-label="sitewide"
          id="textfield-search"
          placeholder="Search for wellbore"
          disabled={!isReady || isError}
        />
      </FilterContainer>
      <ButtonContainer>
        <Button
          onClick={showCreateOrderDialog}
          disabled={!isReady || isError || !isWellboresWithoutOrder}
        >
          <Icon name="add" title="add" size={16} />
          Create order
        </Button>
        {isReady && !isWellboresWithoutOrder && (
          <Tooltip title="No wellbores are eligible for creating an order">
            <Icon
              name="infoCircle"
              color={colors.interactive.primaryResting}
              size={20}
            />
          </Tooltip>
        )}
        <Icon
          onClick={() => toggleBanner(!showBanner)}
          name={showBanner ? "unfoldMore" : "unfoldLess"}
          color={colors.interactive.primaryResting}
          title="Toggle banner"
          size={24}
        />
      </ButtonContainer>
    </Container>
  );
}

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 12.5rem;

  & > :not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const Container = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;

  & > div:last-child {
    margin-left: 1rem;
  }

  & > h2 {
    line-height: inherit;
  }
`;

const FilterContainer = styled.div`
  align-items: flex-end;
  display: flex;

  & > :not(:last-child) {
    margin-right: 1rem;
  }
`;

export default memo(SearchAndFilterBar);
