import {
  Button,
  CircularProgress,
  Dialog as EdsDialog,
  Icon,
  Typography
} from "@equinor/eds-core-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useRequest } from "../../../../hooks";
import Contact from "../../../../models/contact";
import { selectOrderId } from "../../../../state/currentOrder/selectors";
import { RequestType } from "../../../../state/request";
import { addSnack } from "../../../../state/snacks/reducer";
import { colors } from "../../../../styles/Colors";
import Tooltip from "../../../Tooltip";

const { Title, Header } = EdsDialog;

type Props = {
  contact: Contact;
  onClose: () => void;
};

function ContactDeleteDialog({ contact, onClose }: Props): React.ReactElement {
  const dispatch = useDispatch();

  const orderId = useSelector(selectOrderId);
  const [errorMessageApi, setErrorMessageApi] = useState("");
  const errorMessage = `The contact ${contact.name} could not be deleted`;

  const {
    invokerFunction: onDeleteContact,
    isError,
    isLoading,
    isSuccess
  } = useRequest({
    requestType: RequestType.DeleteContact,
    successCallback: () => {
      dispatch(
        addSnack(`The contact ${contact.name} was successfully deleted`)
      );
      onClose();
    },
    errorCallback: (error) => {
      if (error?.status === 400 && error.title?.includes("submitted order")) {
        setErrorMessageApi(error.title);
      } else {
        setErrorMessageApi("");
      }
      dispatch(addSnack(errorMessage));
    },
    payloadGenerator: {
      func: () => [orderId, contact],
      deps: [orderId, contact]
    }
  });

  return (
    <Dialog open={true}>
      <Header>
        <Title>Delete contact?</Title>
      </Header>
      <Row>
        The contact
        <Typography variant="body_short" as="span" bold>
          {" "}
          {contact.name}{" "}
        </Typography>
        will be deleted.
      </Row>
      <ActionButtons>
        <Button
          color="danger"
          variant="outlined"
          onClick={onClose}
          disabled={isLoading || isSuccess}
        >
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={onDeleteContact}
          disabled={isLoading || isSuccess}
        >
          Delete
        </Button>
        {isLoading && <Spinner />}
        {isError && (
          <Tooltip
            placement="bottom-start"
            title={`${errorMessage}. Please try again`}
            html={
              errorMessageApi ? (
                <TooltipContainer>
                  <Typography group="ui" variant="tooltip" color="white">
                    {`${errorMessage}.`}
                  </Typography>
                  <Typography group="ui" variant="tooltip" color="white">
                    {errorMessageApi}
                  </Typography>
                </TooltipContainer>
              ) : undefined
            }
            variant="dark"
          >
            <Icon
              name="errorOutlined"
              color={colors.interactive.dangerResting}
            />
          </Tooltip>
        )}
      </ActionButtons>
    </Dialog>
  );
}

const Dialog = styled(EdsDialog)`
  width: 31.5rem;
`;

const Row = styled.div`
  margin: 0 1rem 1rem;
`;

const ActionButtons = styled(Row)`
  align-items: center;
  display: flex;

  & > button,
  & > button:disabled {
    margin-right: 1rem;
  }
`;

const Spinner = styled(CircularProgress)`
  height: auto;
  width: 1.25rem;
`;

const TooltipContainer = styled.div`
  width: 23.5rem;
`;

export default ContactDeleteDialog;
