import { createSelector, OutputParametricSelector } from "@reduxjs/toolkit";

import { BaseRootState } from "../../types";
import { selectIsFilterActive } from "../selectors";
import { AccordionState } from "./reducer";
import {
  LogAccordionDefaultExpandedState,
  ServiceAccordionDefaultExpandedState,
  ToolAccordionDefaultExpandedState
} from "./types";

type SelectIsAccordionExpandedSelector = OutputParametricSelector<
  BaseRootState,
  string,
  boolean,
  (
    res1: AccordionState,
    res2: boolean,
    res3: string,
    res4: string | undefined,
    res5: string | undefined
  ) => boolean
>;
export const selectIsAccordionExpandedSelectorFactory =
  (): SelectIsAccordionExpandedSelector =>
    createSelector(
      (state: BaseRootState) => state.currentOrder.accordionState,
      selectIsFilterActive,
      (_: BaseRootState, logId: string) => logId,
      (_: BaseRootState, __: string, serviceId?: string) => serviceId,
      (_: BaseRootState, __: string, ___?: string, toolId?: string) => toolId,
      (state, activeFilter, logId, serviceId, toolId) => {
        if (activeFilter) {
          return true;
        }

        if (serviceId) {
          if (toolId) {
            return (
              state[logId]?.service[serviceId]?.tool[toolId] ??
              ToolAccordionDefaultExpandedState
            );
          }
          return (
            state[logId]?.service[serviceId]?.expanded ??
            ServiceAccordionDefaultExpandedState
          );
        }
        return state[logId]?.expanded ?? LogAccordionDefaultExpandedState;
      }
    );
