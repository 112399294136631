import { Menu, Typography } from "@equinor/eds-core-react";
import React from "react";
import { useDispatch } from "react-redux";

import { setTheme } from "../../state/settings/reducer";
import { Theme } from "../../state/settings/types";

const { Item: MenuItem } = Menu;

type Props = {
  anchor: HTMLElement | null;
  onClose: () => void;
};

function ThemeContextMenu({ anchor, onClose }: Props): React.ReactElement {
  const dispatch = useDispatch();

  const isOpen = Boolean(anchor);

  return (
    <Menu open={isOpen} anchorEl={anchor} placement="bottom" onClose={onClose}>
      {Object.values(Theme).map((theme: Theme) => (
        <MenuItem key={theme} onClick={() => dispatch(setTheme(theme))}>
          <Typography group="navigation" variant="menu_title">
            {theme}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
}

export default ThemeContextMenu;
