const noChanges = {
  label: "No changes",
  header: "No change of selected curves.",
  text: "Section order has no changes since last save."
};

export const saveDraftStatus = [
  noChanges,
  {
    label: "Save draft",
    header: "Save selected curves as draft.",
    text: "Section order is not submitted."
  },
  {
    label: "(Save draft)",
    header: "Draft mode is disabled.",
    text: "Section has a submitted section order."
  }
];

export const saveAndSubmitStatus = [
  noChanges,
  {
    label: "Save and Submit",
    header: "Changes of selected curves since last save.",
    text: "Save and submit to update section order."
  }
];
