import React, { memo, useMemo } from "react";

import { useParamSelector } from "../../../../hooks";
import { selectNumberOfOrderedCurvesForServiceSelectorFactory } from "../../../../state/currentOrder/selectors";
import SelectedCurvesLabel from "./SelectedCurvesLabel";

type Props = {
  logId: string;
  serviceIds: string[];
};

function LogSelectedCurvesLabel({
  logId,
  serviceIds
}: Props): React.ReactElement | null {
  const selectNumberOfOrderedCurves = useMemo(
    selectNumberOfOrderedCurvesForServiceSelectorFactory,
    []
  );

  const GetNumberOfCurvesForServiceId = ({ serviceId }: { serviceId: any }) => {
    return useParamSelector(selectNumberOfOrderedCurves, logId, serviceId);
  };

  const numberOfOrderedCurvesMl = GetNumberOfCurvesForServiceId({
    serviceId: serviceIds[0]
  });

  const numberOfOrderedCurvesMwd = GetNumberOfCurvesForServiceId({
    serviceId: serviceIds[1]
  });

  const numberOfOrderedCurves =
    numberOfOrderedCurvesMl + numberOfOrderedCurvesMwd;

  if (!numberOfOrderedCurves) {
    return null;
  }

  return <SelectedCurvesLabel numberOfOrderedCurves={numberOfOrderedCurves} />;
}

export default memo(LogSelectedCurvesLabel);
