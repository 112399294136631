import { DotProgress, Tabs, Typography } from "@equinor/eds-core-react";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useRequestStatus } from "../../../hooks";
import { setActiveTab } from "../../../state/currentOrder/reducer";
import {
  selectActiveTab,
  selectWellboreName
} from "../../../state/currentOrder/selectors";
import { RequestType } from "../../../state/request";
import { colors } from "../../../styles/Colors";

const { List: TabList, Tab: EdsTab } = Tabs;

function StatusAndActionBar(): React.ReactElement {
  const dispatch = useDispatch();

  const wellboreName = useSelector(selectWellboreName);

  const activeTab = useSelector(selectActiveTab);
  const setTab = (tab: number) => dispatch(setActiveTab(tab));

  const { isReady } = useRequestStatus(
    RequestType.GetOrder,
    RequestType.GetTargetWellbores
  );

  return (
    <OrderHeader>
      <div>
        <Typography>RIPS order for:</Typography>
        {wellboreName.length > 0 ? (
          <Typography variant="h1">{wellboreName}</Typography>
        ) : (
          <div style={{ padding: "1rem" }}>
            <DotProgress color="primary" />
          </div>
        )}
        <Tabs activeTab={activeTab} onChange={setTab}>
          <TabList>
            <Tab disabled={!isReady}>Configuration</Tab>
            <Tab disabled={!isReady}>Curve Orders</Tab>
          </TabList>
        </Tabs>
      </div>
    </OrderHeader>
  );
}

const Tab = styled(EdsTab)`
  background: ${colors.ui.backgroundLight};

  &:disabled {
    border-bottom: unset;
    color: ${colors.text.disabled};
  }
`;

const OrderHeader = styled.div`
  background: ${colors.ui.backgroundLight};
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 0 2rem;
`;

export default memo(StatusAndActionBar);
