import { createSlice } from "@reduxjs/toolkit";

import { addCurve, removeCurve, removeLog, updateLog } from "../actions";
import { LogCurvePayload, RemoveLogCurvePayload } from "../types";

export interface CurveDiffState {
  added: LogCurvePayload[];
  removed: RemoveLogCurvePayload[];
}

const initialState = {
  added: [],
  removed: []
} as CurveDiffState;

const curveDiffSlice = createSlice({
  name: "currentOrder/curveDiff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCurve, (state, action) => {
        const { logId, targetWellboreId, curveId, curveType } = action.payload;

        const removedIndex = state.removed.findIndex(
          (logCurve) =>
            logCurve.logId === logId &&
            logCurve.targetWellboreId === targetWellboreId &&
            logCurve.curveId === curveId &&
            logCurve.curveType === curveType
        );

        if (~removedIndex) {
          state.removed.splice(removedIndex, 1);
        } else {
          state.added.push({ logId, targetWellboreId, curveId, curveType });
        }
      })
      .addCase(removeCurve, (state, action) => {
        const { id, logId, targetWellboreId, curveId, curveType } =
          action.payload;

        const addedIndex = state.added.findIndex(
          (logCurve) =>
            logCurve.logId === logId &&
            logCurve.targetWellboreId === targetWellboreId &&
            logCurve.curveId === curveId &&
            logCurve.curveType === curveType
        );

        if (~addedIndex) {
          state.added.splice(addedIndex, 1);
        } else {
          state.removed.push({
            id,
            logId,
            targetWellboreId,
            curveId,
            curveType
          });
        }
      })
      .addCase(removeLog, (state, { payload: logId }) => {
        // log has been deleted, remove all diffs for this log.
        state.added = state.added.filter((curve) => curve.logId !== logId);
        state.removed = state.removed.filter((curve) => curve.logId !== logId);
      })
      .addCase(updateLog, (state, { payload: { id: logId } }) => {
        // log has been updated, remove all diffs for this log.
        state.added = state.added.filter((curve) => curve.logId !== logId);
        state.removed = state.removed.filter((curve) => curve.logId !== logId);
      });
  }
});

export default curveDiffSlice.reducer;
