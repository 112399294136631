import { Accordion as EdsAccordion } from "@equinor/eds-core-react";
import React, { memo, MouseEvent, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useParamSelector } from "../../../../hooks";
import Log from "../../../../models/log";
import { selectIsAccordionExpandedSelectorFactory } from "../../../../state/currentOrder/accordionState/selectors";
import { setLogAccordionState } from "../../../../state/currentOrder/reducer";
import {
  selectLogSelectorFactory,
  selectProviderServiceToolIdsSelectorFactory
} from "../../../../state/currentOrder/selectors";
import { selectTheme } from "../../../../state/settings/selectors";
import { Theme } from "../../../../state/settings/types";
import { colors } from "../../../../styles/Colors";
import Icon from "../../../../styles/Icons";
import { dateFormatter } from "../../../../utils/dateFormatter";
import StatusLabel from "../../../StatusLabel/StatusLabel";
import LogSelectedCurvesLabel from "./LogSelectedCurvesLabel";
import ServiceAccordionItem from "./ServiceAccordionItem";

const {
  Header: EdsAccordionHeader,
  Item: EdsAccordionItem,
  Panel: EdsAccordionPanel
} = EdsAccordion;

type Props = {
  logId: string;
  callback: (element: HTMLDivElement | null, log: Log) => void;
};

function LogAccordionItem({
  logId,
  callback,
  ...rest
}: Props): React.ReactElement | null {
  const dispatch = useDispatch();

  const selectLog = useMemo(selectLogSelectorFactory, []);
  const selectProviderServiceToolIds = useMemo(
    selectProviderServiceToolIdsSelectorFactory,
    []
  );
  const selectExpanded = useMemo(selectIsAccordionExpandedSelectorFactory, []);

  const log = useParamSelector(selectLog, logId);
  const serviceIds = useParamSelector(selectProviderServiceToolIds, logId);
  const expanded = useParamSelector(selectExpanded, logId);
  const theme = useSelector(selectTheme);

  const ref = useRef<HTMLDivElement>(null);

  if (serviceIds.length === 0) {
    return null;
  }

  return (
    <AccordionItem {...rest} isExpanded={expanded} theme={theme}>
      <EdsAccordionHeader
        onToggle={() => dispatch(setLogAccordionState(logId, !expanded))}
      >
        <HeaderItem>{log.name}</HeaderItem>
        <HeaderItem>{dateFormatter(log.startTime)}</HeaderItem>
        <HeaderItem>{log.rigName}</HeaderItem>
        <HeaderItem>
          <StatusLabel status={log.depthStatusSimple} />
        </HeaderItem>
        <HeaderItem>
          <a
            target="_blank"
            href={`https://planner.wellcom.equinor.com/projectplanning/index.html#project/${log.sectionProjectId}/planner-planning`}
            onClick={(e: MouseEvent) => e.stopPropagation()}
          >
            Wellcom
          </a>
        </HeaderItem>
        <HeaderItemLabel>
          <LogSelectedCurvesLabel logId={logId} serviceIds={serviceIds} />
        </HeaderItemLabel>
        <HeaderMenu>
          <Icon
            ref={ref}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              callback(ref.current, log);
            }}
            name="moreVertical"
            title="Open menu"
            size={24}
            color={colors.interactive.primaryResting}
          />
        </HeaderMenu>
      </EdsAccordionHeader>
      <AccordionPanel>
        {expanded && (
          <ServiceAccordion>
            {serviceIds.map((id) => (
              <ServiceAccordionItem key={id} logId={logId} serviceId={id} />
            ))}
          </ServiceAccordion>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

const ServiceAccordion = styled(EdsAccordion)`
  & > div > h2 {
    border-right: unset;
  }

  & > div:first-child > h2 {
    border-top: unset;
  }

  & > div:last-child > * {
    border-bottom: unset;
  }
`;

const AccordionItem = styled(EdsAccordionItem)<{
  theme: Theme;
  isExpanded: boolean;
}>`
  & > h2 {
    background: ${colors.ui.backgroundDefault};
    position: sticky;
    top: 0;
    z-index: 10;
    ${({ isExpanded }) =>
      isExpanded
        ? `&&& { border-bottom: 1px solid ${colors.ui.backgroundMedium}; }`
        : ""};
    & > button {
      ${({ theme }) => theme === Theme.Compact && { height: "2rem" }}
    }
  }
`;

const AccordionPanel = styled(EdsAccordionPanel)<{ cTheme: Theme }>`
  border-left: unset;
  border-right: unset;
  border-top: unset;
  min-height: auto;
  padding: 0 0 0 3rem;
  position: sticky;
  top: ${({ cTheme }) => (cTheme === Theme.Compact ? "4rem" : "6rem")};
  z-index: 8;
`;

const HeaderItem = styled.div`
  overflow: hidden;
  padding-right: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 13rem;
`;

const HeaderItemLabel = styled(HeaderItem)`
  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const HeaderMenu = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-left: 0.5rem;
`;

export default memo(LogAccordionItem);
