import { createApiError } from "../models/api/apiError";
import SiteComGroup from "../models/siteComGroup";
import apiClient from "./apiClient";

async function getSiteComGroupsForOrder(
  abortSignal: AbortSignal,
  orderId: string
): Promise<SiteComGroup[]> {
  const response = await apiClient.get(
    `/orders/${orderId}/accessgroups`,
    abortSignal
  );

  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    throw createApiError("Failed to fetch SiteCom groups", json);
  }
}

async function updateSiteComGroupForOrder(
  abortSignal: AbortSignal,
  orderId: string,
  siteComGroup: SiteComGroup
): Promise<SiteComGroup> {
  const response = await apiClient.post(
    `/orders/${orderId}/accessgroups/${siteComGroup.id}`,
    "",
    abortSignal
  );

  if (response.ok) {
    return siteComGroup;
  } else {
    const json = await response.json();
    throw createApiError("Failed to update SiteCom group for order", json);
  }
}

export default {
  getSiteComGroupsForOrder,
  updateSiteComGroupForOrder
};
