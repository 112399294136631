import { CircularProgress } from "@equinor/eds-core-react";
import React from "react";
import styled from "styled-components";

import { useRequestStatus } from "../../../../hooks";
import { RequestType } from "../../../../state/request";
import GenericErrorPanel from "../../../GenericErrorPanel/GenericErrorPanel";
import ContactPanel from "./ContactPanel";
import PropertiesPanel from "./PropertiesPanel";
import SystemPanel from "./SystemPanel";

function ConfigurationPanel(): React.ReactElement {
  const { isReady, isError } = useRequestStatus(
    RequestType.GetOrder,
    RequestType.GetTargetSystems,
    RequestType.GetTargetWellbores,
    RequestType.GetSiteComGroupsForOrder
  );

  if (isError) {
    return <GenericErrorPanel />;
  }

  if (!isReady) {
    return <Spinner />;
  }

  return (
    <Layout>
      <Properties>
        <PropertiesPanel />
        <SystemPanel />
      </Properties>
      <Contacts>
        <ContactPanel />
      </Contacts>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Properties = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

const Contacts = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  padding: 3rem 2rem;
`;

const Spinner = styled(CircularProgress)`
  margin-left: 50%;
  margin-top: 5%;
`;

export default ConfigurationPanel;
