import { Table } from "@equinor/eds-core-react";
import React, { memo } from "react";
import styled from "styled-components";

const { Cell, Head, Row } = Table;

function ContactTableHeader(): React.ReactElement {
  return (
    <Head sticky>
      <Row>
        <Cell>Name</Cell>
        <Cell>Role</Cell>
        <Cell>Main contact</Cell>
        <Cell>Telephone</Cell>
        <Cell>Email</Cell>
        <Cell>Available</Cell>
        <EllipsisCell>{""}</EllipsisCell>
      </Row>
    </Head>
  );
}

const EllipsisCell = styled(Cell)`
  width: 2rem;
`;

export default memo(ContactTableHeader);
