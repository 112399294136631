import { Tabs, Typography } from "@equinor/eds-core-react";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { selectTargetSystems } from "../../../../state/currentOrder/selectors";
import TargetWellboreList from "./TargetWellboreList";

const { List: TabList, Tab, Panels: TabPanels, Panel: TabPanel } = Tabs;

function SystemPanel(): React.ReactElement {
  const systems = useSelector(selectTargetSystems);
  const [activeTab, setActiveTab] = useState(0);

  // TODO: if systems changes (triggering a rerender), the app will crash due to a problem in the
  //  EDS <TabList> component. (Rendered more hooks than during the previous render, line 84)

  return (
    <Layout>
      <Typography variant="h2">Systems</Typography>
      <Tabs
        activeTab={activeTab}
        onChange={(t: number) => setActiveTab(t)}
        variant="fullWidth"
      >
        <TabList>
          {systems.map((system) => (
            <Tab key={system.id}>{system.name}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {systems.map((system) => (
            <TabPanel key={system.id}>
              <TargetWellboreList targetSystemId={system.id} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex: 0 1 40vw;
  flex-direction: column;
  margin: 3rem 2rem 0 0;

  & > h2 {
    margin: 1.5rem 0;
  }
`;

export default memo(SystemPanel);
