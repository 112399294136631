import { Typography } from "@equinor/eds-core-react";
import React from "react";
import { useSelector } from "react-redux";

import OrderStatus from "../../../models/orderStatus";
import {
  selectSelectedField,
  selectSelectedLogType
} from "../../../state/overview/selectors";

function SearchFilterDescription(): React.ReactElement {
  const selectedField = useSelector(selectSelectedField);
  const selectedStatus = useSelector(selectSelectedLogType);

  const fieldDescriptions: { [K in keyof typeof OrderStatus]: string } = {
    [OrderStatus.Ongoing]: `Showing upcoming sections for ${selectedField}`,
    [OrderStatus.Historic]: `Showing previous sections for ${selectedField}`,
    [OrderStatus.All]: `Showing all sections for ${selectedField}`
  };

  const nonFieldDescriptions: { [K in keyof typeof OrderStatus]: string } = {
    [OrderStatus.Ongoing]: "Showing sections for the next 3 months",
    [OrderStatus.Historic]: "Showing sections for the previous 12 months",
    [OrderStatus.All]:
      "Showing sections for the previous 12 months and next 3 months"
  };

  return (
    <Typography variant="overline">
      {selectedField
        ? fieldDescriptions[selectedStatus]
        : nonFieldDescriptions[selectedStatus]}
    </Typography>
  );
}

export default SearchFilterDescription;
