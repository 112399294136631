import {
  Button,
  Dialog as EdsDialog,
  Table,
  Typography
} from "@equinor/eds-core-react";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  selectUnfilteredLogIds,
  selectWellboreName
} from "../../../../state/currentOrder/selectors";
import { isRequestLoading, RequestType } from "../../../../state/request";
import { colors } from "../../../../styles/Colors";
import Icon from "../../../../styles/Icons";
import SaveAndSubmitTableHeader from "./SaveAndSubmitTableHeader";
import SaveAndSubmitTableRow from "./SaveAndSubmitTableRow";

type Props = {
  onClose: () => void;
};

const { Body } = Table;

function SaveAndSubmitDialog({ onClose }: Props): React.ReactElement {
  const logIds = useSelector(selectUnfilteredLogIds);
  const wellboreName = useSelector(selectWellboreName);

  const isUpdateRequestInProgress = useSelector(
    isRequestLoading(RequestType.UpdateCurveOrders)
  );
  const isUpdateAndSubmitRequestInProgress = useSelector(
    isRequestLoading(RequestType.UpdateAndSubmitCurveOrders)
  );

  const isDisabled =
    isUpdateRequestInProgress || isUpdateAndSubmitRequestInProgress;

  return (
    <Dialog open={true}>
      <Container disabled={isDisabled}>
        <TitleContainer>
          <Typography group="navigation" variant="label">
            RIPS Order for {wellboreName}
          </Typography>
          <Typography variant="h3">Save and submit section orders</Typography>
        </TitleContainer>
        <Icon
          name="close"
          title="close"
          onClick={() => {
            if (!isDisabled) {
              onClose();
            }
          }}
          color={
            isDisabled
              ? colors.text.disabled
              : colors.interactive.primaryResting
          }
          size={20}
        />
      </Container>
      <TableContainer>
        <Table>
          <SaveAndSubmitTableHeader />
          <Body>
            {logIds.map((logId) => (
              <SaveAndSubmitTableRow key={logId} logId={logId} />
            ))}
          </Body>
        </Table>
      </TableContainer>
      <CloseButton>
        <Button
          variant="outlined"
          title="close"
          onClick={onClose}
          disabled={isDisabled}
        >
          Close
        </Button>
      </CloseButton>
    </Dialog>
  );
}

const Dialog = styled(EdsDialog)`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: hidden;
  padding: 3rem 3rem 4rem;
  width: 60.5rem;
`;

const Container = styled.div<{ disabled: boolean }>`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  & > svg {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`;

const TitleContainer = styled.div`
  & > p {
    color: ${colors.text.staticIconsTertiary};
    font-weight: 400;
  }

  & > h3 {
    margin: 0;
  }
`;

const TableContainer = styled.div`
  flex: 1 1 100%;
  overflow: auto;

  & > table {
    width: 100%;
  }
`;

const CloseButton = styled.div`
  margin-top: 2rem;
`;

export default SaveAndSubmitDialog;
