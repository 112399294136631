enum DrillingStatus {
  Active = "Active",
  Completed = "Completed",
  Draft = "Draft",
  NoOrder = "No order",
  NoOrderedCurves = "No ordered curves",
  Ready = "Ready",
  ReadyToEstablish = "Ready to establish",
  Submitted = "Submitted",
  Verified = "Verified",
  NotApplicable = "NotApplicable"
}

export const drillingStatusDescriptions = [
  {
    label: DrillingStatus.NotApplicable,
    header: "Section has no depth status.",
    text: "Section may still be available for section order."
  },
  {
    label: DrillingStatus.NoOrderedCurves,
    header: "Section has no ordered depth curves.",
    text: "Select curves, and submit to activate section order."
  },
  {
    label: DrillingStatus.Draft,
    header: "Section order has curves saved as Draft.",
    text: "Submit to activate section order."
  },
  {
    label: DrillingStatus.Submitted,
    header: "Section has a submitted section order.",
    text: "Section order is still open for change. Submit to update order."
  },
  {
    label: DrillingStatus.Active,
    header: "Section has an ongoing drilling operation.",
    text: "Section order is still open for change. Submit to update order."
  },
  {
    label: DrillingStatus.Completed,
    header: "The section's drilling operation is completed.",
    text: "Section order is closed for change."
  }
];

export const displayFriendlyString = (status: DrillingStatus): string => {
  switch (status) {
    case DrillingStatus.NotApplicable:
      return "Not applicable";
    default:
      return status;
  }
};

export default DrillingStatus;
