import { Icon } from "@equinor/eds-core-react";
import {
  accessible,
  account_circle as accountCircle,
  add,
  check,
  chevron_down as chevronDown,
  clear,
  close,
  copy,
  delete_to_trash as deleteToTrash,
  edit,
  error_filled as errorFilled,
  error_outlined as errorOutlined,
  fullscreen,
  help_outline as helpOutline,
  info_circle as infoCircle,
  lock,
  log_out as logOut,
  mood_very_sad as moodVerySad,
  more_vertical as moreVertical,
  notifications,
  open_in_browser as openInBrowser,
  refresh,
  report_bug as reportError,
  save,
  send,
  unfold_less as unfoldLess,
  unfold_more as unfoldMore
} from "@equinor/eds-icons";

const icons = {
  accountCircle,
  accessible,
  add,
  check,
  chevronDown,
  clear,
  close,
  copy,
  deleteToTrash,
  edit,
  errorFilled,
  errorOutlined,
  fullscreen,
  helpOutline,
  infoCircle,
  lock,
  logOut,
  notifications,
  moodVerySad,
  moreVertical,
  openInBrowser,
  refresh,
  reportError,
  save,
  send,
  unfoldMore,
  unfoldLess
};

Icon.add(icons);

export default Icon;
