import React, { memo, useMemo } from "react";

import { useParamSelector } from "../../../../hooks";
import { selectNumberOfOrderedCurvesForServiceSelectorFactory } from "../../../../state/currentOrder/selectors";
import SelectedCurvesLabel from "./SelectedCurvesLabel";

type Props = {
  logId: string;
  serviceId: string;
};

function ServiceSelectedCurvesLabel({
  logId,
  serviceId
}: Props): React.ReactElement | null {
  const selectNumberOfOrderedCurves = useMemo(
    selectNumberOfOrderedCurvesForServiceSelectorFactory,
    []
  );

  const numberOfOrderedCurves = useParamSelector(
    selectNumberOfOrderedCurves,
    logId,
    serviceId
  );

  if (!numberOfOrderedCurves) {
    return null;
  }

  return <SelectedCurvesLabel numberOfOrderedCurves={numberOfOrderedCurves} />;
}

export default memo(ServiceSelectedCurvesLabel);
