import { Search } from "@equinor/eds-core-react";
import React, { ChangeEvent, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useDebounce, useRequestStatus } from "../../../../hooks";
import {
  setCurveFilter,
  setSelectedLog,
  setSelectedTargetSystem
} from "../../../../state/currentOrder/reducer";
import { selectCurveFilter } from "../../../../state/currentOrder/referenceData/selectors";
import {
  selectLogFilterValues,
  selectSelectedLog,
  selectSelectedTargetSystem,
  selectTargetSystemFilterValues
} from "../../../../state/currentOrder/selectors";
import { RequestType } from "../../../../state/request";
import SelectMenu from "../../../SelectMenu";

function SearchAndFilterBar(): React.ReactElement {
  const dispatch = useDispatch();

  const selectedLog = useSelector(selectSelectedLog);
  const selectedTargetSystem = useSelector(selectSelectedTargetSystem);
  const logs = useSelector(selectLogFilterValues);
  const targetSystems = useSelector(selectTargetSystemFilterValues);
  const defaultFilterValue = useSelector(selectCurveFilter);

  const { isReady, isError } = useRequestStatus(
    RequestType.GetOrder,
    RequestType.GetProviderServiceTools,
    RequestType.GetTargetWellbores,
    RequestType.GetTargetSystems
  );

  const debounceDispatchCurveFilter = useDebounce((filter: string) => {
    if (filter.length >= 2 || filter.length === 0) {
      dispatch(setCurveFilter(filter));
    }
  }, 250);

  return (
    <Container>
      <SelectMenu
        name="Section"
        data={logs}
        onSelect={(log) => dispatch(setSelectedLog(log.id, log.name))}
        disabled={!isReady || isError}
        value={selectedLog.id}
        variant="search"
      />
      <SelectMenu
        name="Curve"
        data={targetSystems}
        onSelect={(targetSystem) =>
          dispatch(setSelectedTargetSystem(targetSystem.id, targetSystem.name))
        }
        disabled={!isReady || isError}
        value={selectedTargetSystem.id}
        variant="search"
      />
      <FilterField
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          debounceDispatchCurveFilter(e.target.value)
        }
        aria-label="sitewide"
        id="curve-filter"
        placeholder="Search for curve"
        disabled={!isReady || isError}
        defaultValue={defaultFilterValue}
      />
    </Container>
  );
}

const Container = styled.div`
  align-items: flex-end;
  display: flex;

  & > :not(:last-child) {
    margin-right: 1rem;
  }
`;

const FilterField = styled(Search)`
  width: 15rem;
`;

export default memo(SearchAndFilterBar);
