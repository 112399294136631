const dateFormat = new Intl.DateTimeFormat("nb-NO", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
});
const dateTimeFormat = new Intl.DateTimeFormat("nb-NO", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit"
});

export const dateFormatter = (
  date: string | Date | undefined,
  options = { dateOnly: false }
): string => {
  if (!date) {
    // Silent return an empty string if the date is undefined
    return "";
  }

  const validatedDate = typeof date === "string" ? new Date(date) : date;

  return options.dateOnly
    ? dateFormat.format(validatedDate)
    : dateTimeFormat.format(validatedDate).replace(",", "");
};
