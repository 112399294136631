import ApiLog from "./api/apiLog";
import ApiLogDepthCurve from "./api/apiLogDepthCurve";

interface LogDepthCurve extends ApiLogDepthCurve {
  logId: string;
}

export function mapApiLogDepthCurves(log: ApiLog): LogDepthCurve[] {
  return log.depthCurves.map((curve) => ({
    ...curve,
    logId: log.id
  }));
}

export default LogDepthCurve;
