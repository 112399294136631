import environment from "../config/environment";
import { getAccessToken } from "../msal/MsalAuthProvider";

export interface RequestInit {
  signal: AbortSignal | null;
}

function get(
  pathName: string,
  abortSignal: AbortSignal | null = null
): Promise<Response> {
  const requestInit: RequestInit = {
    signal: abortSignal
  };

  return runHttpRequest(pathName, requestInit);
}

function put(
  pathName: string,
  body: string,
  abortSignal: AbortSignal | null = null
): Promise<Response> {
  const requestInit = {
    signal: abortSignal,
    method: "PUT",
    body: body
  };

  return runHttpRequest(pathName, requestInit);
}

function post(
  pathName: string,
  body: string,
  abortSignal: AbortSignal | null = null
): Promise<Response> {
  const requestInit = {
    signal: abortSignal,
    method: "POST",
    body: body
  };

  return runHttpRequest(pathName, requestInit);
}

function patch(
  pathName: string,
  body: string,
  abortSignal: AbortSignal | null = null
): Promise<Response> {
  const requestInit = {
    signal: abortSignal,
    method: "PATCH",
    body: body,
    headers: {
      "Content-Type": "application/json-patch+json"
    }
  };

  return runHttpRequest(pathName, requestInit);
}

function remove(
  pathName: string,
  abortSignal: AbortSignal | null = null
): Promise<Response> {
  const requestInit = {
    signal: abortSignal,
    method: "DELETE"
  };

  return runHttpRequest(pathName, requestInit);
}

async function getCommonHeaders() {
  const accessToken = await getAccessToken(
    environment.SCOPE_PARAMETER_RIPS_API
  );

  return {
    "Authorization": `Bearer ${accessToken}`,
    "Content-Type": "application/json"
  };
}

async function runHttpRequest(
  pathName: string,
  requestInit: RequestInit & { headers?: HeadersInit }
) {
  const headers = await getCommonHeaders();
  const options = {
    ...requestInit,
    headers: {
      ...headers,
      ...requestInit.headers
    }
  };

  return new Promise<Response>((resolve, reject) => {
    const url = new URL(`${environment.URL_RIPS_API}${pathName}`);
    fetch(url.href, options)
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        }
        reject(error);
      });
  });
}

export default {
  get,
  put,
  post,
  patch,
  delete: remove
};
