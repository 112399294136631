import { Menu as EdsMenu, Typography } from "@equinor/eds-core-react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useParamSelector } from "../../../../hooks";
import DrillingStatus from "../../../../models/drillingStatus";
import Log from "../../../../models/log";
import TimeStatus from "../../../../models/timeStatus";
import { selectNumberOfClearableCurvesSelectorFactory } from "../../../../state/currentOrder/referenceData/selectors";
import {
  selectIsOrderCompleted,
  selectNumberOfEligibleLogCopyTargetsSelectorFactory
} from "../../../../state/currentOrder/selectors";
import { colors } from "../../../../styles/Colors";
import Icon from "../../../../styles/Icons";

const { Item, Section } = EdsMenu;

type Props = {
  anchor: HTMLElement | null;
  log: Log;
  onCopy: () => void;
  onClear: () => void;
  onAddRun: () => void;
  onRemoveRun: () => void;
  onClose: () => void;
};

const LogAccordionContextMenu = ({
  anchor,
  log,
  onCopy,
  onClear,
  onAddRun,
  onRemoveRun,
  onClose
}: Props): React.ReactElement => {
  const selectNumberOfEligibleCopyTargets = useMemo(
    selectNumberOfEligibleLogCopyTargetsSelectorFactory,
    []
  );
  const selectNumberOfClearableCurves = useMemo(
    selectNumberOfClearableCurvesSelectorFactory,
    []
  );

  const numberOfEligibleTargets = useParamSelector(
    selectNumberOfEligibleCopyTargets,
    log.id
  );
  const numberOfClearableCurves = useParamSelector(
    selectNumberOfClearableCurves,
    log.id
  );
  const isOrderCompleted = useSelector(selectIsOrderCompleted);

  const isOpen = Boolean(anchor);

  return (
    <Menu
      open={isOpen}
      anchorEl={anchor}
      placement="bottom-end"
      onClose={onClose}
    >
      <Section title={"Options for " + log.name} index={0}>
        <Item onClick={onCopy} disabled={numberOfEligibleTargets === 0}>
          <Icon
            name="copy"
            title="copy section"
            size={20}
            color={colors.interactive.primaryResting}
          />
          <Typography group="navigation" variant="menu_title" color="primary">
            Copy to…
          </Typography>
        </Item>
        <Item onClick={onAddRun} disabled={isOrderCompleted}>
          <Icon
            name="add"
            title="add run"
            size={20}
            color={colors.interactive.primaryResting}
          />
          <Typography group="navigation" variant="menu_title" color="primary">
            Add run
          </Typography>
        </Item>
        <Item
          onClick={onClear}
          disabled={
            numberOfClearableCurves === 0 ||
            log.depthStatusSimple === DrillingStatus.Completed ||
            log.depthStatusSimple === DrillingStatus.Active
          }
        >
          <Icon
            name="clear"
            title="clear section"
            size={20}
            color={colors.interactive.primaryResting}
          />
          <Typography group="navigation" variant="menu_title" color="primary">
            Clear this section
          </Typography>
        </Item>
        {log.depthStatus === DrillingStatus.Draft &&
        log.timeStatus === TimeStatus.Draft &&
        !log.hasSpotchecks &&
        log.runNumber > 1 ? (
          <Item onClick={onRemoveRun}>
            <Icon
              name="deleteToTrash"
              title="delete run"
              size={20}
              color={colors.interactive.dangerResting}
            />
            <Typography group="navigation" variant="menu_title" color="danger">
              Delete run
            </Typography>
          </Item>
        ) : (
          <></>
        )}
      </Section>
    </Menu>
  );
};

const Menu = styled(EdsMenu)`
  & > li:first-child > p {
    font-weight: 400;
  }
`;

export default LogAccordionContextMenu;
