import ApiOrder from "./api/apiOrder";

export interface BaseOrder {
  id: string;
  wellboreId: string;
  wellboreName: string;
}

interface Order extends BaseOrder {
  fieldName: string;
  siteComGroupName: string;
  version: number;
  wellAlias: string;
}

export function getEmptyOrder(): Order {
  return {
    id: "",
    fieldName: "",
    wellboreId: "",
    wellboreName: "",
    siteComGroupName: "",
    version: 0,
    wellAlias: ""
  };
}

export function mapApiOrder(order: ApiOrder): Order {
  return {
    id: order.id,
    fieldName: order.fieldName,
    wellboreId: order.wellboreId,
    wellboreName: order.wellboreName,
    siteComGroupName: order.siteComGroupName,
    version: order.version,
    wellAlias: order.wellAlias
  };
}

export default Order;
