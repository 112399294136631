import {
  ConcurrentRequestArg,
  ConcurrentRequestType,
  isConcurrentRequestArg,
  isConcurrentRequestType,
  Request,
  RequestStatus,
  Selector,
  SingleRequestType,
  TRequestType
} from "./types";

export function selectRequest(
  requestType: SingleRequestType
): Selector<Request>;
export function selectRequest(
  requestType: ConcurrentRequestType
): Selector<Record<string, Request>>;
export function selectRequest(
  requestType: ConcurrentRequestType,
  id: string
): Selector<Request>;
export function selectRequest(
  requestType: TRequestType,
  id?: string
): Selector<Request | Record<string, Request>> {
  return (state) => {
    if (isConcurrentRequestType(requestType) && id) {
      return state.request[requestType][id] ?? { status: RequestStatus.Idle };
    }
    return state.request[requestType];
  };
}

export function isRequestLoading(
  requestType: SingleRequestType
): Selector<boolean>;
export function isRequestLoading(
  requestType: ConcurrentRequestType
): Selector<boolean>;
export function isRequestLoading(
  requestType: ConcurrentRequestType,
  id: string
): Selector<boolean>;
export function isRequestLoading(
  requestType: TRequestType,
  id?: string
): Selector<boolean> {
  return (state) => {
    if (isConcurrentRequestType(requestType)) {
      if (id) {
        return (
          (state.request[requestType][id]?.status ?? RequestStatus.Idle) ===
          RequestStatus.InProgress
        );
      }
      return Object.values(state.request[requestType]).some(
        (requestState) => requestState.status === RequestStatus.InProgress
      );
    }
    return state.request[requestType].status === RequestStatus.InProgress;
  };
}

export function areRequestsReady(
  ...requestTypes: (TRequestType | ConcurrentRequestArg)[]
): Selector<boolean> {
  return (state) =>
    requestTypes.every((requestType) => {
      if (isConcurrentRequestArg(requestType)) {
        const { requestType: type, id } = requestType;
        return (
          (state.request[type][id]?.status ?? RequestStatus.Idle) ===
          RequestStatus.Succeeded
        );
      }
      if (isConcurrentRequestType(requestType)) {
        const states = Object.values(state.request[requestType]);
        return (
          states.length > 0 &&
          states.every(
            (requestState) => requestState.status === RequestStatus.Succeeded
          )
        );
      }
      return state.request[requestType].status === RequestStatus.Succeeded;
    });
}

export function anyRequestsWithErrors(
  ...requestTypes: (TRequestType | ConcurrentRequestArg)[]
): Selector<boolean> {
  return (state) =>
    requestTypes.some((requestType) => {
      if (isConcurrentRequestArg(requestType)) {
        const { requestType: type, id } = requestType;
        return (
          (state.request[type][id]?.status ?? RequestStatus.Idle) ===
          RequestStatus.Failed
        );
      }
      if (isConcurrentRequestType(requestType)) {
        return Object.values(state.request[requestType]).some(
          (requestState) => requestState.status === RequestStatus.Failed
        );
      }
      return state.request[requestType].status === RequestStatus.Failed;
    });
}
