import { Icon, Typography } from "@equinor/eds-core-react";
import {
  FormControl as MuiFormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { colors } from "../../styles/Colors";
import {
  SearchVariantAllowEmptyProps,
  SearchVariantNoAllowEmptyProps,
  SelectItem,
  SelectMenuProps,
  TextFieldVariantAllowEmptyProps,
  TextFieldVariantNoAllowEmptyProps,
  Variants
} from "./types";

function SelectMenu<T extends SelectItem>(
  props: SearchVariantNoAllowEmptyProps<T>
): React.ReactElement;

function SelectMenu<T extends SelectItem>(
  props: TextFieldVariantNoAllowEmptyProps<T>
): React.ReactElement;

function SelectMenu<T extends SelectItem>(
  props: SearchVariantAllowEmptyProps<T>
): React.ReactElement;

function SelectMenu<T extends SelectItem>(
  props: TextFieldVariantAllowEmptyProps<T>
): React.ReactElement;

function SelectMenu<T extends SelectItem>({
  label,
  name,
  data,
  onSelect,
  value,
  allowEmpty,
  variant = "search",
  errorText,
  inputRef,
  required,
  ...props
}: SelectMenuProps<T>): React.ReactElement {
  const selectProps = {
    ...props,
    inputRef,
    name: name
  };

  const [selectedItem, setSelectedItem] = useState<T>();

  useEffect(() => {
    if (value) {
      setSelectedItem(findItem(value, data));
    } else {
      setSelectedItem(undefined);
    }
  }, [value, data]);

  const onSelectItem = (event: ChangeEvent<any>) => {
    const value = event.target.value;
    const item = findItem(value, data);

    setSelectedItem(item);
    if (allowEmpty || item) {
      onSelect(item);
    }
  };

  return (
    <FormControl
      $variant={variant}
      $placeholder={!selectedItem}
      $required={required}
      $error={!!errorText}
      $disabled={selectProps.disabled}
      $readOnly={selectProps.readOnly}
    >
      <InputLabel id={`${name}-label`}>{label ?? name}</InputLabel>
      <Select
        {...selectProps}
        labelId={`${name}-label`}
        value={selectedItem?.id ?? ""}
        onChange={onSelectItem}
        renderValue={() =>
          selectedItem?.name ?? `Select ${(label ?? name).toLowerCase()}`
        }
        displayEmpty
      >
        {allowEmpty && (
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
        )}
        {data.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Typography>{item.name}</Typography>
          </MenuItem>
        ))}
      </Select>
      {errorText && (
        <ErrorTextContainer>
          <Icon
            name="errorOutlined"
            color={colors.interactive.dangerHover}
            size={16}
          />
          <ErrorText>{errorText}</ErrorText>
        </ErrorTextContainer>
      )}
    </FormControl>
  );
}

const findItem = <T extends SelectItem>(
  value: string,
  data: T[]
): T | undefined => {
  return data.find((i) => i.id === value) ?? data.find((i) => i.name === value);
};

const ErrorText = styled.p`
  color: ${colors.text.danger};
  font-family: Equinor, Arial, serif;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.013em;
  line-height: 0.75rem;
  margin: 0 0 0 0.5rem;
  text-align: left;
`;

const ErrorTextContainer = styled.div`
  align-items: flex-end;
  display: flex;
  margin-top: 0.5rem;
`;

const FormControl = styled(MuiFormControl)<{
  $variant: Variants;
  $placeholder: boolean;
  $required?: boolean;
  $error?: boolean;
  $disabled?: boolean;
  $readOnly?: boolean;
}>`
  && {
    text-align: inherit;
    width: 13.5rem;
  }

  & > label:first-child {
    font-family: Equinor, Arial, serif;
    padding-left: 0.6875rem;

    &.MuiInputLabel-shrink {
      color: ${colors.text.staticIconsTertiary};
      font-weight: 500;
    }

    &:not(.MuiInputLabel-shrink) {
      color: ${colors.text.staticIconsTertiary};
      font-weight: 500;
      transform: translate(0, 1.5px) scale(0.75);
      transform-origin: top left;
    }

    ${({ $required }) =>
      $required
        ? css`
            &::after {
              color: ${colors.interactive.dangerResting};
              content: " *";
            }
          `
        : ""}
  }

  & > div:nth-child(2) {
    background: ${colors.ui.backgroundLight};
    height: 2.25rem;

    ${({ $variant, $disabled, $readOnly }) =>
      $variant === "search"
        ? css`
            &:hover {
              ${!$disabled &&
              !$readOnly && {
                backgroundColor: colors.ui.backgroundLight,
                outlineOffset: 0,
                outline: `1px solid ${colors.interactive.primaryResting}`
              }}

              &::before,
              &::after {
                border-bottom: unset;
              }
            }

            &:active,
            &:focus-within {
              &::before,
              &::after {
                border-bottom: unset;
              }
            }
          `
        : ""}

    ${({ $variant, $error, $disabled, $readOnly }) =>
      $variant === "text-field"
        ? css`
            outline: 1px solid
              ${$error ? colors.interactive.dangerResting : "transparent"};

            &:hover {
              &::before,
              &::after {
                border-bottom: unset;
              }
            }

            &:active,
            &:focus-within {
              ${!$disabled &&
              !$readOnly && {
                backgroundColor: colors.ui.backgroundLight,
                outlineOffset: 0,
                outline: `2px solid ${
                  $error
                    ? colors.interactive.dangerHover
                    : colors.interactive.primaryResting
                }`
              }}

              &::before,
              &::after {
                border-bottom: unset;
                box-shadow: unset;
              }
            }
          `
        : ""}

    &::before,
    &::after {
      border-bottom: unset;
      ${({ $variant, $error, $disabled, $readOnly }) =>
        $variant === "text-field" &&
        !$error &&
        !$disabled &&
        !$readOnly && {
          boxShadow: `inset 0 -1px 0 0 ${colors.text.staticIconsTertiary}`
        }}
    }

    & > div:first-child {
      background: ${({ $readOnly }) =>
        $readOnly ? colors.ui.backgroundDefault : colors.ui.backgroundLight};

      color: ${({ $placeholder, $disabled }) =>
        $placeholder
          ? colors.text.staticIconsTertiary
          : $disabled
          ? colors.text.disabled
          : colors.text.staticIconsDefault};
      font-family: Equinor, Arial, serif;
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 0.025rem;
      line-height: 1.5rem;
      padding-left: 0.5rem;

      ${({ $readOnly }) =>
        $readOnly && {
          userSelect: "text"
        }}

      &:hover {
        ${({ $readOnly }) => $readOnly && { cursor: "default" }}
        ${({ $disabled }) =>
          $disabled && {
            cursor: "not-allowed"
          }}
      }
    }

    & > svg:last-child {
      ${({ $readOnly }) => $readOnly && { display: "none" }}
    }
  }
`;

export { SelectMenu };
