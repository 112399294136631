import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Dialog, Scrim, Typography } from "@equinor/eds-core-react";
import React from "react";
import styled from "styled-components";

const { Title, CustomContent } = Dialog;

function Unauthorized(): React.ReactElement {
  const { inProgress } = useMsal();

  return (
    <React.Fragment>
      {inProgress === InteractionStatus.None && (
        <Scrim isDismissable={false}>
          <WideDialog>
            <Title>Unauthorized</Title>
            <CustomContent>
              <Typography>
                You are not authorized to access RIPS Order Web.
                <br />
                Please contact Real Time Center:
              </Typography>
              <ul>
                <li>
                  <Typography>
                    Thomas Halland (
                    <a
                      href="mailto:thomash@equinor.com?Subject=RIPS%20Order%20Web%20access%20request"
                      target="_top"
                    >
                      thomash@equinor.com
                    </a>
                    )
                  </Typography>
                </li>
              </ul>
            </CustomContent>
          </WideDialog>
        </Scrim>
      )}
    </React.Fragment>
  );
}

const WideDialog = styled(Dialog)`
  width: 450px;
`;

export default Unauthorized;
