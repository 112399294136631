type AADUser = {
  id: string;
  displayName: string;
  mail?: string;
  mobilePhone?: string;
};

export function isAADUser(value: string | AADUser | null): value is AADUser {
  return (value as AADUser).id !== undefined;
}

export default AADUser;
