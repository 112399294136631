import { Banner as EdsBanner, Icon } from "@equinor/eds-core-react";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useParamSelector } from "../../../../hooks";
import TargetSystem, {
  TargetSystemType
} from "../../../../models/targetSystem";
import {
  selectIsExploration,
  selectTargetSystemSelectorFactory,
  selectTargetWellboresForSystemSelectorFactory
} from "../../../../state/currentOrder/selectors";
import SupportEmail from "../../../SupportEmail";
import TargetWellboreListEntry from "./TargetWellboreListEntry";
import TargetWellboreListSiteComEntry from "./TargetWellboreListSiteComEntry";

const { Icon: BannerIcon, Message: BannerMessage } = EdsBanner;

type Props = {
  targetSystemId: string;
};

function TargetWellboreList({ targetSystemId }: Props): React.ReactElement {
  const selectTargetSystem = useMemo(selectTargetSystemSelectorFactory, []);
  const selectTargetWellboresForSystem = useMemo(
    () => selectTargetWellboresForSystemSelectorFactory(selectTargetSystem),
    [selectTargetSystem]
  );

  const isExploration = useSelector(selectIsExploration);
  const targetSystem = useParamSelector(selectTargetSystem, targetSystemId);
  const targetWellbores = useParamSelector(
    selectTargetWellboresForSystem,
    targetSystemId
  );

  if (isSiteCom(targetSystem)) {
    return (
      <Container>
        <TargetWellboreListSiteComEntry />
        {isExploration && (
          <Banner>
            <BannerIcon>
              <Icon name="infoCircle" />
            </BannerIcon>
            <BannerMessage>
              Can't find the access group you are looking for? Contact{" "}
              <SupportEmail /> for assistance.
            </BannerMessage>
          </Banner>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <TargetWellboreListEntry
        targetSystemId={targetSystemId}
        targetWellbore={targetWellbores[0]}
      />
      {targetWellbores.slice(1).map((targetWellbore) => (
        <TargetWellboreListEntry
          key={targetWellbore.id}
          targetSystemId={targetSystemId}
          targetWellbore={targetWellbore}
        />
      ))}
    </Container>
  );
}

const isSiteCom = (system: TargetSystem) =>
  system.name === TargetSystemType.SiteCom;

const Banner = styled(EdsBanner)`
  grid-column: 1 / 5;
`;

const Container = styled.div`
  display: grid;
  gap: 1rem 0.5rem;
  grid: auto-flow / repeat(2, 1fr) repeat(2, auto);
  place-items: center stretch;
`;

export default memo(TargetWellboreList);
