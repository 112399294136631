import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import { Snack } from "./types";

type SnacksState = Record<string, Snack>;

const initialState = {} as SnacksState;

const snacksSlice = createSlice({
  name: "snacks",
  initialState,
  reducers: {
    addSnack(state, action: PayloadAction<string>) {
      const id = nanoid();
      const message = action.payload;
      state[id] = { id, message };
    },
    removeSnack(state, action: PayloadAction<Snack>) {
      delete state[action.payload.id];
    }
  }
});

export const { addSnack, removeSnack } = snacksSlice.actions;
export default snacksSlice.reducer;
