import { List, Table } from "@equinor/eds-core-react";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useParamSelector } from "../../../../hooks";
import { CurveType } from "../../../../models/curve";
import { selectCurveTableColumnsSelectorFactory } from "../../../../state/currentOrder/selectors";
import { selectTheme } from "../../../../state/settings/selectors";
import { Theme } from "../../../../state/settings/types";
import { colors } from "../../../../styles/Colors";
import Icon from "../../../../styles/Icons";
import Tooltip from "../../../Tooltip";

const { Cell, Head: EdsHead, Row } = Table;
const { Item: ListItem } = List;

type Props = {
  serviceId: string;
};

function CurveTableHeader({ serviceId }: Props): React.ReactElement {
  const selector = useMemo(selectCurveTableColumnsSelectorFactory, []);

  const columns = useParamSelector(selector, serviceId);
  const theme = useSelector(selectTheme);

  return (
    <Head theme={theme}>
      <Row>
        <Cell as="th" scope="col">
          <Curve>Curve</Curve>
        </Cell>
        {columns.map((column) => {
          const hasSystemDescriptions = !!column.systemDescriptions?.length;

          return (
            <Cell
              key={`${column.targetWellboreId}-${column.type}`}
              as="th"
              scope="col"
            >
              <Tooltip
                placement="top-start"
                html={
                  hasSystemDescriptions ? (
                    <TooltipContainer>
                      <List variant="bullet">
                        {column.systemDescriptions?.map((desc, idx) => (
                          <ListItem key={idx}>{desc}</ListItem>
                        ))}
                      </List>
                    </TooltipContainer>
                  ) : undefined
                }
              >
                <Container>
                  <HeaderContainer>
                    <p>
                      {column.name}
                      <span>
                        {column.type === CurveType.Depth ? " (D)" : " (T)"}
                      </span>
                    </p>
                    {hasSystemDescriptions && (
                      <Icon
                        name="infoCircle"
                        size={16}
                        color={colors.interactive.primaryResting}
                      />
                    )}
                  </HeaderContainer>
                  {column.instanceDescription && (
                    <SubHeaderContainer>
                      <p>{column.instanceDescription}</p>
                    </SubHeaderContainer>
                  )}
                </Container>
              </Tooltip>
            </Cell>
          );
        })}
      </Row>
    </Head>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  width: 9rem;

  & > div > p {
    margin: 0;
  }
`;

const Curve = styled.div`
  font-size: 1rem;
  width: 15rem;
`;

const Head = styled(EdsHead)<{ theme: Theme }>`
  position: sticky;
  top: ${(props: any) => (props.theme === Theme.Compact ? "6rem" : "9rem")};
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;

  & > p:first-child > span {
    font-size: 0.75rem;
  }

  & > svg {
    flex: 0 0 auto;
    margin-left: 0.25rem;
  }
`;

const SubHeaderContainer = styled.div`
  overflow: hidden;

  & > p:first-child {
    font-size: 0.7rem;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const TooltipContainer = styled.div`
  width: 30rem;
`;

export default memo(CurveTableHeader);
