import { createSelector, OutputParametricSelector } from "@reduxjs/toolkit";

import Log from "../../models/log";
import LogDepthCurve from "../../models/logDepthCurve";
import LogTimeCurve from "../../models/logTimeCurve";
import TargetSystem from "../../models/targetSystem";
import TargetWellbore from "../../models/targetWellbore";
import { BaseRootState } from "../types";

export const selectTargetWellbores = (state: BaseRootState): TargetWellbore[] =>
  state.currentOrder.targetWellbores;

export const selectLogsUnsorted = (state: BaseRootState): Log[] =>
  state.currentOrder.logs;

export type SelectLogSelector = OutputParametricSelector<
  BaseRootState,
  string,
  Log,
  (res1: Log[], res2: string) => Log
>;
export const selectLogSelectorFactory = (): SelectLogSelector =>
  createSelector(
    selectLogsUnsorted,
    (_: BaseRootState, id: string) => id,
    (logs, id) => logs.find((log) => log.id === id) ?? ({} as Log)
  );

export const selectTargetSystems = (state: BaseRootState): TargetSystem[] =>
  state.currentOrder.referenceData.targetSystems;

export const selectTargetWellboreIdsGroupedByTargetSystem = createSelector(
  selectTargetSystems,
  selectTargetWellbores,
  (targetSystems, targetWellbores) => {
    const systemByProject = {} as Record<string, string>;

    for (const targetSystem of targetSystems) {
      const projects = targetSystem.instances.flatMap(
        (instance) => instance.projects
      );
      projects.forEach(
        (project) => (systemByProject[project.id] = targetSystem.name)
      );
    }

    return targetWellbores.reduce((result, targetWellbore) => {
      const system = systemByProject[targetWellbore.targetProjectId];
      return {
        ...result,
        [system]: [...(result[system] || []), targetWellbore.id]
      };
    }, {} as Record<string, string[]>);
  }
);

export type SelectTargetSystemNameForTargetWellboreSelector =
  OutputParametricSelector<
    BaseRootState,
    string,
    string,
    (res1: Record<string, string[]>, res2: string) => string
  >;
export const selectTargetSystemNameForTargetWellboreSelectorFactory =
  (): SelectTargetSystemNameForTargetWellboreSelector =>
    createSelector(
      selectTargetWellboreIdsGroupedByTargetSystem,
      (_: BaseRootState, __: string, ___: string, targetWellboreId: string) =>
        targetWellboreId,
      (idsBySystem, targetWellboreId) => {
        for (const [system, ids] of Object.entries(idsBySystem)) {
          for (const id of ids) {
            if (id === targetWellboreId) {
              return system;
            }
          }
        }
        return "";
      }
    );

export const selectAllLogTimeCurves = (state: BaseRootState): LogTimeCurve[] =>
  state.currentOrder.logTimeCurves;

export const selectAllLogDepthCurves = (
  state: BaseRootState
): LogDepthCurve[] => state.currentOrder.logDepthCurves;

export type SelectLogTimeCurvesSelector = OutputParametricSelector<
  BaseRootState,
  string,
  LogTimeCurve[],
  (res1: LogTimeCurve[], res2: string) => LogTimeCurve[]
>;
export const selectLogTimeCurvesSelectorFactory =
  (): SelectLogTimeCurvesSelector =>
    createSelector(
      selectAllLogTimeCurves,
      (_: BaseRootState, logId: string) => logId,
      (curves, logId) => curves.filter((curve) => curve.logId === logId)
    );

export type SelectLogDepthCurvesSelector = OutputParametricSelector<
  BaseRootState,
  string,
  LogDepthCurve[],
  (res1: LogDepthCurve[], res2: string) => LogDepthCurve[]
>;
export const selectLogDepthCurvesSelectorFactory =
  (): SelectLogDepthCurvesSelector =>
    createSelector(
      selectAllLogDepthCurves,
      (_: BaseRootState, logId: string) => logId,
      (curves, logId) => curves.filter((curve) => curve.logId === logId)
    );
