import { Typography } from "@equinor/eds-core-react";
import React, { memo } from "react";
import styled from "styled-components";

import bannerImage from "../../images/Equinor_Statoil_Sverdrup.jpg";
import { colors } from "../../styles/Colors";

type Props = {
  visible: boolean;
};

function AppBanner({ visible }: Props): React.ReactElement | null {
  if (!visible) {
    return null;
  }

  return (
    <Banner>
      <BannerHeader color={colors.text.staticIconsPrimaryWhite}>
        RIPS Order Web
      </BannerHeader>
    </Banner>
  );
}

const Banner = styled.div`
  align-items: center;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), 100%, transparent),
    url(${bannerImage});
  background-size: cover;
  display: flex;
  flex: 0 0 20vh;
  flex-direction: column;
  justify-content: center;
`;

const BannerHeader = styled(Typography)`
  font-size: 3rem;
`;

export default memo(AppBanner);
