import { Progress } from "@equinor/eds-core-react";
import React from "react";
import styled from "styled-components";

const Spinner = styled(Progress.Star)`
  margin-left: 50%;
  margin-top: 25%;
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  padding: 0 2rem 3rem;
`;

function Loading(): React.ReactElement {
  return (
    <Container>
      <Spinner />
    </Container>
  );
}

export default Loading;
