import { createSelector, OutputParametricSelector } from "@reduxjs/toolkit";

import { CurveChange } from "../../../services/orderService";
import { BaseRootState } from "../../types";
import { LogCurvePayload, RemoveLogCurvePayload } from "../types";

const selectCurveDiff = (state: BaseRootState) => state.currentOrder.curveDiff;

export const selectIsCurveDiff = createSelector(
  selectCurveDiff,
  (diff) => diff.added.length > 0 || diff.removed.length > 0
);

export const selectLogIdsWithCurveDiff = createSelector(
  selectCurveDiff,
  (diff) => [
    ...new Set(diff.added.concat(diff.removed).map((curve) => curve.logId))
  ]
);

const selectAddedCurvesForLogSelectorFactory = () =>
  createSelector(
    (state: BaseRootState) => state.currentOrder.curveDiff.added,
    (_: BaseRootState, logId: string) => logId,
    (addedCurves, logId) => addedCurves.filter((curve) => curve.logId == logId)
  );

const selectRemovedCurvesForLogSelectorFactory = () =>
  createSelector(
    (state: BaseRootState) => state.currentOrder.curveDiff.removed,
    (_: BaseRootState, logId: string) => logId,
    (removedCurves, logId) =>
      removedCurves.filter((curve) => curve.logId == logId)
  );

type SelectCurveChangesForLogSelectorFactory = OutputParametricSelector<
  BaseRootState,
  string,
  CurveChange[],
  (res1: LogCurvePayload[], res2: RemoveLogCurvePayload[]) => CurveChange[]
>;
export const selectCurveChangesForLogSelectorFactory =
  (): SelectCurveChangesForLogSelectorFactory => {
    const selectAddedCurves = selectAddedCurvesForLogSelectorFactory();
    const selectRemovedCurves = selectRemovedCurvesForLogSelectorFactory();
    return createSelector(
      selectAddedCurves,
      selectRemovedCurves,
      (addedCurves, removedCurves) =>
        addedCurves
          .map(
            (curve) =>
              ({
                op: "add",
                value: {
                  [`${curve.curveType.toLowerCase()}CurveId`]: curve.curveId,
                  targetWellboreId: curve.targetWellboreId
                }
              } as CurveChange)
          )
          .concat(
            removedCurves.map(
              (curve) =>
                ({
                  op: "remove",
                  value: {
                    [`${curve.curveType.toLowerCase()}CurveId`]: curve.curveId,
                    targetWellboreId: curve.targetWellboreId,
                    id: curve.id
                  }
                } as CurveChange)
            )
          )
    );
  };
