import { Menu, Typography } from "@equinor/eds-core-react";
import React from "react";

import { getAccountInfo, signOut } from "../../msal/MsalAuthProvider";

const { Item: MenuItem } = Menu;

type Props = {
  anchor: HTMLElement | null;
  onClose: () => void;
};

function UserContextMenu({ anchor, onClose }: Props): React.ReactElement {
  const isOpen = Boolean(anchor);
  const user = getAccountInfo()?.name;
  return (
    <Menu open={isOpen} anchorEl={anchor} placement="bottom" onClose={onClose}>
      <MenuItem>
        <Typography group="navigation" variant="menu_title">
          {user}
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => signOut()}>
        <Typography group="navigation" variant="menu_title">
          Logout
        </Typography>
      </MenuItem>
    </Menu>
  );
}

export default UserContextMenu;
