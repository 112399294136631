import {
  combineReducers,
  createSlice,
  PayloadAction,
  Reducer
} from "@reduxjs/toolkit";
import reduceReducers from "reduce-reducers";

import ApiLog from "../../models/api/apiLog";
import ApiOrder from "../../models/api/apiOrder";
import Contact from "../../models/contact";
import { CurveType } from "../../models/curve";
import Log, { mapApiLog } from "../../models/log";
import LogDepthCurve, {
  mapApiLogDepthCurves
} from "../../models/logDepthCurve";
import LogTimeCurve, { mapApiLogTimeCurves } from "../../models/logTimeCurve";
import Order, { getEmptyOrder, mapApiOrder } from "../../models/order";
import SiteComGroup from "../../models/siteComGroup";
import TargetWellbore from "../../models/targetWellbore";
import accordionReducer, { AccordionState } from "./accordionState/reducer";
import { addCurve, removeCurve, removeLog, updateLog } from "./actions";
import azureAdReducer, { AzureAdState } from "./azureAd/reducer";
import curveDiffReducer, { CurveDiffState } from "./curveDiff/reducer";
import referenceDataReducer, {
  ReferenceDataState
} from "./referenceData/reducer";
import {
  LogFilterValue,
  LogFilterValueDrillingSections,
  TargetSystemAllValue,
  TargetSystemFilterValue
} from "./types";

export interface CurrentOrderState {
  order: Order;
  logs: Log[];
  contacts: Contact[];
  logDepthCurves: LogDepthCurve[];
  logTimeCurves: LogTimeCurve[];
  targetWellbores: TargetWellbore[];
  selectedLog: LogFilterValue;
  selectedTargetSystem: TargetSystemFilterValue;
  curveFilter: string;
  activeTab: number;
  referenceData: ReferenceDataState;
  curveDiff: CurveDiffState;
  azureAd: AzureAdState;
  accordionState: AccordionState;
}

const initialState = {
  order: getEmptyOrder(),
  targetWellbores: [] as TargetWellbore[],
  selectedLog: LogFilterValueDrillingSections,
  selectedTargetSystem: TargetSystemAllValue,
  curveFilter: "",
  logs: [] as Log[],
  contacts: [] as Contact[],
  logDepthCurves: [] as LogDepthCurve[],
  logTimeCurves: [] as LogTimeCurve[],
  activeTab: NaN
} as CurrentOrderState;

const currentOrderSlice = createSlice({
  name: "currentOrder",
  initialState,
  reducers: {
    setOrder(state, { payload: order }: PayloadAction<ApiOrder>) {
      state.order = mapApiOrder(order);
      state.contacts = order.contacts;
      state.logs = order.logs.map((log) => mapApiLog(log));
      state.logTimeCurves = order.logs.flatMap((log) =>
        mapApiLogTimeCurves(log)
      );
      state.logDepthCurves = order.logs.flatMap((log) =>
        mapApiLogDepthCurves(log)
      );
    },
    updateOrderSiteComGroup(
      state,
      { payload: { name } }: PayloadAction<SiteComGroup>
    ) {
      state.order.siteComGroupName = name;
    },
    setTargetWellbores(state, action: PayloadAction<TargetWellbore[]>) {
      state.targetWellbores = action.payload;
    },
    addOrUpdateTargetWellbore(
      state,
      { payload: targetWellbore }: PayloadAction<TargetWellbore>
    ) {
      const idx = state.targetWellbores.findIndex(
        (tw) => tw.id === targetWellbore.id
      );
      if (~idx) {
        state.targetWellbores.splice(idx, 1, targetWellbore);
      } else {
        state.targetWellbores.push(targetWellbore);
      }
    },
    addContact(state, action: PayloadAction<Contact>) {
      state.contacts.push(action.payload);
    },
    updateContact(state, action: PayloadAction<Contact>) {
      const updatedContact = action.payload;
      const idx = state.contacts.findIndex(
        (contact) => updatedContact.id === contact.id
      );
      if (~idx) {
        state.contacts.splice(idx, 1, updatedContact);
      }
    },
    removeContact(state, action: PayloadAction<string>) {
      const idx = state.contacts.findIndex(
        (contact) => action.payload === contact.id
      );
      if (~idx) {
        state.contacts.splice(idx, 1);
      }
    },
    setSelectedLog: {
      reducer: (state, action: PayloadAction<LogFilterValue>) => {
        state.selectedLog = action.payload;
      },
      prepare: (id: string, name: string) => {
        return {
          payload: { id, name }
        };
      }
    },
    setSelectedTargetSystem: {
      reducer: (state, action: PayloadAction<TargetSystemFilterValue>) => {
        state.selectedTargetSystem = action.payload;
      },
      prepare: (id: string, name: string) => {
        return {
          payload: { id, name }
        };
      }
    },
    setCurveFilter(state, action: PayloadAction<string>) {
      state.curveFilter = action.payload.toLowerCase();
    },
    setActiveTab(state, action: PayloadAction<number>) {
      state.activeTab = action.payload;
    },
    addLog(state, { payload: log }: PayloadAction<ApiLog>) {
      state.logs.push(mapApiLog(log));
      state.logTimeCurves.concat(mapApiLogTimeCurves(log));
      state.logDepthCurves.concat(mapApiLogDepthCurves(log));
    },
    clearState() {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCurve, (state, action) => {
        const { logId, targetWellboreId, curveId, curveType } = action.payload;

        if (curveType === CurveType.Depth) {
          state.logDepthCurves.push({
            id: "",
            logId: logId,
            depthCurveId: curveId,
            targetWellboreId: targetWellboreId
          });
        } else if (curveType === CurveType.Time) {
          state.logTimeCurves.push({
            id: "",
            logId: logId,
            timeCurveId: curveId,
            targetWellboreId: targetWellboreId
          });
        }
      })
      .addCase(removeCurve, (state, action) => {
        const { id, logId, targetWellboreId, curveId, curveType } =
          action.payload;

        if (curveType === CurveType.Depth) {
          const idx = state.logDepthCurves.findIndex(
            (curve) =>
              curve.id === id &&
              curve.logId === logId &&
              curve.depthCurveId === curveId &&
              curve.targetWellboreId === targetWellboreId
          );
          if (~idx) {
            state.logDepthCurves.splice(idx, 1);
          }
        } else if (curveType === CurveType.Time) {
          const idx = state.logTimeCurves.findIndex(
            (curve) =>
              curve.id === id &&
              curve.logId === logId &&
              curve.timeCurveId === curveId &&
              curve.targetWellboreId === targetWellboreId
          );
          if (~idx) {
            state.logTimeCurves.splice(idx, 1);
          }
        }
      })
      .addCase(removeLog, (state, { payload: logId }) => {
        const idx = state.logs.findIndex((log) => log.id === logId);

        if (~idx) {
          state.logs.splice(idx, 1);

          state.logTimeCurves = state.logTimeCurves.filter(
            (curve) => curve.logId !== logId
          );
          state.logDepthCurves = state.logDepthCurves.filter(
            (curve) => curve.logId !== logId
          );
        }
      })
      .addCase(updateLog, (state, { payload: apiLog }) => {
        const { id: logId } = apiLog;
        const idx = state.logs.findIndex((log) => log.id === logId);

        if (~idx) {
          const log = mapApiLog(apiLog);
          const logTimeCurves = mapApiLogTimeCurves(apiLog);
          const logDepthCurves = mapApiLogDepthCurves(apiLog);

          state.logs.splice(idx, 1, log);
          state.logTimeCurves = state.logTimeCurves
            .filter((curve) => curve.logId !== logId)
            .concat(logTimeCurves);
          state.logDepthCurves = state.logDepthCurves
            .filter((curve) => curve.logId !== logId)
            .concat(logDepthCurves);
        }
      });
  }
});

const nestedEntitiesReducer = combineReducers({
  order: ((s = initialState.order) => s) as Reducer<Order>,
  logs: ((s = initialState.logs) => s) as Reducer<Log[]>,
  contacts: ((s = initialState.contacts) => s) as Reducer<Contact[]>,
  logDepthCurves: ((s = initialState.logDepthCurves) => s) as Reducer<
    LogDepthCurve[]
  >,
  logTimeCurves: ((s = initialState.logTimeCurves) => s) as Reducer<
    LogTimeCurve[]
  >,
  targetWellbores: ((s = initialState.targetWellbores) => s) as Reducer<
    TargetWellbore[]
  >,
  selectedLog: ((s = initialState.selectedLog) => s) as Reducer<LogFilterValue>,
  selectedTargetSystem: ((s = initialState.selectedTargetSystem) =>
    s) as Reducer<TargetSystemFilterValue>,
  curveFilter: ((s = initialState.curveFilter) => s) as Reducer<string>,
  activeTab: ((s = initialState.activeTab) => s) as Reducer<number>,
  referenceData: referenceDataReducer,
  curveDiff: curveDiffReducer,
  azureAd: azureAdReducer,
  accordionState: accordionReducer
});

const reducer = reduceReducers<CurrentOrderState>(
  currentOrderSlice.reducer,
  nestedEntitiesReducer
) as Reducer<CurrentOrderState>;

export * from "./accordionState/reducer";
export * from "./azureAd/reducer";
export * from "./curveDiff/reducer";
export * from "./referenceData/reducer";
export * from "./actions";
export const {
  setOrder,
  updateOrderSiteComGroup,
  setTargetWellbores,
  addOrUpdateTargetWellbore,
  addContact,
  removeContact,
  setSelectedLog,
  setSelectedTargetSystem,
  setCurveFilter,
  updateContact,
  setActiveTab,
  addLog,
  clearState
} = currentOrderSlice.actions;
export default reducer;
