import {
  Button as EdsButton,
  CircularProgress,
  Icon
} from "@equinor/eds-core-react";
import { nanoid } from "@reduxjs/toolkit";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useParamSelector, useRequest } from "../../../../hooks";
import TargetWellbore from "../../../../models/targetWellbore";
import { selectTargetSystemProjectsWithInstanceNameSelectorFactory } from "../../../../state/currentOrder/referenceData/selectors";
import {
  selectIsOrderCompleted,
  selectWellboreId,
  selectWellboreName
} from "../../../../state/currentOrder/selectors";
import { RequestType } from "../../../../state/request";
import { addSnack } from "../../../../state/snacks/reducer";
import { colors } from "../../../../styles/Colors";
import { SelectMenu } from "../../../SelectMenu/SelectMenu";
import { TextField } from "../../../TextField";
import Tooltip from "../../../Tooltip";

type Props = {
  targetSystemId: string;
  targetWellbore?: TargetWellbore;
};

const TargetWellboreListEntry = ({
  targetSystemId,
  targetWellbore
}: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const selectTargetProjects = useMemo(
    selectTargetSystemProjectsWithInstanceNameSelectorFactory,
    []
  );

  const isOrderCompleted = useSelector(selectIsOrderCompleted);
  const wellboreId = useSelector(selectWellboreId);
  const wellboreName = useSelector(selectWellboreName);
  const availableTargetProjects = useParamSelector(
    selectTargetProjects,
    targetSystemId
  );

  const [initialTargetWellbore] = useState(targetWellbore);
  const [requestId] = useState(targetWellbore?.id ?? nanoid());

  const {
    handleSubmit,
    control,
    setFocus,
    setError,
    watch,
    getValues,
    reset,
    formState: { isDirty, dirtyFields, submitCount }
  } = useForm({
    defaultValues: {
      id: targetWellbore?.id,
      targetProjectId: targetWellbore?.targetProjectId ?? "",
      name: targetWellbore?.name ?? wellboreName
    },
    criteriaMode: "all"
  });

  const isUpdateOperation = initialTargetWellbore || submitCount > 1;
  const errorMessage = `The target wellbore could not be ${
    isUpdateOperation ? "updated" : "created"
  }, please try again`;

  const {
    invokerFunction: onSubmit,
    isLoading,
    isError,
    isSuccess
  } = useRequest({
    requestType: RequestType.CreateOrUpdateTargetWellbore,
    requestId,
    successCallback: () => {
      reset(getValues(), { keepSubmitCount: true });
      dispatch(
        addSnack(
          `The target wellbore was successfully ${
            isUpdateOperation ? "updated" : "created"
          }`
        )
      );
    },
    errorCallback: () => dispatch(addSnack(errorMessage)),
    payloadGenerator: {
      func: (model) => {
        const { id, ...data } = model;
        if (id) {
          const payload = {
            id,
            targetProjectId: dirtyFields.targetProjectId
              ? data.targetProjectId
              : undefined,
            name: dirtyFields.name ? data.name : undefined
          };
          return [wellboreId, payload];
        }
        return [wellboreId, data];
      },
      deps: [wellboreId, dirtyFields]
    },
    setError
  });

  useEffect(() => {
    if (targetWellbore) {
      reset(
        {
          id: targetWellbore.id,
          targetProjectId: targetWellbore.targetProjectId,
          name: targetWellbore.name
        },
        { keepSubmitCount: true }
      );
    }
  }, [targetWellbore, reset]);

  const selectedTargetProjectId = watch("targetProjectId");
  const isReadOnly =
    targetWellbore &&
    (!targetWellbore.isActive ||
      !!targetWellbore.uidWell ||
      !!targetWellbore.uidWellbore);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="targetProjectId"
        control={control}
        rules={{ required: "This field is required" }}
        render={({
          field: { ref, onChange, ...props },
          fieldState: { error }
        }) => (
          <SelectMenu
            {...props}
            label="Project"
            inputRef={ref}
            onSelect={(tp) => {
              onChange(tp.id);
              requestAnimationFrame(() => setFocus("name"));
            }}
            data={availableTargetProjects}
            disabled={isLoading || (!isReadOnly && isOrderCompleted)}
            readOnly={isReadOnly}
            errorText={error?.message}
            variant="text-field"
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        rules={{ required: "This field is required" }}
        render={({
          field: { ref, ...props },
          fieldState: { invalid, error }
        }) => (
          <TextField
            {...props}
            label="Wellbore"
            inputRef={ref}
            disabled={
              !selectedTargetProjectId ||
              isLoading ||
              (!isReadOnly && isOrderCompleted)
            }
            readOnly={isReadOnly}
            helperText={error?.message}
            helperIcon={errorIcon}
            variant={invalid ? "error" : "default"}
          />
        )}
      />
      {!isReadOnly && (
        <Tooltip
          title={
            isOrderCompleted
              ? "Changes cannot be made to the order after completion"
              : ""
          }
        >
          <Button
            type="submit"
            variant="ghost"
            disabled={!isDirty || isLoading || isOrderCompleted}
          >
            <Icon name="save" size={20} />
          </Button>
        </Tooltip>
      )}
      <IconContainer>
        {isLoading && <Spinner />}
        {isSuccess && !isDirty && (
          <Icon
            name="check"
            size={20}
            color={colors.interactive.successResting}
          />
        )}
        {isError && (
          <Tooltip title={errorMessage}>
            <Icon
              name="errorOutlined"
              size={20}
              color={colors.interactive.dangerResting}
            />
          </Tooltip>
        )}
        {isReadOnly && (
          <Tooltip title="The target wellbore is linked and cannot be changed">
            <Icon
              name="lock"
              size={20}
              color={colors.interactive.primaryResting}
            />
          </Tooltip>
        )}
      </IconContainer>
    </Form>
  );
};

const errorIcon = <Icon name="errorOutlined" />;

const Button = styled(EdsButton)`
  &,
  &:disabled {
    margin-top: 1rem;
  }

  & + div {
    grid-column: -1 / -2;
  }
`;

const Form = styled.form`
  display: contents;

  &&& > div {
    &:first-of-type {
      min-width: 6.25rem;
      padding-right: 0.25rem;
      width: 100%;
    }

    &:nth-of-type(2) {
      padding-left: 0.25rem;
    }
  }
`;

const IconContainer = styled.div`
  grid-column: -1 / -3;
  margin-left: auto;
  margin-top: 1rem;
  width: 1.25rem;
`;

const Spinner = styled(CircularProgress)`
  height: auto;
  width: 1rem;
`;

export default TargetWellboreListEntry;
