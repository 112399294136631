import { EdsProvider, Table as EDSTable } from "@equinor/eds-core-react";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useParamSelector } from "../../../../hooks";
import { selectCurveIdsGroupedByNameForToolSelectorFactory } from "../../../../state/currentOrder/selectors";
import { selectTheme } from "../../../../state/settings/selectors";
import CurveTableHeader from "./CurveTableHeader";
import CurveTableRow from "./CurveTableRow";

const { Body } = EDSTable;

type Props = {
  logId: string;
  serviceId: string;
  toolId: string;
};

function CurveTable({ logId, serviceId, toolId }: Props): React.ReactElement {
  const selector = useMemo(
    selectCurveIdsGroupedByNameForToolSelectorFactory,
    []
  );

  const curveIdsByName = useParamSelector(selector, logId, toolId);
  const theme = useSelector(selectTheme);

  return (
    <EdsProvider density={theme}>
      <Table>
        <CurveTableHeader serviceId={serviceId} />
        <Body>
          {Object.values(curveIdsByName).map((curve) => (
            <CurveTableRow
              key={curve.name}
              logId={logId}
              serviceId={serviceId}
              curveIds={curve.ids}
            />
          ))}
        </Body>
      </Table>
    </EdsProvider>
  );
}

const Table = styled(EDSTable)`
  min-width: 100%;
`;

export default memo(CurveTable);
