import { Table } from "@equinor/eds-core-react";
import React from "react";
import styled from "styled-components";

import { drillingStatusDescriptions } from "../../../../models/drillingStatus";
import {
  saveAndSubmitStatus,
  saveDraftStatus
} from "../../../../models/saveSubmitStatus";
import { colors } from "../../../../styles/Colors";
import Icon from "../../../../styles/Icons";
import Tooltip from "../../../Tooltip";
import SaveAndSubmitStatusTableRow from "./SaveAndSubmitStatusTableRow";

const { Body, Cell, Head, Row: EdsRow } = Table;

function SaveAndSubmitTableHeader(): React.ReactElement {
  return (
    <Head sticky>
      <Row>
        <Cell>Section</Cell>
        <Cell>Date</Cell>
        <Cell>
          <Tooltip
            placement="top-start"
            html={
              <TooltipContainer>
                <Table>
                  <Body>
                    {drillingStatusDescriptions.map((item) => (
                      <SaveAndSubmitStatusTableRow
                        key={item.label}
                        drillingStatusLabel={item.label}
                        header={item.header}
                        text={item.text}
                      />
                    ))}
                  </Body>
                </Table>
              </TooltipContainer>
            }
          >
            <CellInner>
              Status
              <InfoIcon name="infoCircle" size={20} />
            </CellInner>
          </Tooltip>
        </Cell>
        <Cell>
          <Tooltip
            placement="top-start"
            html={
              <TooltipContainer>
                <Table>
                  <Body>
                    {saveDraftStatus.map((item) => (
                      <SaveAndSubmitStatusTableRow
                        key={item.label}
                        label={item.label}
                        header={item.header}
                        text={item.text}
                      />
                    ))}
                  </Body>
                </Table>
              </TooltipContainer>
            }
          >
            <CellInner>
              Save draft
              <InfoIcon name="infoCircle" size={20} />
            </CellInner>
          </Tooltip>
        </Cell>
        <Cell>
          <Tooltip
            placement="top-start"
            html={
              <TooltipContainer>
                <Table>
                  <Body>
                    {saveAndSubmitStatus.map((item) => (
                      <SaveAndSubmitStatusTableRow
                        key={item.label}
                        label={item.label}
                        header={item.header}
                        text={item.text}
                      />
                    ))}
                  </Body>
                </Table>
              </TooltipContainer>
            }
          >
            <CellInner>
              Save and submit
              <InfoIcon name="infoCircle" size={20} />
            </CellInner>
          </Tooltip>
        </Cell>
      </Row>
    </Head>
  );
}

const TooltipContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const Row = styled(EdsRow)`
  & > th {
    z-index: 2;
  }
`;

const CellInner = styled.div`
  align-items: center;
  display: flex;
`;

const InfoIcon = styled(Icon)`
  color: ${colors.interactive.primaryResting};
  margin-left: 0.25rem;
`;

export default SaveAndSubmitTableHeader;
