import { createSelector, OutputParametricSelector } from "@reduxjs/toolkit";

import Field from "../../models/field";
import { BaseOrder } from "../../models/order";
import OrderStatus from "../../models/orderStatus";
import OverviewLog from "../../models/overviewLog";
import Wellbore from "../../models/wellbore";
import {
  by,
  withCollatorBy,
  withCollatorFirstByAndThenBy,
  withNumericCollatorBy
} from "../../utils/SortHelpers";
import { BaseRootState } from "../types";
import { LogType } from "./types";

export const selectFieldsUnsorted = (state: BaseRootState): Field[] =>
  state.overview.fields;

export const selectFields = createSelector(selectFieldsUnsorted, (fields) =>
  [...fields].sort(withCollatorBy((field) => field.name))
);

export const selectLogsUnsorted = (state: BaseRootState): OverviewLog[] =>
  state.overview.logs;

export const selectSelectedLogType = (state: BaseRootState): LogType =>
  state.overview.selectedLogType;

export const selectSelectedField = (state: BaseRootState): string | undefined =>
  state.overview.selectedField;

export const selectWellboreFilter = (
  state: BaseRootState
): string | undefined => state.overview.wellboreFilter;

export const selectLogs = createSelector(
  selectLogsUnsorted,
  selectSelectedLogType,
  selectWellboreFilter,
  (logs, logType, wellboreFilter) => {
    const ascending = logType === OrderStatus.Ongoing;
    return logs
      .filter(
        (log) =>
          !wellboreFilter ||
          log.wellboreName.toLowerCase().includes(wellboreFilter)
      )
      .sort(by((log) => new Date(log.startTime), { ascending }));
  }
);

export const selectLogIds = createSelector(selectLogs, (logs) =>
  logs.map((log) => log.id)
);

export const selectLogsWithoutOrder = createSelector(
  selectLogsUnsorted,
  (logs) => logs.filter((log) => !log.orderId)
);

export const selectFieldsAndWellboresWithoutOrder = createSelector(
  selectLogsWithoutOrder,
  (logs) =>
    logs
      .filter(
        (log, idx) =>
          logs.findIndex((l) => l.wellboreId === log.wellboreId) === idx
      )
      .sort(
        withCollatorFirstByAndThenBy(
          (log) => log.fieldName,
          (log) => log.wellboreName
        )
      )
      .reduce(
        (result, log) => ({
          ...result,
          [log.fieldName]: [
            ...(result[log.fieldName] || []),
            {
              id: log.wellboreId,
              name: log.wellboreName
            }
          ]
        }),
        {} as Record<string, Wellbore[]>
      )
);

export const selectNumberOfWellboresWithoutOrder = createSelector(
  selectFieldsAndWellboresWithoutOrder,
  (wellboresByField) =>
    Object.values(wellboresByField).reduce(
      (count, wellbores) => count + wellbores.length,
      0
    )
);

export const selectIsWellboresWithoutOrder = createSelector(
  selectNumberOfWellboresWithoutOrder,
  (wellboresWithoutOrder) => wellboresWithoutOrder > 0
);

export const selectLogsWithOrder = createSelector(selectLogsUnsorted, (logs) =>
  logs.filter((log) => log.orderId)
);

type SelectLogSelector = OutputParametricSelector<
  BaseRootState,
  string,
  OverviewLog,
  (res1: OverviewLog[], res2: string) => OverviewLog
>;
export const selectLogSelectorFactory = (): SelectLogSelector =>
  createSelector(
    selectLogsUnsorted,
    (_: BaseRootState, id: string) => id,
    (logs, id) => logs.find((log) => log.id === id) ?? ({} as OverviewLog)
  );

export const selectOrdersGroupedByField = createSelector(
  (state: BaseRootState) => state.overview.ordersByField,
  (ordersByField) =>
    Object.entries(ordersByField).reduce(
      (result, [fieldName, orders]) => ({
        ...result,
        [fieldName]: [...orders].sort(
          withNumericCollatorBy((x) => x.wellboreName)
        )
      }),
      {} as Record<string, BaseOrder[]>
    )
);

// Returns a collection where the id is the order id and the name is the wellbore name connected to the order.
// Each collection is grouped by the field name connected to the wellbore.
export const selectFieldsAndWellboresWithOrder = createSelector(
  selectOrdersGroupedByField,
  (ordersByField) =>
    Object.entries(ordersByField).reduce(
      (result, [fieldName, orders]) => ({
        ...result,
        [fieldName]: orders.map((order) => ({
          id: order.id,
          name: order.wellboreName
        }))
      }),
      {} as Record<string, { id: string; name: string }[]>
    )
);
