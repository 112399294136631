import { Menu, Typography } from "@equinor/eds-core-react";
import React from "react";
import { useSelector } from "react-redux";

import { selectIsOrderCompleted } from "../../../../state/currentOrder/selectors";
import { colors } from "../../../../styles/Colors";
import Icon from "../../../../styles/Icons";

const { Item: MenuItem } = Menu;

type Props = {
  anchor: EventTarget | null;
  onEdit: () => void;
  onClose: () => void;
  onDelete: () => void;
};

function ContactContextMenu({
  anchor,
  onEdit,
  onClose,
  onDelete
}: Props): React.ReactElement {
  const isOpen = Boolean(anchor);

  const isOrderCompleted = useSelector(selectIsOrderCompleted);

  return (
    <Menu
      open={isOpen}
      anchorEl={anchor}
      placement="left-start"
      onClose={onClose}
    >
      <MenuItem onClick={onEdit} disabled={isOrderCompleted}>
        <Icon
          name="edit"
          title="edit"
          size={20}
          color={colors.interactive.primaryResting}
        />
        <Typography group="navigation" variant="menu_title" color="primary">
          Edit
        </Typography>
      </MenuItem>
      <MenuItem onClick={onDelete} disabled={isOrderCompleted}>
        <Icon
          name="deleteToTrash"
          title="delete"
          size={20}
          color={colors.interactive.dangerResting}
        />
        <Typography group="navigation" variant="menu_title" color="danger">
          Delete
        </Typography>
      </MenuItem>
    </Menu>
  );
}

export default ContactContextMenu;
