import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

import {
  anyRequestsWithErrors,
  areRequestsReady,
  ConcurrentRequestType,
  TRequestType
} from "../state/request";

type RequestTypeWithId = {
  requestType: ConcurrentRequestType;
  id: string;
};
type RequestStatus = {
  isReady: boolean;
  isError: boolean;
};

export const useRequestStatus = (
  ...requestTypes: (TRequestType | RequestTypeWithId)[]
): RequestStatus => {
  const isReady = useSelector(areRequestsReady(...requestTypes), shallowEqual);
  const isError = useSelector(
    anyRequestsWithErrors(...requestTypes),
    shallowEqual
  );

  return useMemo(
    () => ({
      isReady,
      isError
    }),
    [isReady, isError]
  );
};
