import { Button, Typography } from "@equinor/eds-core-react";
import React from "react";
import styled from "styled-components";

import { ReactComponent as ErrorImage } from "../../images/errorImage.svg";
import Icon from "../../styles/Icons";
import SupportEmail from "../SupportEmail";

function GenericErrorPanel(): React.ReactElement {
  return (
    <Container>
      <ErrorImage />
      <TextContainer>Oh, this was not supposed to happen…</TextContainer>
      <Typography variant="body_short">
        If the problem persists, contact <SupportEmail /> for assistance.
      </Typography>
      <Button
        variant="ghost"
        color="secondary"
        onClick={() => location.reload()}
      >
        <Icon name="refresh" />
        Reload to try again
        <div />
      </Button>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  overflow: hidden;

  & > p {
    margin: 0.5rem 0 2rem;
  }
`;

const TextContainer = styled.span`
  font-size: 2rem;
  font-weight: bold;
`;

export default GenericErrorPanel;
