import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Field from "../../models/field";
import { BaseOrder } from "../../models/order";
import OrderStatus from "../../models/orderStatus";
import OverviewLog from "../../models/overviewLog";
import { logDepthStatusSimplified } from "../../utils/dataConverter";
import { computeLogName } from "../../utils/log";
import { LogType, SetLogPayload } from "./types";

interface OverviewState {
  fields: Field[];
  logs: OverviewLog[];
  ordersByField: Record<string, BaseOrder[]>;
  selectedLogType: LogType;
  selectedField: string | undefined;
  wellboreFilter: string | undefined;
}

const initialState = {
  fields: [],
  logs: [],
  ordersByField: {},
  selectedLogType: OrderStatus.Ongoing,
  selectedField: undefined,
  wellboreFilter: undefined
} as OverviewState;

const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setFields(state, action: PayloadAction<Field[]>) {
      state.fields = action.payload;
    },
    setLogs(state, action: PayloadAction<SetLogPayload>) {
      const { logs, logType } = action.payload;
      state.logs = logs.map((log) => ({
        ...log,
        name: computeLogName(log),
        depthStatusSimple: logDepthStatusSimplified(log.depthStatus)
      }));
      state.selectedLogType = logType;
    },
    setOrdersByField(
      state,
      action: PayloadAction<Record<string, BaseOrder[]>>
    ) {
      state.ordersByField = action.payload;
    },
    setLogType(state, action: PayloadAction<LogType>) {
      state.selectedLogType = action.payload;
    },
    setSelectedField(state, action: PayloadAction<string | undefined>) {
      state.selectedField = action.payload;
    },
    setWellboreFilter(state, action: PayloadAction<string | undefined>) {
      state.wellboreFilter = action.payload?.toLowerCase();
    },
    clearState() {
      return initialState;
    }
  }
});

export const {
  setFields,
  setLogs,
  setOrdersByField,
  setLogType,
  setSelectedField,
  setWellboreFilter,
  clearState
} = overviewSlice.actions;
export default overviewSlice.reducer;
