import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog as EdsDialog,
  Icon,
  Typography
} from "@equinor/eds-core-react";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useRequest } from "../../../../hooks";
import AADUser, { isAADUser } from "../../../../models/aadUser";
import Contact, { Availability } from "../../../../models/contact";
import { selectRoles } from "../../../../state/currentOrder/referenceData/selectors";
import { selectOrderId } from "../../../../state/currentOrder/selectors";
import { RequestType } from "../../../../state/request";
import { addSnack } from "../../../../state/snacks/reducer";
import { colors } from "../../../../styles/Colors";
import SelectMenu from "../../../SelectMenu";
import { TextField as EdsTextField } from "../../../TextField";
import Tooltip from "../../../Tooltip";
import ContactSearchBar from "./ContactSearchBar";

const { Title, Header } = EdsDialog;

type Props = {
  contact?: Contact;
  onClose: () => void;
};

function ContactDialog({ contact, onClose }: Props): React.ReactElement {
  const dispatch = useDispatch();
  const [errorMessageApi, setErrorMessageApi] = useState("");

  const isEdit = !!contact;
  const requestType = isEdit
    ? RequestType.UpdateContact
    : RequestType.CreateContact;

  const roles = useSelector(selectRoles);
  const orderId = useSelector(selectOrderId);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    formState: { isSubmitted }
  } = useForm({
    defaultValues: {
      id: contact?.id,
      name: contact?.name ?? "",
      isMainContact: contact?.isMainContact ?? false,
      email: contact?.email ?? "",
      phone: contact?.phone ?? "",
      roleId: roles.find((role) => role.name === contact?.role)?.id,
      availability: contact?.availability
    },
    criteriaMode: "all"
  });

  const errorMessage = `The contact ${getValues("name")} could not be ${
    isEdit ? "updated" : "created"
  }`;

  const {
    invokerFunction: onSubmit,
    isLoading,
    isError,
    isSuccess
  } = useRequest({
    requestType,
    successCallback: () => {
      dispatch(
        addSnack(
          `The contact ${getValues("name")} was successfully ${
            isEdit ? "updated" : "created"
          }`
        )
      );
      onClose();
    },
    errorCallback: (error) => {
      if (error?.status === 400 && error.title?.includes("submitted order")) {
        setErrorMessageApi(error.title);
      } else {
        setErrorMessageApi("");
      }
      dispatch(addSnack(errorMessage));
    },
    setError,
    payloadGenerator: {
      func: (data) => {
        const payload = {
          ...data,
          phone: data.phone === "" ? undefined : data.phone,
          roleId: data.roleId ?? "",
          availability: data.availability ?? ""
        };
        return [orderId, payload];
      },
      deps: [orderId]
    }
  });

  const callback = useCallback(
    (user: string | AADUser | null) => {
      if (user && isAADUser(user)) {
        setValue("name", user.displayName, {
          shouldDirty: true,
          shouldValidate: isSubmitted
        });
        setValue("email", user.mail ?? "", {
          shouldDirty: true,
          shouldValidate: isSubmitted
        });
        setValue("phone", user.mobilePhone ?? "", {
          shouldDirty: true,
          shouldValidate: isSubmitted
        });
      }
    },
    [setValue, isSubmitted]
  );

  return (
    <Dialog open={true}>
      <Header>
        <Title>{isEdit ? "Edit" : "Add"} contact</Title>
      </Header>
      {!isEdit && (
        <SearchWrapper>
          <ContactSearchBar callback={callback} />
        </SearchWrapper>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "This field is required"
            }}
            render={({
              field: { ref, ...props },
              fieldState: { invalid, error }
            }) => (
              <RequiredTextField
                {...props}
                id={props.name}
                placeholder="Full name"
                label="Name"
                readOnly={isEdit}
                inputRef={ref}
                helperText={error?.message}
                helperIcon={errorOutlined}
                variant={invalid ? "error" : "default"}
              />
            )}
          />
          <Controller
            name="isMainContact"
            control={control}
            render={({ field: { value, ...props } }) => (
              <Checkbox {...props} label="Main responsible" checked={value} />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="roleId"
            control={control}
            rules={{ required: "This field is required" }}
            render={({
              field: { ref, onChange, ...props },
              fieldState: { error }
            }) => (
              <SelectMenu
                {...props}
                label="Role"
                variant="text-field"
                data={roles}
                onSelect={(r) => onChange(r.id)}
                inputRef={ref}
                errorText={error?.message}
                required
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              pattern: {
                value: /^\+?[()\-\s\d]+$/g,
                message: "Enter a valid phone number"
              },
              maxLength: {
                value: 30,
                message: "Limit phone number to 30 characters"
              }
            }}
            render={({
              field: { ref, ...props },
              fieldState: { invalid, error }
            }) => (
              <TextField
                {...props}
                id={props.name}
                placeholder="Phone number"
                label="Phone (optional)"
                inputRef={ref}
                helperText={error?.message}
                helperIcon={errorOutlined}
                variant={invalid ? "error" : "default"}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "This field is required",
              pattern: {
                value: /\S+@\S+\.\S+/g,
                message: "Enter a valid email address"
              },
              maxLength: {
                value: 80,
                message: "Limit email address to 80 characters"
              }
            }}
            render={({
              field: { ref, ...props },
              fieldState: { invalid, error }
            }) => (
              <RequiredTextField
                {...props}
                id={props.name}
                placeholder="example@equinor.com"
                label="Email"
                inputRef={ref}
                helperText={error?.message}
                helperIcon={errorOutlined}
                variant={invalid ? "error" : "default"}
              />
            )}
          />
          <Controller
            name="availability"
            control={control}
            rules={{ required: "This field is required" }}
            render={({
              field: { ref, onChange, ...props },
              fieldState: { error }
            }) => (
              <SelectMenu
                {...props}
                label="Availability"
                variant="text-field"
                data={Object.values(Availability).map(
                  (availability: Availability, idx) => ({
                    id: String(idx),
                    name: availability
                  })
                )}
                onSelect={(a) => onChange(a.name)}
                inputRef={ref}
                errorText={error?.message}
                required
              />
            )}
          />
        </Row>
        <ActionButtons>
          <Button type="submit" disabled={isLoading || isSuccess}>
            {isEdit ? "Update" : "Add"}
          </Button>
          <Button
            variant="outlined"
            disabled={isLoading || isSuccess}
            onClick={onClose}
          >
            Cancel
          </Button>
          {isLoading && <Spinner />}
          {isError && (
            <Tooltip
              placement="bottom-start"
              title={`${errorMessage}. Please try again`}
              html={
                errorMessageApi ? (
                  <TooltipContainer>
                    <Typography group="ui" variant="tooltip" color="white">
                      {`${errorMessage}.`}
                    </Typography>
                    <Typography group="ui" variant="tooltip" color="white">
                      {errorMessageApi}
                    </Typography>
                  </TooltipContainer>
                ) : undefined
              }
              variant="dark"
            >
              <Icon
                name="errorOutlined"
                color={colors.interactive.dangerResting}
              />
            </Tooltip>
          )}
        </ActionButtons>
      </form>
    </Dialog>
  );
}

const errorOutlined = <Icon name="errorOutlined" />;

const Dialog = styled(EdsDialog)`
  width: 31.5rem;
`;

const Spinner = styled(CircularProgress)`
  height: auto;
  width: 1.25rem;
`;

const SearchWrapper = styled.div`
  margin: 0 1rem 2rem;
`;

// align helper text with icon
const TextField = styled(EdsTextField)`
  & > div > p {
    line-height: 0.75rem;
  }
`;

// add red asterisk to left of label
const RequiredTextField = styled(TextField)`
  & > label:first-child > span:first-child::after {
    color: ${colors.interactive.dangerResting};
    content: " *";
  }
`;

const Row = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1.25rem;

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  &&& > * {
    width: 100%;
  }

  & > label {
    margin-top: 1rem;

    & > span:first-child {
      padding: 0.375rem;
    }

    & > span:last-child {
      padding-left: 0.5rem;
    }
  }
`;

const ActionButtons = styled.div`
  align-items: center;
  display: flex;
  margin: 0 1rem 1.375rem;

  & > button,
  & > button:disabled {
    margin-right: 1rem;
  }
`;

const TooltipContainer = styled.div`
  width: 23.5rem;
`;

export default ContactDialog;
