import {
  Button,
  Checkbox as EdsCheckbox,
  Dialog as EdsDialog,
  Typography
} from "@equinor/eds-core-react";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import { useParamSelector } from "../../../../hooks";
import Log from "../../../../models/log";
import { copyCurvesForLog } from "../../../../state/currentOrder/actions";
import { selectAllEligibleLogCopyTargetsSelectorFactory } from "../../../../state/currentOrder/selectors";
import { dateFormatter } from "../../../../utils/dateFormatter";

const { Title, Header } = EdsDialog;

type Props = {
  log: Log;
  onClose: () => void;
};

const LogCopyDialog = ({ log, onClose }: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const selectAllEligibleTargets = useMemo(
    selectAllEligibleLogCopyTargetsSelectorFactory,
    []
  );

  const targetLogs = useParamSelector(selectAllEligibleTargets, log.id);

  const { handleSubmit, control } = useForm();

  const submitHandler = (data: Record<string, boolean>) => {
    onClose();

    const targetIds = Object.keys(data).filter((key) => data[key]);
    dispatch(copyCurvesForLog(log, ...targetIds));
  };

  return (
    <Dialog open={true}>
      <Header>
        <Title>
          Copy selected curves from section{" "}
          <NoWrap>
            {log.name} ({dateFormatter(log.startTime, { dateOnly: true })})
          </NoWrap>
        </Title>
      </Header>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <Typography variant="body_long">
            Copy selected curves into:
          </Typography>
        </Row>
        <CheckboxRow>
          {targetLogs.map((log) => (
            <Controller
              key={log.id}
              name={log.id}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  label={log.name}
                  $date={dateFormatter(log.startTime, { dateOnly: true })}
                />
              )}
            />
          ))}
        </CheckboxRow>
        <ActionButtons>
          <Button type="submit">Copy</Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </ActionButtons>
      </form>
    </Dialog>
  );
};

const Dialog = styled(EdsDialog)`
  width: 31.5rem;
`;

const Row = styled.div`
  margin: 0 1rem 1rem;
`;

const CheckboxRow = styled(Row)`
  display: flex;
  flex-direction: column;
`;

const ActionButtons = styled(Row)`
  align-items: center;
  display: flex;

  & > button,
  & > button:disabled {
    margin-right: 1rem;
  }
`;

const Checkbox = styled(EdsCheckbox)<{ $date: string }>`
  & > span:last-child {
    font-weight: 500;

    &::after {
      ${({ $date }) =>
        $date
          ? css`
              content: " (${$date})";
              font-weight: 400;
            `
          : ""}
    }
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

export default LogCopyDialog;
