import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import ProviderServiceTool from "../../../models/providerServiceTool";
import Role from "../../../models/role";
import SiteComGroup from "../../../models/siteComGroup";
import TargetSystem from "../../../models/targetSystem";

export interface ReferenceDataState {
  roles: Role[];
  targetSystems: TargetSystem[];
  providerServiceTools: ProviderServiceTool[];
  siteComGroups: SiteComGroup[];
}

const initialState = {
  roles: [],
  targetSystems: [],
  providerServiceTools: [],
  siteComGroups: []
} as ReferenceDataState;

const referenceDataSlice = createSlice({
  name: "currentOrder/referenceData",
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<Role[]>) {
      state.roles = action.payload;
    },
    setTargetSystems(state, action: PayloadAction<TargetSystem[]>) {
      state.targetSystems = action.payload;
    },
    setProviderServiceTools(
      state,
      action: PayloadAction<ProviderServiceTool[]>
    ) {
      state.providerServiceTools = action.payload;
    },
    setSiteComGroups(state, action: PayloadAction<SiteComGroup[]>) {
      state.siteComGroups = action.payload;
    }
  }
});

export const {
  setRoles,
  setTargetSystems,
  setProviderServiceTools,
  setSiteComGroups
} = referenceDataSlice.actions;
export default referenceDataSlice.reducer;
