export enum Availability {
  Always = "24/7",
  WorkHours = "08-16"
}

type Contact = {
  id: string;
  name: string;
  email: string;
  isMainContact: boolean;
  role: string;
  availability: Availability;
  phone?: string;
};

export default Contact;
