import {
  Button,
  Dialog as EdsDialog,
  List,
  Typography
} from "@equinor/eds-core-react";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { selectLogsWithCurveChanges } from "../../../state/currentOrder/selectors";
import { dateFormatter } from "../../../utils/dateFormatter";

const { Item: ListItem } = List;
const { Title, Header } = EdsDialog;

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
};

function UnsavedChangesDialog({
  onCancel,
  onConfirm
}: Props): React.ReactElement {
  const logsWithCurveChanges = useSelector(selectLogsWithCurveChanges);

  return (
    <Dialog open={true}>
      <Header>
        <Title>Discard unsaved changes?</Title>
      </Header>
      <Row>
        <Typography variant="body_long">
          You are about to leave this page. If you continue all unsaved changes
          will be lost.
        </Typography>
      </Row>
      <Row>
        <Typography variant="body_long">
          Sections with unsaved changes:
        </Typography>
        <List variant="bullet">
          {logsWithCurveChanges.map((log) => (
            <ListItem>
              <Typography as="span" bold>
                {log.name}
              </Typography>
              {" - " + dateFormatter(log.startTime)}
            </ListItem>
          ))}
        </List>
      </Row>
      <ActionButtons>
        <Button variant="outlined" color="danger" onClick={onCancel}>
          Stay on page
        </Button>
        <Button color="danger" onClick={onConfirm}>
          Discard changes
        </Button>
      </ActionButtons>
    </Dialog>
  );
}

const Dialog = styled(EdsDialog)`
  width: 31.5rem;
`;

const Row = styled.div`
  margin: 0 1rem 1rem;

  & > ul {
    margin-top: 1rem;
    padding-inline-start: 1.5rem;

    & > li:not(:last-child) {
      padding-bottom: 0.25rem;
    }
  }
`;

const ActionButtons = styled(Row)`
  align-items: center;
  display: flex;
  margin: 1rem 1rem 1.375rem;

  & > button:not(:last-child) {
    margin-right: 1rem;
  }
`;

export default UnsavedChangesDialog;
